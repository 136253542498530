<template>
  <div class="m-detail">
    <el-card shadow="never">
      <div v-html="html"></div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "detail",
  data() {
    return {};
  },
  computed: {
    html() {
      return (
        (this.$route.matched &&
          this.$route.matched[2] &&
          this.$route.matched[2].meta &&
          this.$route.matched[2].meta.html) ||
        ""
      );
    },
  },
};
</script>

<style lang="less" scoped>
.m-detail {
  /deep/ p {
    line-height: 1.8;
  }
  /deep/ img {
    max-width: 100%;
  }
}
</style>
