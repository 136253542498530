<template>
  <div class="topMenu">
    <div class="topMenu_title">
      <img src="@/assets/imgs/bi_logo.png" alt="" />
      <span class="s1">积木云策</span>
      <span class="s2">帮助中心</span>
    </div>
    <div class="topMenu_fnc">
      <div class="r">
        <div class="tabs">
          <div class="_f">
            <div
              v-for="item in firstRoutes"
              :key="item.path"
              class="tabs-li"
              :class="{ active: item.path === currFirstPath }"
              @click="toPath(item.path)"
            >
              <p>
                <i v-if="item.meta && item.meta.menuIcon && item.name !== 'setting'" :class="item.meta.menuIcon"></i>
                <i v-if="item.name === 'setting'" class="el-icon-setting"></i>
                {{ item.meta.menuTitle }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ul>
        <li><a href="https://www.linkeryun.cn/" class="link" target="_blank">积木云官网</a></li>
        <!-- <li @click="$router.push({ name: 'memberCenter' })">常见问题</li> -->
        <!-- <li @click="$router.push({ name: 'member' })">使用手册</li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "topMenu",
  data() {
    return {};
  },
  computed: {
    // 获取一级菜单配置
    firstRoutes() {
      return this.$router.options.routes.filter((item) => item.meta && item.meta.menuTitle);
    },
    // 当前匹配的一级路由地址
    currFirstPath() {
      return this.$route.matched && this.$route.matched[0] && this.$route.matched[0].path;
    },
  },
  methods: {
    toPath(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less">
.topMenu {
  height: 68.92px;
  background-color: #327eff;
  position: relative;
  display: flex;
  .topMenu_title {
    height: 68.92px;
    width: 262px;
    border-right: 1px solid #f5f7fb;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-right: 45px;
    box-sizing: border-box;
    img {
      width: 25px;
      height: 25px;
    }
    .s1 {
      font-size: 22px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    .s2 {
      font-size: 18px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      transform: translate(0px, 3px);
    }
  }
  .topMenu_fnc {
    height: 68.92px;
    width: 100%;
    padding-left: 15px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-input {
      width: 220px;
      margin-left: 24px;
      .el-input__inner {
        width: 220px;
        height: 40px;
        line-height: 40px;
      }
    }
    ul {
      display: flex;
      li {
        margin-right: 50px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        &:last-child {
          margin-right: 24px;
        }
      }
    }
  }
}
.link {
  color: #ffffff;
}
.r {
  flex: 1;
  line-height: 56px;
  font-size: 16px;
  color: #fff;
  .icon-box {
    display: inline-block;
    padding: 0 30px 0 26px;
  }
  i {
    cursor: pointer;
  }
  .tabs {
    display: inline-block;
    font-size: 14px;
    text-align: center;
    width: calc(100% - 30px);
    ._f {
      display: flex;
    }
    .tabs-li {
      padding: 0 10px;
      min-width: 110px;
      margin-right: 30px;
      box-sizing: border-box;
      cursor: pointer;
      display: inline-block;
      i {
        margin-right: 10px;
      }
      &.active {
        color: #fff;
        height: 53px;
        border-bottom: 3px solid #fff;
      }
    }
  }
}
</style>
