export default `<h1 id="L2F9p" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="u06a77553" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(44, 58, 70); font-size: 14px">人群营销整合平台不同属性、不同行为、不同渠道的用户，旨在帮助商家进行数据化、精细化、自动化的流量运营，持续提升用户的付费转化率，带来更多营收。</span>
</p>
<p id="uf2920914" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(44, 58, 70); font-size: 14px"></span>
</p>
<p id="u1981b1de" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(44, 58, 70); font-size: 14px">在流量成本越来越高的时代，存量客户的经营变得越来越重要。一家店铺通过前期的运营推广，积累了一大批客户，客户的来源不同，特征也千差万别。如果用统一的运营方式来运营所有的客户，必然会出现的一个问题是，满足一部分客户需求的同时，另一部分客户的需求并不能很好的满足，导致整体运营操作的转化率并不会很高，所以流量的精细化运营成为必要。人群运营帮助商家进行数据化、精细化、自动化的流量运营，持续带来成交转化。</span>
</p>
<h1 id="m3gWb" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、推荐运营分组</span>
</h1>
<p id="u4e47a1d2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 14px">1、管理员进入「营销中台-人群营销」，可查看积木云策内置的不同群组的人数以及访问系统人数，可针对内置群组直接创建人群营销进行运营触达</span>
</p>
<p id="u56efbd9a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/e176ce35853ab5c14ab104d5c6d24912.jpg" width="921.6666910824958" id="uae79e4f1" class="ne-image"/>
</p>
<p id="u54b89941" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·人群总数：符合该人群统计规则的人数；</span>
</p>
<p id="uc761b033" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·昨日访问：统计昨日该人群访问业务系统的人数合计；</span>
</p>
<p id="ubb2fa424" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、可点击「创建运营计划」，给指定群组新建人群营销</span>
</p>
<p id="u121f22c0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/97baf5282a8935b837f335aa33939fb8.jpg" width="1058.333361369593" id="u8202dad2" class="ne-image"/><span class="ne-text"> &nbsp;备注：与新建营销计划最终效果一致，仅人群不同。</span>
</p>
<h1 id="fVtS0" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、新建营销计划</span>
</h1>
<p id="u847b68da" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「营销中台-人群营销」，点击「新增营销计划」进入新增页</span>
</p>
<p id="u945d9b49" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/bc80b944fc469a6a6fda0238a53b6e67.jpg" width="1058.8889169398656" id="u02b3d348" class="ne-image"/>
</p>
<p id="u3d6b4eda" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、在选择顾客分组页面，可选择对应业务系统下已创建完成的顾客分组以及分组的基本信息，选择分组后，点击「确定」按钮进入下一步；</span>
</p>
<p id="ud7b42a4f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/99d07674b8dd0c3c1679d1153b440fc1.jpg" width="1051.6666945263198" id="u9213d743" class="ne-image"/>
</p>
<p id="u77493ac0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、进入「基本信息」填写页，填写「计划名称」、「业务系统」、「计划方式」、「触发条件」后进入下一步计划配置页；</span>
</p>
<p id="ufe661a0d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/e5bb659c1ffff1cc039e60f1ccb5d365.jpg" width="1058.8889169398656" id="u6c197f5b" class="ne-image"/>
</p>
<p id="uf849545c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）计划名称：可输入1-20字计划名称，用于内部管理查看；</span>
</p>
<p id="u345b72d2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）计划人群：可查看所选人群的名称以及人群数量，可点击「？」来查看人群的定义，方便快速查看人群信息；</span>
</p>
<p id="ucd35894a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：每次推送人数建议少于10万，否则容易出现延时的情况</span>
</p>
<p id="ufb631ed2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）业务系统：点击下拉选择业务系统（选择的业务系统请与人群所在业务系统保持一致）</span>
</p>
<p id="u2c35094e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）计划方式：可按单次/重复进行选择；</span>
</p>
<p id="ub8f09e0e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·单次：可选择在指定日期、指定时间对客户进行触达，且仅触达一次，触达完成后活动结束。</span>
</p>
<p id="ue5afc329" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·重复：可选择活动时间范围，在指定日期范围内每天的指定时间进行触达，可配置单个用户可参与1次或多次活动</span>
</p>
<p id="udac56f27" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/1ffdbb26a24cc00c9f73c4d92bc98136.jpg" width="281.1111185580126" id="uc3c63e3e" class="ne-image"/>
</p>
<p id="u50f03eac" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）触发条件：可选择用户访问/消费后触发活动或直接触发，当到达计划发送时间后，将满足触发条件的用户</span>
</p>
<p id="ud8b2e4b0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/4305ed26d5028eefb85ab1590068f684.jpg" width="274.7777862548828" id="ua6eef668" class="ne-image"/>
</p>
<p id="u8fc43128" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">4、进入「计划配置」第二步页面，可配置不同的通知渠道以及权益配置，可配置用户完成营销活动的定义规则，以及用户完成后自动打上指定标签</span>
</p>
<p id="ub1d03902" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/b3a475ca6cf300303875594c07667de9.jpg" width="1063.8889170723205" id="uc37fd06f" class="ne-image"/>
</p>
<p id="ub240295f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）通知渠道：可选择单个或多个触达渠道触达至用户</span>
</p>
<p id="u5f44f453" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·短信通知：点击弹出弹窗，可配置短信通知内容以及变量（短信模板来源通过企业配置配置短信渠道后即可获取）</span>
</p>
<p id="u247d3998" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/96bcfee4b00e4bdb0938e4d8ef3bc490.jpg" width="441.66667836683797" id="uc69984f0" class="ne-image"/>
</p>
<p id="ud3b50494" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：无用户手机号的用户会被自动过滤</span>
</p>
<p id="uf4226aa5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·公众号通知：点击弹出公众号模板（公众号模板来源于企业配置公众号渠道后获取），选择后进入填写内容页，填写模板下的变量内容以及模板通知的打开方式</span>
</p>
<p id="ue99930a0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：未关注公众号的用户会被自动过滤</span>
</p>
<p id="ua9e67ebd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/26a6692fe097379eecb8aca2c61ce505.jpg" width="571.3333435058594" id="uea65ff4e" class="ne-image"/><img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d371834c1063708aaf210f18d9cbc252.jpg" width="577.2222375133895" id="u6781d06c" class="ne-image"/>
</p>
<p id="u1d6a3d64" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·push通知：app应用对接积木云策的情况下，需要在企业配置配置好推送渠道（支持极光推送/友盟推送）后支持通过push推送触达用户，选择推送渠道后，可填写推送的标题和内容，以及点击推送跳转的效果</span>
</p>
<p id="u5f14ac42" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/653ada52fda421ee535e69198240011b.jpg" width="1271.111144784057" id="ubd769c96" class="ne-image"/>
</p>
<p id="u172726ec" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·应用弹窗：可配置推送的应用端口以及对应类型，上传弹窗图片后即可配置成功，当配置后，触达用户打开端口直接展示弹窗图片</span>
</p>
<p id="u99e206c3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/f1cf11a9a319663663366ede911df7b1.jpg" width="385" id="u1c48b9c2" class="ne-image"/>
</p>
<p id="u60ac8b15" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）权益设置：配置完触达渠道后可配置本次营销活动的权益内容（目前暂时只支持优惠券），可点击「添加优惠券」按钮添加优惠券</span>
</p>
<p id="u57a4c9f7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：支持配置多个优惠券，触达用户后，系统将优惠券直接发放给用户，不需要领取。</span>
</p>
<p id="ua9d87730" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/205e264d86b061e92cd8bd48a7d70044.jpg" width="895.5555792796765" id="ua4a9b2b7" class="ne-image"/>
</p>
<p id="ud33a9bb7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）完成定义：根据活动所期望带来的效果，来配置用户完成营销活动的定义，用于统计营销活动的有效性。</span>
</p>
<p id="u4aa8cd85" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·消费：即用户在触达后通过营销事件进入业务系统后，在当日存在消费行为则视为完成营销活动</span>
</p>
<p id="u657df9b8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·登录系统：即用户在通过营销事件进入业务系统后，在当日有登录系统则视为完成营销活动</span>
</p>
<p id="ue4c838d8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·点击链接：用户通过营销事件点击链接（点击短信通知内的链接或者点击公众号通知附带的链接或者点击push推送附带的链接即视为完成）</span>
</p>
<p id="u1fa76e51" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/4416417fa517ca0f65900b8cef4c762a.jpg" width="314.44445277437774" id="u432ac61a" class="ne-image"/>
</p>
<p id="ub77421f6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）标签：用户完成活动后自动打上此标签内容，标示用户来源，精细化运营用户。</span>
</p>
<p id="uf95abc47" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/c79e56cabcdc9af4396ef732f5d6797b.jpg" width="200.55556086846354" id="u53db92f3" class="ne-image"/>
</p>
<h1 id="VZtrJ" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">四、查找和管理营销计划</span>
</h1>
<p id="u9e91ca61" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「营销中台-人群营销」列表，可通过「会员分组」、「状态筛选」、「计划筛选」、「通知渠道」、「业务系统」来查找想要查看的营销计划</span>
</p>
<p id="uc5586eee" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d6f39cb7165fb37d13afa169199df801.jpg" width="1042.777805401956" id="ude9840ab" class="ne-image"/>
</p>
<p id="udce15655" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·会员分组：可点击弹出群组弹窗，多选群组后点击「确定选中」即可选择该分组下的营销计划</span>
</p>
<p id="u9471ca18" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/cbd0d15495e382fdfa6d232e41377406.jpg" width="638.888905813665" id="uf85b0ac3" class="ne-image"/>
</p>
<p id="uc8c60638" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·状态筛选：支持按营销计划的状态筛选营销计划（状态包含：待开始/执行中/已完成）</span>
</p>
<p id="ue1eff689" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·计划筛选：可按单次计划/长期计划来筛选营销计划类型</span>
</p>
<p id="u2de19853" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·通知渠道：可按短信通知/公众号通知/push通知/弹窗推送四种类型来筛选营销计划</span>
</p>
<p id="u1830b9de" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·业务系统：可按不同业务系统来筛选营销计划（业务系统来源于对接积木云策）</span>
</p>
<p id="u58dbd424" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、可在列表点击「复制」按钮来重新选择分组后复用此营销计划规则进行创建；</span>
</p>
<p id="u35963433" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/87c64c68d3b862daa49bdc2ac3952deb.jpg" width="1032.7778051370462" id="u0ece561d" class="ne-image"/>
</p>
<p id="u484c5cb7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、可点击「详情」按钮，来查看该营销计划配置的基本信息与计划配置内容；</span>
</p>
<p id="u137eaa66" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/5415ccdd1d9fc9a54a8f96e7a2db13b6.jpg" width="1046.1111388235925" id="ubf451423" class="ne-image"/>
</p>
<p id="u49c78c5b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/acdc6426c8ab4793bff65246f0e44379.jpg" width="1060.555583650684" id="ub2f1ae48" class="ne-image"/>
</p>
<p id="u592a56da" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/be052e7951da72b34c997931a2a145fa.jpg" width="1058.8889169398656" id="u274defbe" class="ne-image"/>
</p>
<h1 id="e7iBF" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">五、数据报表</span>
</h1>
<p id="u276c99ba" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「营销中台-人群营销-数据报表」，可查看此营销数据的详细统计数据；</span>
</p>
<p id="u9ab8c115" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/b97c3a2db7bd2da548ace523f0eef7ad.jpg" width="1049.4444722452288" id="u495cd308" class="ne-image"/>
</p>
<p id="u7771740e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入营销计划页，可查看营销计划的数据汇总、转化效果、活动完成情况、活动用户后续留存等多维度数据来查看此活动的有效性；</span>
</p>
<p id="ua6af1e43" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/a188bffc21922c17ba9a8e2c5c68e682.jpg" width="1053.8889168074109" id="ub7ce6005" class="ne-image"/>
</p>
<p id="u04435709" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）数据汇总：</span>
</p>
<p id="u7a843333" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·通知成员人数：按实际收到推送的用户总数统计（预计与实际存在误差， 因为不同的触达渠道可能会存在无法通知到用户的情况）；</span>
</p>
<p id="u40f0c0e9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访客数：</span><span class="ne-text">通过本次营销计划访问系统的用户总数，跨天去重；</span>
</p>
<p id="u3af333f3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·下单人数：</span><span class="ne-text">通过本次营销计划进入系统后的创建订单的用户总数，既计划执行期间的下单人数；</span>
</p>
<p id="u79ef9c05" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·支付人数：通过营销计划进入业务系统后</span><span class="ne-text">成功调用支付的用户人数；</span>
</p>
<p id="ua66c3148" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·支付订单数：</span><span class="ne-text">通过营销计划进入业务系统后成功支付的订单总数；</span>
</p>
<p id="ud10b6a25" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·支付金额：通过营销计划进入业务系统后支付订单总金额总数；</span>
</p>
<p id="u099a88c1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）转化效果：</span>
</p>
<p id="u9d30ce12" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·推送成功人数：按实际收到推送的用户总数统计（预计与实际存在误差， 因为不同的触达渠道可能会存在无法通知到用户的情况）；</span>
</p>
<p id="u741efb81" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访客人数：</span><span class="ne-text">通过本次营销计划访问系统的用户总数，跨天去重；</span>
</p>
<p id="u69097940" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·支付人数：通过营销计划进入业务系统后</span><span class="ne-text">成功调用支付的用户人数；</span>
</p>
<p id="uee19abb6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·通知-支付转化率：</span><span class="ne-text">支付人数/通知人数x100%；</span>
</p>
<p id="u6072ece5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问-支付转化率：</span><span class="ne-text">支付人数/访客人数x100%；</span>
</p>
<p id="u95ea25fe" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·通知-访问转化率：</span><span class="ne-text" style="font-size: 15px;">访客人数/通知人数x100%；</span>
</p>
<p id="ue69b10b1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）活动完成情况：</span>
</p>
<p id="u117a4e92" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·目标完成率：</span><span class="ne-text" style="font-size: 15px;">指定日期下，完成活动人数/参与人数x100%</span>
</p>
<p id="u351497bb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·发送成功人数：</span><span class="ne-text" style="font-size: 15px;">指定日期下，计划收到推送的用户总数</span>
</p>
<p id="u91653c7a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·目标完成人数：</span><span class="ne-text" style="font-size: 15px;">指定日期下，完成活动人数</span>
</p>
<p id="ud2c72e53" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·发送失败人数：</span><span class="ne-text" style="font-size: 15px;">指定日期下，实际未发送成功的人数（选择的触达渠道无法通知到某用户的情况）</span>
</p>
<p id="ub12b4631" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·用户拒收人数：</span><span class="ne-text" style="font-size: 15px;">指定日期下，用户拒收的人数（短信、app情况下，用户可能存在拒收的情况）</span>
</p>
<p id="u24ef042b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）活动用户后续留存：</span>
</p>
<p id="u006d0cfb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·指定日期总人数：参与活动的用户人数</span>
</p>
<p id="uff914e5d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·指定日期的第1-7天留存率：例如有100个用户参与活动后，第1天有50个用户访问了，就代表第1天的留存率为50%</span>
</p>
<p>
<br/>
</p>`;
