export default `<h1 id="L2F9p" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="u39ed7aa4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(44, 58, 70); font-size: 15px">在整个客户生命周期中，客户忠诚度的培养和管理是十分重要的一部分。现在积木云策提供了等级体系、优惠券等基础的会员运营能力，让商家可以对客户进行分层，设定优惠。在此基础上，平台需要持续刺激客户复购并且提供客户关怀。提供会员日、生日、节日营销，帮助商家跟客户进行场景化互动，提升会员忠诚度。</span>
</p>
<h1 id="m3gWb" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、推荐运营场景</span>
</h1>
<p id="u9e6be6e2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">1、管理员进入「营销中台-场景营销」，可查看积木云策内置的不同基础场景和用户场景，可针对内置场景直接创建场景营销进行运营触达；</span>
</p>
<p id="uff33c895" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/b4491483d82d010349a9205a5df93d57.jpg" width="1310.0000347031496" id="u40b0a05c" class="ne-image"/>
</p>
<p id="u6d1d60e9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、基础场景：</span>
</p>
<p id="u8a8a2726" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）生日营销：给最近一年内生日的用户推送指定优惠券（备注：通知渠道、权益配置、完成定义、标签需自定配置）。</span>
</p>
<p id="u04d33991" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·基本信息：自动带入名称为「生日营销」，计划类型自动带入「重复」，起止时间为「当日至下一年当日」，触发时间自动带入「当前时间」，参与限制为「参与1次」；</span>
</p>
<p id="ub0ffddb9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d394afc94e3748ac4002394c758ed7f3.jpg" width="1580.0000418557072" id="u0ccc6299" class="ne-image"/>
</p>
<p id="u24ea8f15" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·场景规则：自动带入「用户属性-生日」规则，固定时间为「当日至下一年当日」；</span>
</p>
<p id="ub9c45f1a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/eefeafa80cde0595e3f841d7b8c1e188.jpg" width="1582.2222641367982" id="u4762e32a" class="ne-image"/>
</p>
<p id="ua87aac88" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）会员日营销：特定时间范围内给指定用户会员等级的用户推送指定优惠券，提升顾客黏性。（备注：通知渠道、权益配置、完成定义、标签需自定配置）。</span>
</p>
<p id="u89b4220d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·基本信息：自动带入名称为「会员日营销」，计划类型自动带入「重复」，起止时间为「当日至下一年当日」，参与限制为「参与1次」；</span>
</p>
<p id="u53988a7b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/70e66d63be070d2621c90473e0416183.jpg" width="1590.0000421206169" id="u5fc436f5" class="ne-image"/>
</p>
<p id="u818299d5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·场景规则：自动带入「用户属性-VIP等级」规则，用户自行选择会员等级；</span>
</p>
<p id="udd327d10" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：会员等级来源于「营销中台-会员等级」配置；</span>
</p>
<p id="ua2b7f0e0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d837c69ed233d4db7fc6536de956e9ff.jpg" width="1583.3333752773437" id="u0c777917" class="ne-image"/>
</p>
<p id="u4cf735a1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）促销活动：特定时间促销活动，刺激用户转化消费。（备注：通知渠道、权益配置、完成定义、标签需自定配置）。</span>
</p>
<p id="u0e5806dc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·基本信息：自动带入名称为「促销活动」，计划类型自动带入「重复」，起止时间为「当日至下一年当日」，参与限制为「参与1次」；</span>
</p>
<p id="u6467b59d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/70e66d63be070d2621c90473e0416183.jpg" width="1590.0000421206169" id="uJRwY" class="ne-image"/>
</p>
<p id="u91e64e9b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·场景规则：自动带入「用户属性-消费行为」规则，自动带入「相对时间-最近-365天-无消费」规则；</span>
</p>
<p id="u8ba1337b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/f57a64e19a6146447acee23925877878.jpg" width="1576.6667084340706" id="u2184a944" class="ne-image"/>
</p>
<p id="uf6c95214" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）其他活动：进入创建场景营销页，自行填写基本信息、场景规则、计划配置等信息，不带入任何规则内容。</span>
</p>
<p id="u10f77c35" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、用户场景：</span>
</p>
<p id="u23153465" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）新用户首次注册：新用户注册后自动触发此营销活动，给用户推送指定优惠券，提升用户留存。</span>
</p>
<p id="u57f1cba6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·基本信息：自动带入名称为「新用户首次注册」，计划类型自动带入「重复」，起止时间为「当日至下一年当日」，参与限制为「参与1次」；</span>
</p>
<p id="uba04c0d9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d2d314257959671b1e4507627a0ae082.jpg" width="1584.4444864178893" id="ucb63b698" class="ne-image"/>
</p>
<p id="u311339dc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·场景规则：自动带入「用户属性-注册时间」规则，自动带入「固定时间」，管理员根据实际使用时间自行选择注册时间；</span>
</p>
<p id="u91f54138" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/f0291cf2e7ad1b1a18e8c3714dde9736.jpg" width="1582.2222641367982" id="ud015a3a0" class="ne-image"/>
</p>
<p id="ube8eb840" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<p id="u9b1a7fde" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）用户首次下单：用户首次在业务系统付费后推送指定优惠券，吸引顾客再次复购。</span>
</p>
<p id="uc9f83d07" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·基本信息：自动带入名称为「用户首次下单」，计划类型自动带入「重复」，起止时间为「当日至下一年当日」，参与限制为「参与1次」；</span>
</p>
<p id="u1ac77d41" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d2d314257959671b1e4507627a0ae082.jpg" width="1584.4444864178893" id="LKZ49" class="ne-image"/>
</p>
<p id="u10d1fb51" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·场景规则：自动带入「用户属性-消费行为」规则，自动带入「固定时间-时间范围-有消费」规则</span>
</p>
<p id="u2d750c56" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/fee4df1f5d134debbd937b1cf3c9360b.jpg" width="1588.8889309800713" id="uf1a532d0" class="ne-image"/>
</p>
<p id="u68715fac" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）用户领取券：用户首次获取到优惠券后触发该营销活动，推动用户使用优惠券下单。</span>
</p>
<p id="u7c24bf39" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·基本信息：自动带入名称为「用户领取券」，计划类型自动带入「重复」，起止时间为「当日至下一年当日」，参与限制为「参与1次」；</span>
</p>
<p id="u3de3a4b9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/bbae7a5733553daa212509f55cbc11bd.jpg" width="1587.7778198395258" id="u837acbb5" class="ne-image"/>
</p>
<p id="u398b1591" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·场景规则：自动带入「用户行为-领取优惠券次数」规则，领取优惠券次数带入「固定时间-时间范围-区间0-1次」规则</span>
</p>
<p id="u3ae5c1d5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/dd87a9ce2d511f259aca1b4ff4641de9.jpg" width="1573.333375012434" id="uf2f6ddc9" class="ne-image"/>
</p>
<p id="u7419af9f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<h1 id="s9Kyb" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、新建营销计划</span>
</h1>
<p id="u3208708d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「营销中台-场景营销」，点击「其他活动-立即新建」进入新增页</span>
</p>
<p id="u945d9b49" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/99ad6f79d1f52e89c4ade9430d376086.jpg" width="1586.6667086989803" id="u0586729d" class="ne-image"/>
</p>
<p id="u3d6b4eda" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入「基本信息」填写页，填写「计划名称」、「业务系统」、「计划方式」、「触发条件」后进入下一步计划配置页；</span>
</p>
<p id="ufe661a0d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/91d154e7aca6da7233bbbfa062f26a34.jpg" width="1592.2222644017077" id="ue4e7d02e" class="ne-image"/>
</p>
<p id="uf849545c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）计划名称：可输入1-20字计划名称，用于内部管理查看；</span>
</p>
<p id="u345b72d2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）业务系统：点击下拉选择业务系统（选择的业务系统请与人群所在业务系统保持一致）</span>
</p>
<p id="u2c35094e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）计划方式：可按单次/重复进行选择；</span>
</p>
<p id="ub8f09e0e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·单次：可选择在指定日期、指定时间对客户进行触达，且仅触达一次，触达完成后活动结束。</span>
</p>
<p id="ue5afc329" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·重复：可选择活动时间范围，在指定日期范围内每天的指定时间进行触达，可配置单个用户可参与1次或多次活动</span>
</p>
<p id="udac56f27" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/47bbd00b2694262fd260b7eda1b6bf3f.jpg" width="281.1111185580126" id="uc3c63e3e" class="ne-image"/>
</p>
<p id="u8fc43128" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、进入「场景规则」第二步页面，选择营销活动的触发条件以及营销活动的场景规则后即可进入下一步</span>
</p>
<p id="ub1d03902" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/c102428d9010902f259ccc16f615badd.jpg" width="1581.1111529962527" id="u1b1b83ac" class="ne-image"/>
</p>
<p id="u54b0e80f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）触发条件：</span>
</p>
<p id="ue179a010" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·触发型-完成A：选择该类型后，代表该营销事件，用户达到配置的规则，即可直接触达</span>
</p>
<p id="ue62725b1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·触发型-未完成A：选择该类型后，代表该营销事件，用户未达到配置的规则，即可直接触达</span>
</p>
<p id="u161edc2e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·触发型-完成A 未完成B：代表用户需要满足A事件，同时不满足B事件，到达符合条件后，系统直接触达该用户（若选择该类型的话，需要在满足事件和未满足事件内都选择事件）</span>
</p>
<p id="u6012db91" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）场景规则：</span>
</p>
<p id="ufef04585" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>用户属性：</strong>
</p>
<p id="u52f3cc20" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·VIP等级：可选择云策内定义的会员等级选择，将符合会员等级的用户即打上此标签；</span>
</p>
<p id="u85b1c28c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·性别：按用户的性别筛选（根据对接的业务系统返回的用户性别筛选，支持二次编辑）；</span>
</p>
<p id="u6990cc6d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·所在城市：按用户所在的城市进行筛选，点击可弹出地理位置选择器，选择省份及对应的城市（根据对接的业务系统返回的用户所在城市进行筛选，支持二次编辑）；</span>
</p>
<p id="u2e7d2f3e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·出生年份：按时间范围筛选用户的出生年份信息（根据对接的业务系统返回的用户出生年份进行筛选，支持二次编辑）；</span>
</p>
<p id="u6f410052" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·注册时间：可选固定/相对时间范围内注册或者进入业务系统的用户；</span>
</p>
<p id="u606a8a71" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·生日：可选固定/相对时间范围内生日的用户（生日信息通过对接业务系统获取，支持二次编辑）；</span>
</p>
<p id="ub5e821ee" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·客单价：可按用户在平台的客单价筛选用户信息（客单价=客户在平台的消费金额/购次）；</span>
</p>
<p id="u233b63f3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·消费次数：可按客户累计在平台内支付成功的订单数量进行筛选，筛选出指定消费次数的用户；</span>
</p>
<p id="ud02b20c2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·累计消费金额：可按客户累计消费金额进行筛选；</span>
</p>
<p id="u1701b829" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·消费行为：可选指定时间内，购买/未购买过商品的客户（以支付成功为准，不剔除退款）；</span>
</p>
<p id="u3bdc86af" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·积分：可选客户当前拥有的积分范围进行筛选，筛选出符合所选条件的用户；</span>
</p>
<p id="u3d808b85" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·来源渠道：可根据用户的添加渠道进行筛选，目前支持通过小程序/自然注册/自定义渠道三种进行筛选；</span>
</p>
<p id="u140f008a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>用户行为：</strong>
</p>
<p id="ub199d4bc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问时长：可选择用户访问时长的时间范围，来筛选符合用户访问时长的用户；</span>
</p>
<p id="u060887eb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问次数：可按用户访问业务系统的次数来筛选用户，</span>
</p>
<p id="uac80c88f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·领取优惠券次数：可按用户领取优惠券的次数来筛选用户（以券为单位，领取单张券为1次）</span>
</p>
<p id="u852927c9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·使用优惠券次数：可按用户使用优惠券的次数来筛选用户</span>
</p>
<p id="uc4ad3606" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·是否关注公众号：按用户是否关注公众号来筛选用户（业务系统需要有公众号用户体系，才可使用此筛选功能）</span>
</p>
<p id="u1fca3e7f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·参与活动次数：按用户参与营销活动的次数来筛选用户（活动指的是用户参与积木云策下发的人群/场景营销活动）</span>
</p>
<p id="u65ca3054" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·是否分享：按用户是否存在分享行为来筛选用户（指的是在业务系统层面上进行的分享动作）</span>
</p>
<p id="ud3ffdfd0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·订单次数：筛选时间范围内符合下单次数的用户（指在业务系统上存在下单行为）</span>
</p>
<p id="u6c92ffde" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·取消订单次数：筛选时间范围内符合取消订单次数的用户（指在业务系统上存在取消订单行为）</span>
</p>
<p id="u1e604779" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·短信点击次数：筛选时间范围内用户点击短信短链接的次数</span>
</p>
<p id="uf6c3a4b1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·push点击次数：筛选时间范围内点击过app PUSH推送的用户</span>
</p>
<p id="u2d16b28d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·公众号点击次数：筛选时间范围内点击过公众号模板消息的用户</span>
</p>
<p id="u498b1430" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·全端启动：筛选时间范围内进入过业务系统的用户（业务系统指的是对接积木云策的小程序/APP/网站）</span>
</p>
<p id="u408cfda3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·完成营销活动：筛选时间范围内完成过营销活动的用户（完成营销活动指的是积木云策下发的人群/营销活动内的完成活动定义。）</span>
</p>
<p id="u56295586" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<p id="u40f2790d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、进入「计划配置」第三步页面，可配置不同的通知渠道以及权益配置，可配置用户完成营销活动的定义规则，以及用户完成后自动打上指定标签</span>
</p>
<p id="u74c90e2e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/1c9e6be739bf1443ca92aacce14b04c5.jpg" width="1590.0000421206169" id="u38606c17" class="ne-image"/>
</p>
<p id="ub240295f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）通知渠道：可选择单个或多个触达渠道触达至用户</span>
</p>
<p id="u5f44f453" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·短信通知：点击弹出弹窗，可配置短信通知内容以及变量（短信模板来源通过企业配置配置短信渠道后即可获取）</span>
</p>
<p id="u247d3998" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/a3d0af62a743957eb44b7230ff6d13e3.jpg" width="441.66667836683797" id="uc69984f0" class="ne-image"/>
</p>
<p id="ud3b50494" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：无用户手机号的用户会被自动过滤</span>
</p>
<p id="uf4226aa5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·公众号通知：点击弹出公众号模板（公众号模板来源于企业配置公众号渠道后获取），选择后进入填写内容页，填写模板下的变量内容以及模板通知的打开方式</span>
</p>
<p id="ue99930a0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：未关注公众号的用户会被自动过滤</span>
</p>
<p id="ua9e67ebd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/7dafcf11984d8c9d30d45cab6a31abbe.jpg" width="571.3333435058594" id="uea65ff4e" class="ne-image"/><img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/e2a3200bddeb7af01560e48e3e5b6170.jpg" width="577.2222375133895" id="u6781d06c" class="ne-image"/>
</p>
<p id="u1d6a3d64" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·push通知：app应用对接积木云策的情况下，需要在企业配置配置好推送渠道（支持极光推送/友盟推送）后支持通过push推送触达用户，选择推送渠道后，可填写推送的标题和内容，以及点击推送跳转的效果</span>
</p>
<p id="u5f14ac42" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/2498be6529a9a57ce9ad2b627e576562.jpg" width="1271.111144784057" id="ubd769c96" class="ne-image"/>
</p>
<p id="u172726ec" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·应用弹窗：可配置推送的应用端口以及对应类型，上传弹窗图片后即可配置成功，当配置后，触达用户打开端口直接展示弹窗图片</span>
</p>
<p id="u99e206c3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/88d27cce14a4da2d0587b725eb457438.jpg" width="385" id="u1c48b9c2" class="ne-image"/>
</p>
<p id="u60ac8b15" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）权益设置：配置完触达渠道后可配置本次营销活动的权益内容（目前暂时只支持优惠券），可点击「添加优惠券」按钮添加优惠券</span>
</p>
<p id="u57a4c9f7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：支持配置多个优惠券，触达用户后，系统将优惠券直接发放给用户，不需要领取。</span>
</p>
<p id="ua9d87730" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d4a23395c65f5fd4a7e45b80c08be032.jpg" width="895.5555792796765" id="ua4a9b2b7" class="ne-image"/>
</p>
<p id="ud33a9bb7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）完成定义：根据活动所期望带来的效果，来配置用户完成营销活动的定义，用于统计营销活动的有效性。</span>
</p>
<p id="u4aa8cd85" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·消费：即用户在触达后通过营销事件进入业务系统后，在当日存在消费行为则视为完成营销活动</span>
</p>
<p id="u657df9b8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·登录系统：即用户在通过营销事件进入业务系统后，在当日有登录系统则视为完成营销活动</span>
</p>
<p id="ue4c838d8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·点击链接：用户通过营销事件点击链接（点击短信通知内的链接或者点击公众号通知附带的链接或者点击push推送附带的链接即视为完成）</span>
</p>
<p id="u1fa76e51" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/f9eedc2ab06c1fcc54909e3aa10b056f.jpg" width="314.44445277437774" id="u432ac61a" class="ne-image"/>
</p>
<p id="ub77421f6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）标签：用户完成活动后自动打上此标签内容，标示用户来源，精细化运营用户。</span>
</p>
<p id="uf95abc47" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/a0481dc057fc93c0d74eae73f59f2927.jpg" width="200.55556086846354" id="u53db92f3" class="ne-image"/>
</p>
<h1 id="A7pCt" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">四、查找和管理营销计划</span>
</h1>
<p id="u0145d0a4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「营销中台-场景营销」列表，可通过「业务系统」来查找想要查看的营销计划（业务系统来源于对接积木云策）</span>
</p>
<p id="uc5586eee" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/472f069fb7689ac2157008acd7a77082.jpg" width="1588.8889309800713" id="uf48b0e47" class="ne-image"/>
</p>
<p id="u58dbd424" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<p id="u484c5cb7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、可点击「详情」按钮，来查看该营销计划配置的基本信息与计划配置内容；</span>
</p>
<p id="u137eaa66" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/92c38acbed31a5d0d4967e9493eb7b71.jpg" width="1590.0000421206169" id="u9c5b53cd" class="ne-image"/>
</p>
<p id="u49c78c5b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/1dcdf9a167314c3632060fb7210af855.jpg" width="1581.1111529962527" id="u81872aba" class="ne-image"/>
</p>
<p id="u592a56da" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/1821b0c458284a4596b18f2cce3d1aff.jpg" width="1590.0000421206169" id="u5729038d" class="ne-image"/>
</p>
<p id="ubc6f8b11" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/de0a4eb5def9b1f28f4e1ed45bd24734.jpg" width="1590.0000421206169" id="ufc3c0ab7" class="ne-image"/>
</p>
<h1 id="leTcZ" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">五、数据报表</span>
</h1>
<p id="u505b9595" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「营销中台-场景营销-数据报表」，可查看此营销数据的详细统计数据；</span>
</p>
<p id="u9ab8c115" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/3b63b2ae11f3b0a5172f16de951ab21e.jpg" width="1590.0000421206169" id="u34a39e6a" class="ne-image"/>
</p>
<p id="u7771740e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入营销计划页，可查看营销计划的数据汇总、转化效果、活动完成情况、活动用户后续留存等多维度数据来查看此活动的有效性；</span>
</p>
<p id="ua6af1e43" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/08bdcf3a91976dd79b028b1d4a2a3cba.jpg" width="1582.2222641367982" id="u05aa487a" class="ne-image"/>
</p>
<p id="uf3dd5c68" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/f3aece74f582c1465f323c08dcc59ab4.jpg" width="1592.2222644017077" id="u50a269d8" class="ne-image"/>
</p>
<p id="u04435709" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）数据汇总：</span>
</p>
<p id="u7a843333" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·通知成员人数：按实际收到推送的用户总数统计（预计与实际存在误差， 因为不同的触达渠道可能会存在无法通知到用户的情况）；</span>
</p>
<p id="u40f0c0e9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访客数：</span><span class="ne-text">通过本次营销计划访问系统的用户总数，跨天去重；</span>
</p>
<p id="u3af333f3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·下单人数：</span><span class="ne-text">通过本次营销计划进入系统后的创建订单的用户总数，既计划执行期间的下单人数；</span>
</p>
<p id="u79ef9c05" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·支付人数：通过营销计划进入业务系统后</span><span class="ne-text">成功调用支付的用户人数；</span>
</p>
<p id="ua66c3148" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·支付订单数：</span><span class="ne-text">通过营销计划进入业务系统后成功支付的订单总数；</span>
</p>
<p id="ud10b6a25" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·支付金额：通过营销计划进入业务系统后支付订单总金额总数；</span>
</p>
<p id="u099a88c1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）转化效果：</span>
</p>
<p id="u9d30ce12" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·推送成功人数：按实际收到推送的用户总数统计（预计与实际存在误差， 因为不同的触达渠道可能会存在无法通知到用户的情况）；</span>
</p>
<p id="u741efb81" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访客人数：</span><span class="ne-text">通过本次营销计划访问系统的用户总数，跨天去重；</span>
</p>
<p id="u69097940" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·支付人数：通过营销计划进入业务系统后</span><span class="ne-text">成功调用支付的用户人数；</span>
</p>
<p id="uee19abb6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·通知-支付转化率：</span><span class="ne-text">支付人数/通知人数x100%；</span>
</p>
<p id="u6072ece5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问-支付转化率：</span><span class="ne-text">支付人数/访客人数x100%；</span>
</p>
<p id="u95ea25fe" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·通知-访问转化率：</span><span class="ne-text" style="font-size: 15px;">访客人数/通知人数x100%；</span>
</p>
<p id="ue69b10b1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）活动完成情况：</span>
</p>
<p id="u117a4e92" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·目标完成率：</span><span class="ne-text" style="font-size: 15px;">指定日期下，完成活动人数/参与人数x100%</span>
</p>
<p id="u351497bb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·发送成功人数：</span><span class="ne-text" style="font-size: 15px;">指定日期下，计划收到推送的用户总数</span>
</p>
<p id="u91653c7a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·目标完成人数：</span><span class="ne-text" style="font-size: 15px;">指定日期下，完成活动人数</span>
</p>
<p id="ud2c72e53" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·发送失败人数：</span><span class="ne-text" style="font-size: 15px;">指定日期下，实际未发送成功的人数（选择的触达渠道无法通知到某用户的情况）</span>
</p>
<p id="ub12b4631" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·用户拒收人数：</span><span class="ne-text" style="font-size: 15px;">指定日期下，用户拒收的人数（短信、app情况下，用户可能存在拒收的情况）</span>
</p>
<p id="u24ef042b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）活动用户后续留存：</span>
</p>
<p id="u006d0cfb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·指定日期总人数：参与活动的用户人数</span>
</p>
<p id="uff914e5d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·指定日期的第1-7天留存率：例如有100个用户参与活动后，第1天有50个用户访问了，就代表第1天的留存率为50%</span>
</p>
<p>
<br/>
</p>`;
