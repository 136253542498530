import detail from "@/views/detail";

const router = [
  {
    path: "/workbench",
    component: detail,
    name: "workbench",
    meta: {
      menuTitle: "工作台", // 一级菜单
      menuIcon: "icon-workbench",
    },
    children: [
      {
        path: "/workbench",
        component: detail,
        name: "workbench",
        redirect: "/workbench/realTime",
        meta: {
          menuTitle: "数据概览", // 二级菜单
        },
        children: [
          {
            path: "realTime",
            component: detail,
            name: "realTime",
            meta: {
              menuTitle: "实时数据", // 三级菜单
              html: require("@/assets/data/workbench/realTime").default,
            },
          },
          {
            path: "memberData",
            component: detail,
            name: "memberData",
            meta: {
              menuTitle: "会员数据",
              html: require("@/assets/data/workbench/memberData").default,
            },
          },
          {
            path: "marketingData",
            component: detail,
            name: "marketingData",
            meta: {
              menuTitle: "营销数据",
              html: require("@/assets/data/workbench/marketingData").default,
            },
          },
        ],
      },
      {
        path: "/workbench",
        component: detail,
        name: "workbench",
        redirect: "/workbench/realTime",
        meta: {
          menuTitle: "智能分析", // 二级菜单
        },
        children: [
          {
            path: "helper",
            component: detail,
            name: "helper",
            meta: {
              menuTitle: "积木云助手", // 三级菜单
              html: require("@/assets/data/workbench/helper").default,
            },
          },
        ],
      },
    ],
  },
];
export default router;
