export default `<h1 id="zP0d0" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、优惠券类型统计</span>
</h1>
<p id="u33ed3a9a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「营销中台-优惠券」进入优惠券列表，可查看不同类型优惠券的发放数量与使用数量数据</span>
</p>
<p id="u36f33a3b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d35d6933b2f39f76092693afd8fea245.jpg" width="1049.4444722452288" id="u36b8af67" class="ne-image"/>
</p>
<p id="u1701e4b7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·发放数量：通过不同渠道发送此类型优惠券的数量（发送渠道包含：活动发放、等级发放、用户领取）</span>
</p>
<p id="u6c5993f9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·使用数量：用户在业务系统下使用此类型优惠券的数量（下单即为使用）</span>
</p>
<h1 id="bOuJU" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、优惠券管理</span>
</h1>
<p id="ubffcaee4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、进入优惠券列表，可输入优惠券名称「关键词」、「优惠券类型」、「优惠券状态」、「业务系统」、「发放方式」来筛选查找优惠券内容</span>
</p>
<p id="u92df3e67" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/61ee04527e4af5513afebd2393feb630.jpg" width="1057.2222502290474" id="u68553494" class="ne-image"/>
</p>
<p id="u9c34b012" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）优惠券名称：可输入优惠券的名称模糊搜索优惠券</span>
</p>
<p id="ucfcc2ba5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）优惠券类型：支持按满减券/折扣券/代金券三种类型筛选优惠券</span>
</p>
<p id="u7bfe52ab" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）优惠券状态：可按未开始/发放中/已结束/暂停四种状态来筛选优惠券</span>
</p>
<p id="ud02449b7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）业务系统：按创建时所选业务系统筛选优惠券</span>
</p>
<p id="u6ca2814e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）发放方式：可按用户主动领取/活动发放/等级发放三种类型筛选优惠券</span>
</p>
<p id="uf31d3344" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：主动领取类型为业务系统自带领券大厅功能且对接积木云策后通过积木云策发放优惠券给予用户领取</span>
</p>
<p id="u2c72e786" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、可点击详情「导出」按钮导出优惠券列表来查看优惠券的使用情况，或点击「详情」按钮进入详情页查看数据统计</span>
</p>
<p id="u15f087d4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/ff27f0e7334099ebd348ed23f19a9ece.jpg" width="1052.2222500965927" id="u101f0d59" class="ne-image"/>
</p>
<h1 id="WfQMp" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、新建优惠券</span>
</h1>
<p id="u0f5d02ac" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「营销中台-优惠券」，点击「新增优惠券」</span>
</p>
<p id="u7dc523ea" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/ed1e40c82c4ed37f4a1f11c8219ec7e5.jpg" width="1050.0000278155017" id="u9ec2fc1c" class="ne-image"/>
</p>
<p id="ub51de3f5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入新增优惠券页面，填写「优惠券名称」、「名称备注」、「发放总量」、「优惠券类型」、「用券时间」、「发放渠道」、「发放方式」后即可创建</span>
</p>
<p id="u1bd8d51f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/669a86c188719cb6a147723d715aeb68.jpg" width="1031.111138426228" id="u03acef79" class="ne-image"/>
</p>
<p id="ue614672d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">基本设置：</span>
</p>
<p id="u276c79c4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）优惠券名称：可输入1-10字优惠券名称信息，用于业务系统下优惠券的名称展示</span>
</p>
<p id="udb64d124" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）名称备注：可输入1-20字优惠券备注文字，用于内部查看</span>
</p>
<p id="uaf7df54e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）发放数量：可输入最多10000000000张优惠券，优惠券保存后只可增加不可减少</span>
</p>
<p id="ua3270a64" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">规则设置：</span>
</p>
<p id="u5396b49f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）优惠券类型：</span>
</p>
<p id="u31077651" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(44, 58, 70)">·满减券：举例：满100元减20元优惠券，便于合理控制活动成本；</span>
</p>
<p id="ucce7d72c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(44, 58, 70)">·折扣券：举例：满100元打8折，可提高店铺销量及客单价；</span>
</p>
<p id="u57b4409a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(44, 58, 70)">·代金券：举例：立减10元，无门槛券可加强用户下单意愿。 </span>
</p>
<p id="u69f9d864" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(44, 58, 70)">（2）用券时间：</span><span class="ne-text">创建满减券/折扣券/代金券时，可用时间可选择固定用券时间、领券后立即生效，有效期x天、其中x可设置1-1000的正整数。</span>
</p>
<p id="u3b878fba" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）发放渠道：选择优惠券下发的业务系统渠道，可在不同的业务系统渠道生效</span>
</p>
<p id="u70a509a1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）发放方式：</span>
</p>
<p id="uc5e20d96" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·用户领取：选择该选项后，用户可直接在领券大厅领取（需业务系统有领券大厅功能且对接云策后才可发放至领券大厅）；</span>
</p>
<p id="ua52318f0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·活动发放：选择该选项后，优惠券可通过人群营销/场景营销/渠道管理将此券发放出去给用户领取；</span>
</p>
<p id="u6e2c9ef9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·等级发放：选择该选项后，优惠券可通过等级晋升获取优惠券；</span>
</p>
<p id="u57ff4da0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<p id="u28c666a3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">举例：如设置领券2天后生效且有效期为2天，用户在5月21日14:00时领取优惠券，则该优惠券的可用时间为5月23日的00:00:00至5月24日的23:59:59。</span>
</p>
<h1 id="jWlk2" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">四、优惠券详细数据</span>
</h1>
<p id="ufba47f7a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「营销中台-优惠券」点击「详情」进入数据页</span>
</p>
<p id="u00d96b0d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/2b14da68ca4874fd9afd30bd77208823.jpg" width="1053.3333612371382" id="uade494b2" class="ne-image"/>
</p>
<p id="ua4b8e79c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、可查看优惠券详细信息数据统计内容</span>
</p>
<p id="uf09d2c16" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/cae7616ba4bf79f23919a2bed43b72bf.jpg" width="1061.6666947912295" id="ubb227be2" class="ne-image"/>
</p>
<p id="u641873e7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>优惠券使用明细：</strong>
</p>
<p id="u1d79e043" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·领取人数：该优惠券在指定日期被用户领取的人数（包含领取/系统发放）</span>
</p>
<p id="ufa2aeaf2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·使用人数：该优惠券在指定日期被用户使用下单的人数</span>
</p>
<p id="uead48f94" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·消费订单总额：使用该优惠券下单的订单金额总计</span>
</p>
<p id="u0b09f442" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/7e5c3491ac462e3e935a1826d8ec5565.jpg" width="903.333357263495" id="u00f0dad8" class="ne-image"/>
</p>
<p id="uccbb0ac5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>不同用户群组的优惠券使用次数：</strong><span class="ne-text">可查看此优惠券过去30天对不同用户群组的敏感程度，通过此分析可调整人群营销针对性的优惠券发放策略</span>
</p>
<p id="ubf63498d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/390b672950b42ec4ac20bd49613ad8da.jpg" width="633.3333501109375" id="udf05e6f1" class="ne-image"/>
</p>
<p id="ud1d901df" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>不同等级的优惠券使用次数：</strong><span class="ne-text">可查看此优惠券过去30天对不同会员等级用户的敏感程度，通过此分析可针对性调整会员等级的活动投放策略；</span>
</p>
<p id="u9d66afc8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/932b0f1945896d55a809259a3f7cddf8.jpg" width="624.4444609865734" id="u1d763bcb" class="ne-image"/>
</p>
<p id="ue0548993" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>商品使用优惠券排行：</strong><span class="ne-text">可查看此优惠券下单时购买的商品数量排名，来判断此优惠券与商品之间的联系；</span>
</p>
<p id="ufbd90878" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/c402ddc6aeac7827329c00447ff9d2cd.jpg" width="622.2222387054825" id="uf5ea6302" class="ne-image"/>
</p>
<p id="u5e1db4b8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>品牌使用优惠券排行：</strong><span class="ne-text">可查看平台下的品类使用此优惠券的数量排名，通过此分析来判断优惠券对于不同品类的偏好，来针对性推出新品。</span>
</p>
<p id="u76e9217e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/007e17303f34d0b9480c01df7718b05a.jpg" width="616.6666830027549" id="u9c5c4c9a" class="ne-image"/>
</p>
<p>
<br/>
</p>`;
