import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
//初始化css
import "../src/assets/style/reset.css";
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  //每次跳转将滚动条滚动到最上面
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
