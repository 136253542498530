import detail from "@/views/detail";

const router = [
  {
    path: "/memberAnalyse",
    component: detail,
    name: "memberAnalyse",
    meta: {
      menuTitle: "会员分析", // 一级菜单
      menuIcon: "icon-memberAnalyse",
    },
    children: [
      {
        path: "/memberAnalyse",
        component: detail,
        name: "memberAnalyse",
        redirect: "/memberAnalyse/portrait",
        meta: {
          menuTitle: "会员画像", // 二级菜单
        },
        children: [
          {
            path: "portrait",
            component: detail,
            name: "portrait",
            meta: {
              menuTitle: "会员画像", // 三级菜单
              html: require("@/assets/data/memberAnalyse/portrait").default,
            },
          },
          {
            path: "AARRR",
            component: detail,
            name: "AARRR",
            meta: {
              menuTitle: "AARRR分析", // 三级菜单
              html: require("@/assets/data/memberAnalyse/AARRR").default,
            },
          },
        ],
      },
      {
        path: "/memberAnalyse",
        component: detail,
        name: "memberAnalyse",
        redirect: "/memberAnalyse/AARRR",
        meta: {
          menuTitle: "LTV分析", // 二级菜单
        },
        children: [
          {
            path: "lifecycle",
            component: detail,
            name: "lifecycle",
            meta: {
              menuTitle: "会员生命周期", // 三级菜单
              html: require("@/assets/data/memberAnalyse/lifecycle").default,
            },
          },
        ],
      },
      {
        path: "/memberAnalyse",
        component: detail,
        name: "memberAnalyse",
        redirect: "/memberAnalyse/AARRR",
        meta: {
          menuTitle: "微信生态分析", // 二级菜单
        },
        children: [
          {
            path: "fans",
            component: detail,
            name: "fans",
            meta: {
              menuTitle: "粉丝分析", // 三级菜单
              html: require("@/assets/data/memberAnalyse/fans").default,
            },
          },
          {
            path: "fansTranform",
            component: detail,
            name: "fansTranform",
            meta: {
              menuTitle: "粉丝转化", // 三级菜单
              html: require("@/assets/data/memberAnalyse/fansTranform").default,
            },
          },
        ],
      },
      {
        path: "/memberAnalyse",
        component: detail,
        name: "memberAnalyse",
        redirect: "/memberAnalyse/AARRR",
        meta: {
          menuTitle: "数字化事件", // 二级菜单
        },
        children: [
          {
            path: "member",
            component: detail,
            name: "member",
            meta: {
              menuTitle: "会员事件", // 三级菜单
              html: require("@/assets/data/memberAnalyse/member").default,
            },
          },
        ],
      },
    ],
  },
];
export default router;
