<template>
  <div class="leftMenu">
    <div v-for="(i, index) in secondRoutes" :key="index" class="leftMenu_box">
      <p v-if="i.meta.menuTitle">{{ i.meta.menuTitle }}</p>
      <ul>
        <li
          :class="{ active: $route.name == j.name }"
          v-for="(j, index1) in i.children"
          :key="index1"
          @click="$router.push(j.path)"
        >
          {{ j.meta.menuTitle }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pick: 0,
      pick1: 0,
    };
  },
  computed: {
    // 获取当前二级菜单配置
    secondRoutes() {
      const firstPath = (this.$route.matched && this.$route.matched[0] && this.$route.matched[0].path) || "";
      const firstRoute = this.$router.options.routes.find((item) => item.path === firstPath);
      return firstRoute && firstRoute.children;
    },
  },
  methods: {},
};
</script>

<style lang="less">
.leftMenu {
  width: 262px;
  height: 100%;
  background: #fff;
  // position: fixed;
  padding: 29px 36px 0 36px;
  box-sizing: border-box;
  .leftMenu_box {
    p {
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin-bottom: 20px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
      }
    }
  }
  .active {
    color: #327eff !important;
  }
}
</style>
