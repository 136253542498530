export default `<h3 id="v5J8J" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1.简介</span>
</h3>
<p id="u6c0263c4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">事件，是指用户在产品上的所有行为，如“浏览某页面”、“点击某按钮”、“下单”、“搜索”、“咨询”等操作。</span>
</p>
<p id="u8680677f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">维度，是指用来描述事件的具体特征，在事件分析内表现为“按维度查看”，也就是从什么视角来细分查看指标。比如，对于“下单”事件，可以按“商品名称”、“店铺名称”的维度来拆分查看下单次数。对于用户新增/活跃基础指标监控如无特殊场景下则直接按默认“总体”即可。</span>
</p>
<p id="uf1095b1a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<p id="ufe976be6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong><span class="ne-text" style="color: rgba(0, 0, 0, 0.85)">事件分析能够帮助您洞察这些问题：</span></strong>
</p>
<p id="ue0c051be" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">· 本次活动优惠券的领取、核销数量和趋势是怎样的 </span>
</p>
<p id="udf6f069c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">· 这个月哪个付费渠道的成单量、成单金额最高 </span>
</p>
<p id="ua134c295" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">· 为什么昨天的数据突然下滑</span>
</p>
<p id="ue7aea13e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<h3 id="Hs99U" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.使用说明</span>
</h3>
<p id="u31a5dbd1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">积木云策提供内置基础事件，若基础事件无法满足使用场景的情况下，请联系平台进行业务目标事件定制，以达到全场景的事件分析目的。</span>
</p>
<h3 id="Yqqdl" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.1页面介绍</span>
</h3>
<p id="ud67cdb07" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/2e34ccd474ffbda3347ea961665f8c10.jpg" width="1987.4999703839426" id="uc71a21da" class="ne-image"/>
</p>
<h3 id="CVOAC" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.2选择事件指标</span>
</h3>
<p id="ub519607c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.在左侧下拉框选择指标，如“提交订单”</span>
</p>
<p id="ue87b608a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/a06f7ddb306d7500b8a1b3c170dae221.jpg" width="464.005859375" id="ued6b5c73" class="ne-image"/>
</p>
<p id="udb09d95f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<p id="ued2783a3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2.在右侧下拉框选择对应的指标</span>
</p>
<p id="ubc2683b3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/836a842129871b788909b72fa0bd2465.jpg" width="1128.7499831803145" id="ue741b906" class="ne-image"/>
</p>
<p id="u02f62944" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">指标说明：</span>
</p>
<table id="vYii2" class="ne-table" width="748">
<tbody>
    <tr style="height: 33px" class="firstRow">
        <td width="187" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="ub838199a" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">指标</span></strong>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u1662ccd0" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">适用范围</span></strong>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u172eb051" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">说明</span></strong>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="udc198f94" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">举例</span></strong>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="ub1acc2b2" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">总次数</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u38298516" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">所有事件都有的指标</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u8e970ef3" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">所选时间范围内，事件发生的次数。</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="ub32c53e9" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">提交订单的总次数</span>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="uf0bbd0b1" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">触发用户数</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="ue60102ad" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">所有事件都有的指标</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u385abbca" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">所选时间范围内，触发事件的人数。按照相同设备ID或用户ID去重。</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="uf7104ae6" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">浏览活动页的触发用户数</span>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u40c9de93" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">人均次数</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="uea7af471" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">所有事件都有的指标</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="ub7da8b79" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">总次数/触发用户数。</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u1415a347" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">浏览活动页的人均次数</span>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="ua6eeabe4" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">去重数</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u6fd9ecb7" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">事件的所有属性都有的指标</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u45854437" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">不同属性取值的个数，相同取值去重记为1。</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u89a090cd" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">“任意事件的来源渠道去重数”来查看有几个来源渠道</span>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u53747e47" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">总和</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u07c7512b" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">事件的“数值类型”属性特有的指标</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="uc718567a" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">属性取值求和。</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u78b1c887" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">提交订单的金额总和</span>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u18c52a72" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">均值</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="ucdc66379" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">事件的“数值类型”属性特有的指标</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="ued05f854" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">属性取值求和/取值个数。</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="uc4f357b8" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">“提交订单的金额均值”来查看平均订单金额</span>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="uffe4fb2a" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">最大值</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u8f86e7d6" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">事件的“数值类型”属性特有的指标</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u99ffd843" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">属性取值的最大值。</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u2368bb25" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">提交订单的金额最大值</span>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u93caf0eb" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">最小值</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u44a4c6ad" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">事件的“数值类型”属性特有的指标</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="ud232ca2d" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">属性取值的最小值。</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u1871479b" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">提交订单的金额最小值</span>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u65fe1aeb" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">人均值</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="uea94f3a0" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">事件的“数值类型”属性特有的指标</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="ue1fd086d" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">属性取值求和/触发用户数。</span>
            </p>
        </td>
        <td width="187" style="border-color: rgb(217, 217, 217);">
            <p id="u8284661e" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">“提交订单的金额人均值”来查看人均订单金额</span>
            </p>
        </td>
    </tr>
</tbody>
</table>
<p id="ue457f893" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85)">3.给事件添加过滤条件：通过事件选择器右侧的“漏斗”按钮为所选事件添加约束条件。条件关系支持或、且。</span>
</p>
<p id="u399e9e5f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/721311a639272b1db6a591547e306478.jpg" width="1057.4999842420223" id="u19c37efb" class="ne-image"/>
</p>
<p id="ud0c4850e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">点击左侧下拉弹出属性弹窗，选择事件属性、用户属性</span>
</p>
<p id="u687c524c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/92ae5c077acc6011452bf9f59dd47e6f.jpg" width="642.499990426004" id="ua2a2d26d" class="ne-image"/>
</p>
<p id="u530ed9fe" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">判断符根据所选的数值类型，展示不同类型的判断符，具体如下：</span>
</p>
<p id="u2a5e4d96" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/f41d68525332d97e8411b313ec5a94ce.jpg" width="761.2499886564912" id="iYKli" class="ne-image"/>
</p>
<p id="u73b8561c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">4.可以通过点击左侧的加号，添加多个事件进行分析：</span>
</p>
<p id="ue076cd2c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/b37bc49f8e6c930c56d165fcb90ac3a4.jpg" width="1164.9999826401474" id="ufaa57d6e" class="ne-image"/>
</p>
<h3 id="gn9Q7" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.3按维度查看</span>
</h3>
<p id="u5adb517c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">选择事件指标后，可以按维度查看对指标进行细化分析。可选择的拆分维度是您选择的事件所包含的属性。</span>
</p>
<p id="ub710e1f4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">*该项非必选，不选择默认不按属性维度拆分</span>
</p>
<p id="uc6c7fc8c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/92ae5c077acc6011452bf9f59dd47e6f.jpg" width="642.499990426004" id="utsl4" class="ne-image"/>
</p>
<h3 id="d9Hpn" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.4用户分群</span>
</h3>
<p id="u402707ff" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可通过积木云策创建的群组，选择目标人群来分析他们的行为特征，不选择默认为全部用户</span>
</p>
<p id="u8a0595ff" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/94ebb93d1506d61089c65cd1fa3660bd.jpg" width="663.7499901093544" id="u407ccf97" class="ne-image"/>
</p>
<h3 id="mnD2s" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.5分析时间</span>
</h3>
<p id="ua27eccb9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.周期的粒度可按天、按周、按月。若选择按周的情况下，周期筛选则按照所选时间范围判断所选筛选时间的所在周，自动按周展示，如果选择时间范围不满1周情况下，则按周期所在周自动展示一周的周期（若跨周且时间范围不满一周的情况下则按两个周期展示）。按月同理，不满1月自动按1月的周期范围展示，若跨月则按所在月展示月份数据</span>
</p>
<p id="ub77eba22" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2.选择需要分析的时间范围，默认按最近7天查询。</span>
</p>
<p id="u07b89d1e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<p id="uf3cd222d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/2e0fe2836547fdb72c05337ee526494a.jpg" width="1367.4999796226623" id="u6cdbc1aa" class="ne-image"/>
</p>
<h3 id="SynOB" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.7数据可视化方式</span>
</h3>
<p id="ub48c057d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.数据排名：通过选择TopN调整图表内的线条数量</span>
</p>
<p id="u0e704b0b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">目前支持：</span>
</p>
<p id="uf461cb03" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">前5项/前10项/前15项/前20项/前25项/前30项/前50项</span>
</p>
<p id="ua6c67e62" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/fae21720e58ed2500c7b7a4388511c27.jpg" width="1393.7499792315068" id="u52cdaf10" class="ne-image"/>
</p>
<p id="u40b6f6fe" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2.图表：支持折线图、柱状图</span>
</p>
<p id="u3c1cafec" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3.表格：所有数据分析类型都支持展示</span>
</p>
<p id="u491bc727" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/0fc8e283b9abcb62aa1f9f613f5b7754.jpg" width="1412.49997895211" id="u0e721e16" class="ne-image"/>
</p>
<h3 id="aOjcC" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.8保存</span>
</h3>
<p id="u75533ad2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.概览类型：支持将组件放置到我的概览或公共概览</span>
</p>
<p id="uf70647b9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2.看板：可选择需要展示的看板（支持多选），在看板页面可展示该图表。且看板与概览类型有关联关系，如：选择我的概览后，选择看板只支持选择我的概览下的看板。</span>
</p>
<p id="u43077e55" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3.同步至群组：可多选群组，选择群组后，群组的画像页面，展示该数据图表（数据图表需要筛选出只查看该群组的数据）</span>
</p>
<p id="ua994c514" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">4.标题：可输入1-10字，用于展示图表的标题</span>
</p>
<p id="ue5d3e4a7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">5.副标题：可输入1-30字，用于展示图表的副标题</span>
</p>
<p id="udf2fce0e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/6eaa92c0827175b954a8def7a7ddb33a.jpg" width="1011.249984931201" id="uf01a159d" class="ne-image"/>
</p>
<h3 id="aDI7o" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.9导出</span>
</h3>
<p id="uf81dadba" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.pdf导出：点击右上角导出按钮，可直接以pdf导出该页面</span>
</p>
<p id="ub7781b07" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/f652747117ee4a00a1498b2e2c939c5a.jpg" width="1127.499983198941" id="ud7f66936" class="ne-image"/>
</p>
<p id="u70306709" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2.excel导出：在表格右上方点击导出按钮，导出查询结果的数据，文件格式为excel文件</span>
</p>
<p id="u812835ae" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/989df99ff8db3f5aedd1276d21000c7f.jpg" width="1126.2499832175674" id="ua9732517" class="ne-image"/>
</p>
<p>
<br/>
</p>`