export default `<h3 id="rtyLe" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1.简介</span>
</h3>
<p id="ub4ad455c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">漏斗模型主要用于分析多步骤过程中每一步的转化与流失情况。可以使用漏斗分析工具来分析业务系统中某些关键路径的转化率，以确定整个流程的设计是否合理，是否存在优化空间等。同时，您可以在关键路径中找出流失人群进再分析、再挽回、再投放。另外，通过用户对比，或者按某个维度分组，能够精确定位问题，针对性地改善产品策略。</span>
</p>
<p id="uac0fa542" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(71, 86, 105); font-size: 14px">举例来说，用户购买商品的完整流程可能包含以下步骤：</span>
</p>
<ol class="ne-ol list-paddingleft-2" style="padding-left: 23px;">
<li>
    <p>
        <span class="ne-text" style="color: rgb(71, 86, 105); font-size: 14px">浏览商品</span>
    </p>
</li>
<li>
    <p>
        <span class="ne-text" style="color: rgb(71, 86, 105); font-size: 14px">将商品添加进购物车</span>
    </p>
</li>
<li>
    <p>
        <span class="ne-text" style="color: rgb(71, 86, 105); font-size: 14px">结算购物车中的商品</span>
    </p>
</li>
<li>
    <p>
        <span class="ne-text" style="color: rgb(71, 86, 105); font-size: 14px">选择送货地址、支付方式</span>
    </p>
</li>
<li>
    <p>
        <span class="ne-text" style="color: rgb(71, 86, 105); font-size: 14px">点击付款</span>
    </p>
</li>
<li>
    <p>
        <span class="ne-text" style="color: rgb(71, 86, 105); font-size: 14px">完成付款</span>
    </p>
</li>
</ol>
<p id="u5611d466" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(71, 86, 105); font-size: 14px">可以将如上流程设置为一个漏斗，分析整体的转化情况。</span>
</p>
<h3 id="uMn6L" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.使用说明</span>
</h3>
<h3 id="hTaqr" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.1新建漏斗</span>
</h3>
<p id="ue9bc639d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.进入【漏斗分析】点击漏斗下拉弹窗，点击“新建漏斗”按钮，打开“新建漏斗”弹窗。</span>
</p>
<p id="uf694070c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/84ced3ebee7d9a431dca8de9a7ea853b.jpg" width="555" id="uddcee0dc" class="ne-image"/>
</p>
<p id="ud6395288" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2.漏斗名称：必填，最长20字，不可重复。</span>
</p>
<p id="u76b821ad" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3.漏斗步骤：最少选择2步，最多支持8步，每个步骤可以指定事件，以及事件满足的过滤条件</span>
</p>
<p id="u07f838e8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/3709751fc4afaf60db1d66ac74be2322.jpg" width="793.7499881722035" id="u18dc0643" class="ne-image"/>
</p>
<p id="u01219b57" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">*针对漏斗这种需要设置用户在系统内的行为动作事件时，都需要企业在数据管理中心预设好行为事件并对接，系统不内置</span>
</p>
<h3 id="iWOAT" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.2选择分析条件</span>
</h3>
<p id="u6bb3779d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.转化窗口期：用户触发第一个步骤后，在指定窗口期内完成整个漏斗，即算作完成了转化，可选5分钟/1小时/24小时/7天/14天/30天/60天/90天。</span>
</p>
<p id="ud338ef43" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/e41c164c1086e532760d7ee7f40b075b.jpg" width="1273.7499810196462" id="ucb4e1aa5" class="ne-image"/>
</p>
<p id="u4580cbbb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2.按维度查看：选择事件指标后，可以按维度查看对指标进行细化分析。可选择的拆分维度是您选择的事件所包含的属性。</span>
</p>
<p id="u0f2e34b4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">*该项非必选，不选择默认不按属性维度拆分</span>
</p>
<p id="u2343dc14" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/6633b68dbc6be184092d5b1893adb2cd.jpg" width="651" id="PViYT" class="ne-image"/>
</p>
<p id="u1a4e661d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3.用户分群：</span><span class="ne-text">可通过积木云策创建的群组，选择目标人群来分析他们的行为特征，不选择默认为全部用户</span>
</p>
<p id="u48b8845b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/60dc338d40df77efb1b6fbc473befa08.jpg" width="256" id="AmSya" class="ne-image"/>
</p>
<h3 id="KVXBz" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.3漏斗转化/流失人群的创建</span>
</h3>
<p id="ub68a2bb8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.点击漏斗图中每一步的柱条，可进行该步转化/流失人群的圈选创建人群</span>
</p>
<p id="uf7b05f17" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/52fb8a118717a2c0203aa39528c1d46d.jpg" width="1191.249982248992" id="u5b671e05" class="ne-image"/>
</p>
<p id="ua4e2e8d8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2.创建人群时进入新增人群页，自动带入信息，并且不支持更新方式、创建方式的规则设置。</span>
</p>
<p id="u2945f64c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/56909f9f5bc1ffb34caeae976d1fbb1c.jpg" width="1466.2499781511726" id="uc6e5baf7" class="ne-image"/>
</p>
<p id="u9040f9a8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<h3 id="sq2O3" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.4查看趋势</span>
</h3>
<p id="u86c4911a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可将图标切换至「趋势」选项，查看该漏斗下转化率随时间变化的趋势。</span>
</p>
<p id="ufa0a478b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/2abecb279d6ffd684c166a3946a509a6.jpg" width="1017.4999848380687" id="ucef31785" class="ne-image"/>
</p>
<h3 id="Yr1mO" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">3.漏斗的计算方法</span>
</h3>
<h3 id="nkMCB" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">3.1指标的计算方法</span>
</h3>
<ol class="ne-ol list-paddingleft-2" style="padding-left: 23px;">
<li>
    <p>
        <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">如果一个用户行为序列在窗口期内能满足漏斗描述的所有步骤，则记完成用户数+1；</span>
    </p>
</li>
<li>
    <p>
        <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">如果一个用户行为序列能满足漏斗分析的前N个步骤，则从第N个到第1个步骤的每个步骤，完成次数+1，第N个步骤到第1个步骤的的每个步骤，完成用户数+1；</span>
    </p>
</li>
<li>
    <p>
        <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">如果一个用户的行为序列可以多次匹配漏斗描述的路径，则首先要求多次匹配的路径</span><strong><span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">没有</span></strong><span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">重叠，然后对于每个步骤，累计上该用户完成的次数，且每个步骤的完成用户数+1。</span>
    </p>
</li>
</ol>
<h3 id="rHuYO" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">3.2漏斗切分的原则</span>
</h3>
<ol class="ne-ol list-paddingleft-2" style="padding-left: 23px;">
<li>
    <p>
        <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">对于一个用户的行为序列，我们可以通过一个规则，将这个行为序列切分为一个一个的子序列，我们称作“漏斗路径”。每个漏斗路径没有重叠，可能达成了整个漏斗，也可能只达成了前N步；</span>
    </p>
</li>
<li>
    <p>
        <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">我们在观察用户行为时，按照时间顺序一个一个看，一旦有满足第一步的行为，则算用户进入了一个漏斗中，因此一个用户在某个时刻可能在多个</span><strong><span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">未确定</span></strong><span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">的漏斗中，之所以说未确定，是因为某些路径可能会因为重叠而被抛弃；</span>
    </p>
</li>
<li>
    <p>
        <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">如果一个用户行为，既满足第一步的条件，又满足第二步的条件，则</span>
    </p>
</li>
</ol>
<ol class="ne-list-wrap list-paddingleft-2" style="list-style-type: none;">
<ol ne-level="1" class="ne-ol list-paddingleft-2" style="list-style-type: lower-alpha;">
    <li>
        <p>
            <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">如果用户已经有进入的漏斗路径且在第一步，则认为该行为是漏斗路径的第二步；</span>
        </p>
    </li>
    <li>
        <p>
            <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">如果用户没有进入任何漏斗路径，或者已经入的漏斗路径已经不在第一步了，则认为用户进入了一个新的漏斗路径。</span>
        </p>
    </li>
</ol>
</ol>
<ol start="4" class="ne-ol list-paddingleft-2" style="padding-left: 23px;">
<li>
    <p>
        <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">如果一个用户行为使得某个已进入的路径“前进”了一步，则：</span>
    </p>
</li>
</ol>
<ol class="ne-list-wrap list-paddingleft-2" style="list-style-type: none;">
<ol ne-level="1" class="ne-ol list-paddingleft-2" style="list-style-type: lower-alpha;">
    <li>
        <p>
            <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">比该路径晚进入的所有路径，因与该路径重叠，则均抛弃；</span>
        </p>
    </li>
    <li>
        <p>
            <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">比该路径早进入的路径，如果到达的步骤不大于该路径已到达的步骤，则进行切割。</span>
        </p>
    </li>
</ol>
</ol>
<h3 id="w9yLD" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">3.3举例说明</span>
</h3>
<p id="u731a4629" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">假设我们的漏斗步骤定义为A-B-C，表示第一步要满足条件A，第二步要满足条件B，第三步要满足条件C，路径周期为1天。</span>
</p>
<p id="uefa87bb2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">用户行为如下：</span>
</p>
<table id="a1tCA" class="ne-table" width="750">
<tbody>
    <tr style="height: 33px" class="firstRow">
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="uc0cbcecf" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 16:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u3afae541" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 17:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u8370ab79" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 18:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u11d81f2c" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 9:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u7263d1f8" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 12:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u97773632" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 13:00</span></strong>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="ufa5f5782" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">A</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="uf4107a8b" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">A</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u855af0c2" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">B</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u1c4fa8ff" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">A</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u158c0f7e" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">C</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u1432cc97" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">C</span>
            </p>
        </td>
    </tr>
</tbody>
</table>
<p id="u9eb54e8c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">则达成的漏斗路径为</span>
</p>
<table id="mx2s2" class="ne-table" width="750">
<tbody>
    <tr style="height: 33px" class="firstRow">
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u5cb3a03a" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 16:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u55343374" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 17:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="ub3b32eb6" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 18:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u4c07c304" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 9:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u9557a6ba" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 12:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u303e4570" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 13:00</span></strong>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u7aafc2ff" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: blue; font-size: 14px">A</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u7ec81b4c" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: red; font-size: 14px">A</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="ua6761b21" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: red; font-size: 14px">B</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u79e0df0c" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">A</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u07171817" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: red; font-size: 14px">C</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u6cca8957" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">C</span>
            </p>
        </td>
    </tr>
</tbody>
</table>
<p id="ua305c4f4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">蓝色的A表示day1的时候，达成过第一步的漏斗路径，红色的ABC表示day1还达成过完整的漏斗路径。</span>
</p>
<p id="u9a0ee6b6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">另一个用户行为如下：</span>
</p>
<table id="rQNhY" class="ne-table" width="750">
<tbody>
    <tr style="height: 33px" class="firstRow">
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="ueed8f696" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 9:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u6714a5d0" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 10:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u7703f60f" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 18:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="udb28974e" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 9:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u25ba1471" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 12:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u4ab3ccf5" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 13:00</span></strong>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u39b42d9b" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: blue; font-size: 14px">A</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u8e4a54a2" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">B</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u25ceb1dd" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: blue; font-size: 14px">B</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u6caf86af" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: red; font-size: 14px">A</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u3301bb8b" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">C</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="ued8b2402" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">C</span>
            </p>
        </td>
    </tr>
</tbody>
</table>
<p id="u0618cc1c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">因为漏斗周期只有一天，所以第一天的A-B，无法拼上第二天的C，所以最终结果为day1最多达成了第二步，day2最多达成了第一步</span>
</p>
<p id="u7ce72ca5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">还有一个用户行为如下：</span>
</p>
<table id="fmWWp" class="ne-table" width="750">
<tbody>
    <tr style="height: 33px" class="firstRow">
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u6e4aaa70" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 15:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="ufb75fd08" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 16:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="ud761c1f0" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 18:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u952455ee" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 8:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="ued221c76" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 12:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u0a5b187f" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 13:00</span></strong>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u0d2b04b0" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">A</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="ub767fa58" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">B</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="ua31054e8" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">A</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u0fb8392b" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">A/B</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u1d1c4640" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">C</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="ufad2d001" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">C</span>
            </p>
        </td>
    </tr>
</tbody>
</table>
<p id="u3d7b86ef" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">其中A/B表示该行为既满足第一步又满足第二步。</span>
</p>
<p id="ud2a8e4f4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">则根据我们的规则，这个A/B行为是将其当作满足第二步的条件，给了day1 18:00进入的A</span>
</p>
<table id="x9FQ4" class="ne-table" width="750">
<tbody>
    <tr style="height: 33px" class="firstRow">
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u1aee0cc3" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 15:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u3195bcf2" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 16:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u67e5d3b3" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day1 18:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="ud35ca468" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 8:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="ua2f3cfc8" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 12:00</span></strong>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u05dcfabe" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">day2 13:00</span></strong>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u4b278185" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: blue; font-size: 14px">A</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="ua399d793" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: blue; font-size: 14px">B</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u57548853" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: red; font-size: 14px">A</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u343b2976" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">A/</span><span class="ne-text" style="color: red; font-size: 14px">B</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u74e242ef" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: red; font-size: 14px">C</span>
            </p>
        </td>
        <td width="125" style="border-color: rgb(217, 217, 217);">
            <p id="u518e1405" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">C</span>
            </p>
        </td>
    </tr>
</tbody>
</table>
<p>
<br/>
</p>`;
