import detail from "@/views/detail";

const router = [
  {
    path: "/marketingCenter",
    component: detail,
    name: "marketingCenter",
    meta: {
      menuTitle: "营销中台", // 一级菜单
      menuIcon: "icon-marketingCenter",
    },
    children: [
      {
        path: "/marketingCenter",
        component: detail,
        name: "marketingCenter",
        redirect: "/marketingCenter/memberLevel",
        meta: {
          menuTitle: "营销应用", // 二级菜单
        },
        children: [
          {
            path: "memberLevel",
            component: detail,
            name: "memberLevel",
            meta: {
              menuTitle: "会员等级", // 三级菜单
              html: require("@/assets/data/marketingCenter/memberLevel").default,
            },
          },
          {
            path: "coupon",
            component: detail,
            name: "coupon",
            meta: {
              menuTitle: "优惠券", // 三级菜单
              html: require("@/assets/data/marketingCenter/coupon").default,
            },
          },
        ],
      },
      {
        path: "/marketingCenter",
        component: detail,
        name: "marketingCenter",
        redirect: "/marketingCenter/memberLevel",
        meta: {
          menuTitle: "智能营销", // 二级菜单
        },
        children: [
          {
            path: "crowd",
            component: detail,
            name: "crowd",
            meta: {
              menuTitle: "人群营销", // 三级菜单
              html: require("@/assets/data/marketingCenter/crowd").default,
            },
          },
          {
            path: "scene",
            component: detail,
            name: "scene",
            meta: {
              menuTitle: "场景营销", // 三级菜单
              html: require("@/assets/data/marketingCenter/scene").default,
            },
          },
        ],
      },
    ],
  },
];
export default router;
