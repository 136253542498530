import detail from "@/views/detail";

const router = [
  {
    path: "/setting",
    component: detail,
    name: "setting",
    meta: {
      menuTitle: "系统管理", // 一级菜单
      menuIcon: "icon-setting",
    },
    children: [
      {
        path: "/setting",
        component: detail,
        name: "setting",
        redirect: "/setting/brand",
        meta: {
          menuTitle: "基础设置", // 二级菜单
        },
        children: [
          {
            path: "brand",
            component: detail,
            name: "brand",
            meta: {
              menuTitle: "品牌设置", // 三级菜单
              html: require("@/assets/data/setting/brand").default,
            },
          },
          {
            path: "company",
            component: detail,
            name: "company",
            meta: {
              menuTitle: "企业配置", // 三级菜单
              html: require("@/assets/data/setting/company").default,
            },
          },
          {
            path: "auth",
            component: detail,
            name: "auth",
            meta: {
              menuTitle: "权限配置", // 三级菜单
              html: require("@/assets/data/setting/auth").default,
            },
          },
          {
            path: "path",
            component: detail,
            name: "path",
            meta: {
              menuTitle: "路径配置", // 三级菜单
              html: require("@/assets/data/setting/path").default,
            },
          },
          {
            path: "rule",
            component: detail,
            name: "rule",
            meta: {
              menuTitle: "规则配置", // 三级菜单
              html: require("@/assets/data/setting/rule").default,
            },
          },
        ],
      },
    ],
  },
];
export default router;
