export default `<h3 id="HvRFk" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1.简介</span>
</h3>
<p id="ud0ab9cba" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(51, 51, 51);">目前，除了用户行为指标外，还有很多结合业务场景的特有指标需要分析；如：注册率=注册成功人数/活跃用户数，分、秒之间的单位换算等。</span>
</p>
<p id="ue9ba38a4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(51, 51, 51);">因此，积木云策提供了基础指标之间的四则运算，可灵活定义符合业务场景的特有指标，并支持自定义指标的可视化分析，以及将指标添加到数据看板中进行持续的跟踪监测的功能。</span>
</p>
<h3 id="hyTjJ" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.使用说明</span>
</h3>
<h3 id="lALTp" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.1新增自定义指标</span>
</h3>
<p id="u57bb6bdd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.指标名称：必填，最长20字，不可重复</span>
</p>
<p id="uf90820bd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2.定义指标：</span>
</p>
<p id="u3f1f6547" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·选择指标事件+属性（事件可进行筛选），</span>
</p>
<p id="ue7dbb090" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·增加公式项：点击后，右侧直接增加指标+属性字段，</span>
</p>
<p id="ub8562f5c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·减少公式项：鼠标移入公式项时，在公式项右侧展示x按钮，点击可删除公式项</span>
</p>
<p id="u2777ba12" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·括号：鼠标移入公式项后，公式项左右两侧展示括号效果，点击后高亮并显示括号</span>
</p>
<p id="uea5e70fc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·选择两公式项之间的运算符（包含：+、-、x、/）</span>
</p>
<p id="ufec88c29" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3.计算结果：可选计算结果展示的格式，包含：保留两位小数、保留整数、百分比（百分比则是将计算出来的数值x100%）</span>
</p>
<p id="u9bc8843d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/2c523a533ec0d29eda5acd271496a044.jpg" width="874.9999869614842" id="u7b624421" class="ne-image"/>
</p>
<h3 id="KSqvX" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.2自定义指标管理</span>
</h3>
<p id="u6681c939" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">已创建的自定义指标，可点击「编辑」按钮，进入指标详情，可修改指标名称、计算公式等操作</span>
</p>
<p id="u4203a7d6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/e302ded681ac898fbc8c32f2c02218ed.jpg" width="1078.7499839253726" id="u6066db42" class="ne-image"/>
</p>
<h3 id="m49fR" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.3自定义指标对比</span>
</h3>
<p id="uc5dcec3f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(51, 51, 51);">点击「对比」选择要对比的指标，可支持两个指标进行对比分析；</span>
</p>
<p id="u533bc569" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(51, 51, 51);">对比时，指标中有一个计算结果为「百分比」，趋势图展示折线+柱图的复合图表，</span>
</p>
<p id="u4108e099" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/0ed244c9c016b8f5d45b7b9dd73b4546.jpg" width="1464.999978169799" id="ua3af2e84" class="ne-image"/>
</p>
<p>
<br/>
</p>`;
