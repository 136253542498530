export default `<h1 id="GrUvr" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、导入记录列表</span>
</h1>
<p id="u4e36a955" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">因许多消费者行业客户均存在线下客户，积木云策支持通过Excel表单导入线下客户信息，导入后可与公域、私域用户同步进行分层、触达、分析等全链路运营。</span>
</p>
<p id="u36b2c3eb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d3964740e8eb5ae6232611b852f44727.jpg" width="1036.8749845493587" id="uc606e1fd" class="ne-image"/>
</p>
<p id="ucd20e8df" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>导入注意事项：</strong>
</p>
<p id="u3241359d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>·支持重复导入，每次导入都会覆盖原本客户的资产信息</strong>
</p>
<p id="u05db4dee" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>·基本覆盖信息以导入文件为准</strong>
</p>
<p id="u5eca80e4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>·手机号码是唯一标示，若导入重复系统会自动去重</strong>
</p>
<p id="u7abe4d04" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>·谨慎使用该功能，尽量避免对同一批客户重复导入，防止用户资产丢失。</strong>
</p>
<p id="uc1a29bd5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<p id="uef9e4ea7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、在导入列表页，可通过导入时间范围筛选导入顾客记录</span>
</p>
<p id="uaf56f87f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/19cbc4d2997d05157a28e4dd7f9021b7.jpg" width="1585.5555975584348" id="u4fe1c64c" class="ne-image"/>
</p>
<p id="ua0d5950b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、可点击「查看会员」按钮，查看导入的成员信息</span>
</p>
<p id="u5a2e5be1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/ba6759d81c68cda3a67fa98f3c71348d.jpg" width="1588.8889309800713" id="u55bb28d3" class="ne-image"/>
</p>
<p id="uac9bb569" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、针对已导入的员工，可点击「去运营」按钮，选择会员分组进行人群营销</span>
</p>
<p id="u11a7b89e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/db99cdd9fa875f5e6fec7332f0f8f91b.jpg" width="1580.0000418557072" id="u4d602c0b" class="ne-image"/>
</p>
<p id="u0028a4f7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<h1 id="djNou" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、新建导入</span>
</h1>
<p id="ub678488a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、进入导入列表，点击“新建导入”按钮，进入新建导入的第一步页面</span>
</p>
<p id="ub514c526" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/4f559ae34fdddaac23bd9fa45678d8a8.jpg" width="1030.624984642491" id="u266407b5" class="ne-image"/>
</p>
<p id="u8b6e1a5e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入第一步导入页面，目前仅支持通过手机号识别，当多业务系统的情况下，支持选择指定业务系统进行导入，下载导入模板并填写导入会员信息后，勾选数据导入生命即可导入</span>
</p>
<p id="u4c2b1181" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/c48a8bbaf35ffa049d12a64375dbb58f.jpg" width="1035.6249845679852" id="u66d6d94a" class="ne-image"/>
</p>
<p id="u352d62af" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/583cc52638f94e2fa55887e08613b248.jpg" width="1679.9999749660496" id="u770df12f" class="ne-image"/>
</p>
<p id="u4a7644fa" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、上传文件后在第二步页面，确认导入的会员信息是否无误，在此页面支持二次删除会员信息，同时，可以在本页面选择暂不导入，列表生成待导入记录。</span>
</p>
<p id="ub8f9aa31" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/4394164c0f9ad43396ec50e5b015d30a.jpg" width="1033.7499845959248" id="u204db9ca" class="ne-image"/>
</p>
<p id="u856b46e5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">4、点击「导入」按钮，确认导入内容无误可点击「确定导入」按钮，将此用户导入至会员列表；</span>
</p>
<p id="u429433c4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/cae1555f557f5702796f426b5a69001c.jpg" width="1588.8889309800713" id="u8a858b7f" class="ne-image"/>
</p>
<p id="u761d3090" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/6dc945c6a3d2e4b7cd1dc63e868c25bb.jpg" width="1587.7778198395258" id="u4dc822fb" class="ne-image"/>
</p>
<p id="u8d4de453" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<p>
<br/>
</p>`