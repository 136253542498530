export default `<h1 id="ohlwh" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="u515b1dac" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">积木云策对接客户全渠道触点，通过用户属性数据与行为数据灵活定义客户生命周期阶段，清晰掌握客户分布，及时调整运营侧重点。</span>
</p>
<p id="u772ca9af" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<p id="u384ca4bb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">对已有客户的生命状态进行分类分析后，云策将用户分为以下类别：</span>
</p>
<p id="ubb0e2a74" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新用户：本周新增用户（基于内置群组规则定义新增用户）</span>
</p>
<p id="ubb6dd3cd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·连续活跃用户：上周访问过且本周也访问过的人数</span>
</p>
<p id="uec543dda" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·回流用户：上周未登录且本周登录过的用户</span>
</p>
<p id="ud814c891" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·沉睡用户：本周未登录，上周登录的用户</span>
</p>
<p id="u32675ceb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·流失用户：近14天未登录的客户</span>
</p>
<h1 id="UCMXh" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、生命周期</span>
</h1>
<h3 id="QT0KV" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1、用户生命周期</span>
</h3>
<p id="u9d2eeb37" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看不同日期、不同业务系统、不同端口下各个生命周期节点的用户统计数据</span>
</p>
<p id="u07b77e35" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d2770e5dff3546f0093648687760f8fd.jpg" width="920.0000243716776" id="u9accb218" class="ne-image"/>
</p>
<p id="u23ff0ac3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新用户：本周新增用户（基于内置群组规则定义新增用户）</span>
</p>
<p id="u3a3314d3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·连续活跃用户：上周访问过且本周也访问过的人数</span>
</p>
<p id="u056368d5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·回流用户：上周未登录且本周登录过的用户</span>
</p>
<p id="u42b41b43" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·沉睡用户：本周未登录，上周登录的用户</span>
</p>
<p id="u8a81a027" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·流失用户：近14天未登录的客户</span>
</p>
<p id="u6e2e0607" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·流入流出比：新用户+回流客户/流失客户</span>
</p>
<h3 id="TIfAd" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2、统计报表</span>
</h3>
<p id="u0c9275a0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">查看不同时间不同业务系统下用户生命周期各个类型的占比数据，来查看最近一段时间内哪一个用户占比最多</span>
</p>
<p id="u1310727f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/033895af99b7c55a2426e49dbd94f395.jpg" width="913.8889130986773" id="ub44e9dee" class="ne-image"/>
</p>
<p id="uda89b03c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新用户：本周新增用户（基于内置群组规则定义新增用户）</span>
</p>
<p id="u6c7cc5ad" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·连续活跃用户：上周访问过且本周也访问过的用户人数</span>
</p>
<p id="u22521b55" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·回流用户：上周未登录且本周登录过的用户</span>
</p>
<p id="ua7341e0a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·沉睡用户：本周未登录，上周登录的用户</span>
</p>
<p id="uc5dbdf9f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·流失用户：近14天未登录的用户</span>
</p>
<h3 id="X3LbI" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">3、流入流出比</span>
</h3>
<p id="ucdc642b1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">按“周”为粒度来查看不同周期流入流出比，通过流入流出比来分析得出平台的用户流失情况，当流入流出比越高则代表流失人数越多，流入人数越少。</span>
</p>
<p id="uf16453d8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/66f7760a770de0ae8bc370342ebe0b64.jpg" width="1292.2222564544215" id="u8bc2bf43" class="ne-image"/>
</p>
<p id="u2aa63318" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·流入流出比：新用户+回流客户/流失客户</span>
</p>
<p id="ud1e56175" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：新顾客基于内置群组规则定义新增用户、回流顾客为上周未登录且本周登录过的用户、流失顾客为近14天未登录的用户</span>
</p>
<h1 id="aW7Ym" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、ltv分析</span>
</h1>
<h3 id="mrFUk" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1、注册用户LTV 7/15/30/90分析</span>
</h3>
<p id="ud61692ac" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">用户生命周期价值，即用户在生命周期中贡献的价值，ltv可分析特定日期访问的用户群体，在一定时间内所贡献的人均价值</span>
</p>
<p id="u10ca0a3b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/f735819d7b6c36b29ee95f4f7e2d69ac.jpg" width="1062.777805931775" id="u02978db1" class="ne-image"/>
</p>
<p id="u60b0ac61" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·LTV 7/15/30/90天营收：指的是过去xx天注册的用户的生命周期价值。例如：过去7天的ltv7生命周期，指的是过去7天注册的新用户他们分别在第二天、第三天、第四天。。。第八天消费了多少钱。 过去14天的ltv生命周期同理</span>
</p>
<p id="uf00f8ba0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·消费金额：消费的订单金额总计</span>
</p>
<p id="u480d0803" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·初始人数：过去7天内注册的用户总数</span>
</p>
<p>
<br/>
</p>`;
