export default `<h1 id="uDaLf" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="ub83eb5ce" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(44, 58, 70); font-size: 14px">会员等级是一种会员成长体系的对外展示形式。企业可以使用此功能，建立属于自己的会员成长体系，根据客户生命周期设置会员等级，让会员通过获取成长值、积分来决定自己的会员等级，获得不同的会员权益，从而帮助您更好的维护客户关系、保证会员的活跃度。</span>
</p>
<p id="u0e7c87fe" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(44, 58, 70); font-size: 14px"></span>
</p>
<p id="u608aa895" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(44, 58, 70); font-size: 14px">1、目前支持配置VIP1～VIP10这10个会员等级，企业可以根据实际使用需要，创建配置所需要的等级数量；</span>
</p>
<p id="ua9417f4e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(44, 58, 70); font-size: 14px">2、会员等级仅支持配置在企业自建业务系统中，不支持配置到第三方saas应用内；</span>
</p>
<p id="u53bcba07" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(44, 58, 70); font-size: 14px">3、此功能需要业务系统接入积木云策提供的会员等级API后才可在业务系统中查看等级及获取优惠券；</span>
</p>
<h1 id="AchjX" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、新增与管理会员等级</span>
</h1>
<p id="u626ff841" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>新增会员等级</strong>
</p>
<p id="u883b834a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 14px">1、管理员进入「营销中台-会员等级」，点击「新增会员等级」</span>
</p>
<p id="ufefc07ab" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/b5668c6a99df3ab354f61fcd17a5c0f4.jpg" width="1062.777805931775" id="u0db79ff3" class="ne-image"/>
</p>
<p id="u437e4571" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 14px">2、进入新增会员等级页，填写基本设置与权益设置后创建此会员等级</span>
</p>
<p id="u962141b9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 14px">备注：不可配置已有的vip等级</span>
</p>
<p id="u712e95ee" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/f6d4b4d4c231671ef29a0d099525a7cf.jpg" width="1052.2222500965927" id="ue4ec52e6" class="ne-image"/>
</p>
<p id="ue9e26a19" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）基本设置：</span>
</p>
<p id="u8884efa7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·会员名称：可输入1-10字会员名称字段</span>
</p>
<p id="u43c79def" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·会员等级：可下拉选择VIP1-VIP10（不可选已创建的VIP等级）</span>
</p>
<p id="uf8c504d2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·等级背景：支持14种不同颜色的卡面，业务系统可选择使用此卡面样式。</span>
</p>
<p id="u3585410b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·获得条件：可选择用户需要对应成长值或者积分（支持多选），当配置后，用户需要达到此成长值或积分后成为该等级</span>
</p>
<p id="u909e60de" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·业务系统：可选择该会员等级关联的业务系统（业务系统为对接积木云策后展示，可选不同业务系统使用）</span>
</p>
<p id="uefec67ec" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）权益设置：</span>
</p>
<p id="u71799a83" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·虚拟权益：可配置图标、名称、描述三种不同效果，用于前端展示不同的虚拟权益效果（虚拟权益为业务系统进入会员中心后查看的效果，为线下权益。线上只配置线下权益的描述信息）</span>
</p>
<p id="uccfd89ef" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·优惠券：支持多选优惠券（优惠券来源于积木云策-优惠券模块配置），配置完成后当用户达到该等级后自动获取配置的优惠券</span>
</p>
<p id="u2ebe9904" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<p id="ue54e8342" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>管理会员等级</strong>
</p>
<p id="ub53a2b9d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「营销中台-会员等级」，可按「业务系统」筛选会员等级列表，来筛选想要查看的会员等级</span>
</p>
<p id="u6eac8c99" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/44b814f102d35788eff62ba90ed37c33.jpg" width="1052.7778056668653" id="uf4509a6c" class="ne-image"/>
</p>
<p id="ub370ce59" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、可点击「启用/禁用」来开启或关闭会员等级配置</span>
</p>
<p id="u49be4cff" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/1ab1224902bb7dbbe5d5619df0b3b88b.jpg" width="1062.2222503615021" id="ue06a9c11" class="ne-image"/>
</p>
<p id="ub23c4f4b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：每次重新开启会员等级，都将按新的配置重新计算用户的等级。</span>
</p>
<p id="ub670b9e0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、可点击「编辑」、「删除」按钮来二次修改或删除会员等级</span>
</p>
<p id="u106934b4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/5681ade64ef244461e18ddead4fef959.jpg" width="1053.8889168074109" id="uc070adb6" class="ne-image"/>
</p>
<p id="u8b93ab8f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：修改权益配置后，不影响之前已获得优惠券权益的用户</span>
</p>
<h1 id="bcoad" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、招募会员</span>
</h1>
<p id="u6ec57134" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「营销中台-会员等级」，点击「招募会员」按钮；</span>
</p>
<p id="ucf479b7d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/4f37ade611b4c9cc0028151a221257cd.jpg" width="1058.8889169398656" id="u81bb9b26" class="ne-image"/>
</p>
<p id="u5d3fd189" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、选择对应业务系统的会员等级后点击「下一步」；</span>
</p>
<p id="uf75cdeb2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/093f389eb4ebcf24b6804155bb05ebd9.jpg" width="1060.555583650684" id="u554b4578" class="ne-image"/>
</p>
<p id="ud88aa301" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、展示招募会员海报内容，支持下载海报或只下载二维码，可将海报触达至用户后，用户扫码进入业务系统。或复制短链接，可通过短链接在外部渠道直接唤醒进入小程序；</span>
</p>
<p id="udc1cd105" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/1792da374f044f8fcd1e6910837b5cbe.jpg" width="1057.2222502290474" id="u5398dad7" class="ne-image"/>
</p>
<h1 id="kWJ7M" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">四、会员积分/成长值获取规则配置</span>
</h1>
<p id="u11cfab3f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「营销中台-会员等级」，点击「规则配置」按钮；</span>
</p>
<p id="ubf971e82" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/f72a6b42e5ffa6a6d5406fdd944657ac.jpg" width="1060.000028080411" id="uc3a2aa9d" class="ne-image"/>
</p>
<p id="ua9244b83" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、可配置会员的成长值与积分规则。当配置完成后用户达到对应消费/登录/注册场景后，可获得配置的成长值/积分。</span>
</p>
<p id="u87cfb52d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/9cd59b95922ea9d6224a81063071950c.jpg" width="1060.000028080411" id="u84578f0d" class="ne-image"/>
</p>
<h1 id="zzdd9" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">五、会员变更记录</span>
</h1>
<p id="ue2741260" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「营销中台-会员等级」，点击「变更记录」按钮；</span>
</p>
<p id="u9e192181" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/a005a03800812bd234500efbb81e2837.jpg" width="1054.4444723776837" id="ufe1414cf" class="ne-image"/>
</p>
<p id="u88398b5e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入变更记录页面，可查看会员的积分获取情况以及等级变更记录情况。</span>
</p>
<p id="u5b683324" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/072bed778423e8771c8d5db05683f0f5.jpg" width="1045.000027683047" id="u3910a7df" class="ne-image"/>
</p>
<p id="u3e28d180" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、可通过客户昵称/手机号「关键词」、「会员来源」、「积分筛选」、「群组筛选」、「会员等级」、「业务系统」来查找想要查看的用户积分/等级变更记录</span>
</p>
<p id="u08437067" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/99d8501480c1ad5391d725394dd03479.jpg" width="1060.000028080411" id="u6d3a4bf5" class="ne-image"/>
</p>
<p id="u871b245c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）搜索客户：可通过客户的昵称或者手机号码来模糊查找用户</span>
</p>
<p id="ueeb303b7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）会员来源：可选择不同的会员来源渠道，可在弹窗顶部进行：小程序、自然注册、自定义渠道切换。其中小程序渠道有（小程序模版消息、公众号模版消息、搜索打开、扫描小程序码、公众号菜单、公众号文章、长按识别、聊天卡片）、自定义渠道则展示渠道管理中创建的渠道信息。</span>
</p>
<p id="ufcbb4be5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）积分筛选：输入会员积分区间筛选符合积分区间范围的用户</span>
</p>
<p id="u5e84037c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）群组筛选：下拉展示群组列表，可多选群组，筛选所在群组内的用户</span>
</p>
<p id="udf9b91e7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）会员等级：积木云自带的等级系统，根据等级筛选符合对应等级的用户</span>
</p>
<p id="u1d8ba19e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（6）业务系统：下拉展示业务系统，可多选业务系统（业务系统为我们为客户对接的系统）</span>
</p>
<p id="ua2413a49" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<p id="u76c934d3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">4、可点击「导出Excel」或「查看用户」来导出文件或查看详细用户信息；</span>
</p>
<p id="u12b44a63" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d7af3879f49e97b3626ba5306a40168e.jpg" width="1060.000028080411" id="u2f390cc7" class="ne-image"/>
</p>
<p>
<br/>
</p>`