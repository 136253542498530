export default `<h1 id="UA41V" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="uae66f798" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">在权限管理中，管理员/负责人可在PC端添加管理组，对角色进行权限设置，如成员列表、PC功能权限；</span>
</p>
<p id="2b7c22a924b23460816e2e12094a7d9b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px"></span>
</p>
<p id="017dd9078ff2f8c03ebc84117ccf0159" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">积木云策有两种角色：超级管理员、其他成员</span>
</p>
<p id="5d0d5953c4085e56f478386bcbb8b1a1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">超级管理员：超级管理员可查看全部功能；</span>
</p>
<p id="863af9dd768650a1de35e77ef60bfc41" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">其他成员：可按实际部门结构，来划分不同管理组，不同管理组查看不同的功能；</span>
</p>
<h1 id="jsS5c" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、管理组</span>
</h1>
<p id="ubbfbf3cf" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">进行管理组创建后支持配置管理组可查看的功能权限（即所属该分组的成员，可查看并使用的功能），配置后添加成员至该管理组内后，成员即可使用手机号与密码进行积木云策后台登录；</span>
</p>
<p id="u319a11e8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：系统组为内置分组，不可删除此分组；</span>
</p>
<p id="u8de83603" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/e8df9c0ae17ea3dc4a03cc056d40dcba.jpg" width="1577.7778195746162" id="uc459c50a" class="ne-image"/>
</p>
<h3 id="D4bi5" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（一）添加管理组</span>
</h3>
<p id="u48e231c3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「系统管理-权限配置-管理组」，点击「添加管理组」按钮进入新增管理组页；</span>
</p>
<p id="u4b3318a4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/17587c42985a4eea00ddd12afc4fda79.jpg" width="1587.7778198395258" id="u46e29a64" class="ne-image"/>
</p>
<p id="u2a367ac1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入「新增管理组」页面，填写「管理组名称」、「添加管理员」、「功能权限」后即可创建管理分组</span>
</p>
<p id="u244a7f04" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d6711f2f80a83ff9c8b713ccf4d1bff3.jpg" width="1587.7778198395258" id="u9f276162" class="ne-image"/>
</p>
<p id="ue3517a52" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·管理员名称：可输入1-15字管理员名称内容，用于内部分组查看；</span>
</p>
<p id="uac5fd027" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·管理员列表：可选择任意管理员（选择后会覆盖之前所选管理员之前所在的管理组）；</span>
</p>
<p id="uf5f231d6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·功能权限：展现结构树功能菜单，功能菜单详细到二级页面，可选择该管理组可查看的功能；</span>
</p>
<p id="u1605f750" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<p id="uff4d7eb6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<h3 id="jnv2W" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（二）编辑与删除管理组</span>
</h3>
<p id="u073d2d52" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「系统管理-权限配置-管理组」，选中任意管理组后，点击「编辑」按钮；</span>
</p>
<p id="u2cc27d33" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/c38f6e39452e5e41cfeef6abd14b1740.jpg" width="1595.5555978233442" id="uf3e5dcea" class="ne-image"/>
</p>
<p id="u8e98e574" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入编辑页，可修改「管理组名称」、「管理组成员」、「管理组功能权限」等功能</span>
</p>
<p id="u6cf09656" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/37db5cee8ab23c9aadf1dd179981fdd7.jpg" width="1591.1111532611624" id="u971fb720" class="ne-image"/>
</p>
<p id="u9fa49407" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、若需要删除管理组，可选中任意管理组，点击「删除」按钮来删除分组；</span>
</p>
<p id="u008a60a2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/1c411455905a317ad11a8e078ca2afe7.jpg" width="1591.1111532611624" id="u5843c865" class="ne-image"/>
</p>
<p id="ubb8039c4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：超级管理员分组无法删除；</span>
</p>
<p id="ud4391ffa" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<h1 id="XA2nd" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、管理员管理</span>
</h1>
<p id="u682c80a5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">此功能支持管理员成员创建，创建后关联至指定管理组后即可使用该账号登录积木云策平台进行使用；</span>
</p>
<p id="u8123cf97" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/ec947c596dbce2afddf0c83707c84179.jpg" width="1581.1111529962527" id="u192c208b" class="ne-image"/>
</p>
<h3 id="pP1yL" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（一）添加管理员</span>
</h3>
<p id="u6bdfc208" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「系统管理-权限配置-管理员管理」，点击「新增管理员」按钮进入管理员新增页；</span>
</p>
<p id="uf244ea4d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d9f0ccfb8fd23f01bd2b21e076d299a7.jpg" width="1591.1111532611624" id="u0e245334" class="ne-image"/>
</p>
<p id="u93eb62fe" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入新增管理员页面，填写「姓名」、「手机号」、「密码」、所属「管理组」后即可创建成功</span>
</p>
<p id="u941f6885" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/6c27b11ec73f8798aa276d8f87819646.jpg" width="1592.2222644017077" id="uf8e7f911" class="ne-image"/>
</p>
<p id="u2b0732b2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·姓名：可输入1-10字管理员姓名，用于内部查看使用；</span>
</p>
<p id="u47179327" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·手机号：输入11位管理员实际使用手机号，用作管理员账号使用；</span>
</p>
<p id="u568da522" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·密码：可输入1-25位密码字符，支持特殊符号、大小写、中英文数字，用于管理员登录账号时输入密码登录；</span>
</p>
<p id="u65c45b9a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·管理组：可点击下拉选择管理组，选择后该管理员即可按照该管理组权限进行登录使用；</span>
</p>
<h3 id="NrxJC" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（二）管理员管理</span>
</h3>
<p id="ueb5df5a5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「系统管理-权限配置-管理员管理」，可在搜索框内输入管理员名称模糊搜索管理员；</span>
</p>
<p id="uf8949898" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/8d9925a251e5a51eeaa61aca258402f0.jpg" width="1594.4444866827987" id="u1430434f" class="ne-image"/>
</p>
<p id="uaafbb94a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、若需要更换管理员所在管理组的情况下，可在管理员列表点击「更换管理组」按钮，进行管理组替换，替换后管理员重新登录后即可按照新的管理组身份使用积木云策平台；</span>
</p>
<p id="u3441be4a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/657f05ce1b169dc1e6ba0aa8d56fb935.jpg" width="1590.0000421206169" id="u98f3476c" class="ne-image"/>
</p>
<p id="uf6721ad3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、若需要禁用管理员的情况下，可点击「禁用」按钮来禁止管理员登录后台，或点击「删除」按钮删除管理员</span>
</p>
<p id="udf0d18f1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/a39da9912e5a67fe5d5bf9381ecbb6cf.jpg" width="1590.0000421206169" id="u6b43dadf" class="ne-image"/>
</p>
<p id="u6ac0d192" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">4、若需要修改管理员信息的情况下，可点击「编辑」按钮进入编辑页</span>
</p>
<p id="u6cfbe22f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/6ebc56e8b5315a95b7993a4895ed7f7e.jpg" width="1593.3333755422532" id="u84d58323" class="ne-image"/>
</p>
<p id="u6dec56d9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">5、进入编辑页后可修改管理员所有信息</span>
</p>
<p id="ua9f62271" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/b6a4c5df1727f8d56cde649a5d4f2888.jpg" width="1594.4444866827987" id="uc771bd35" class="ne-image"/>
</p>
<p id="u450067cb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：密码为空点击「确定」即代表不修改密码；</span>
</p>
<p id="uf350149a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<h1 id="caO5e" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">四、操作日志</span>
</h1>
<p id="ubeb0c30d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「系统管理-权限配置-操作日志」，可在此模块查看管理员在积木云策后台操作的模块、操作内容、操作时间、IP地址等详细信息，基于操作日志对系统起到debug、运维监控、业务分析的关键作用；</span>
</p>
<p id="ue544bce6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/664d26aca415feea4b1395b6ee6562ba.jpg" width="1590.0000421206169" id="uc89057ab" class="ne-image"/>
</p>
<p id="uaa261279" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、可筛选「日期范围」、「输入关键词」来模糊搜索操作日志，更精确定位想要查看的操作日志内容；</span>
</p>
<p id="u9d4e59c9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/152220292942478b7c45957aff35cb11.jpg" width="1597.7778201044352" id="u013db110" class="ne-image"/>
</p>
<p>
<br/>
</p>`;
