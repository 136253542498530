import detail from "@/views/detail";

const router = [
  {
    path: "/marketAnalyse",
    component: detail,
    name: "marketAnalyse",
    meta: {
      menuTitle: "营销分析", // 一级菜单
      menuIcon: "icon-marketAnalyse",
    },
    children: [
      {
        path: "/marketAnalyse",
        component: detail,
        name: "marketAnalyse",
        redirect: "/marketAnalyse/marketingPannel",
        meta: {
          menuTitle: "营销看板", // 二级菜单
        },
        children: [
          {
            path: "marketingPannel",
            component: detail,
            name: "marketingPannel",
            meta: {
              menuTitle: "营销看板", // 三级菜单
              html: require("@/assets/data/marketAnalyse/marketingPannel").default,
            },
          },
        ],
      },
      {
        path: "/marketAnalyse",
        component: detail,
        name: "marketAnalyse",
        redirect: "/marketAnalyse/marketingPannel",
        meta: {
          menuTitle: "营销数据", // 二级菜单
        },
        children: [
          {
            path: "coupon",
            component: detail,
            name: "coupon",
            meta: {
              menuTitle: "优惠券分析", // 三级菜单
              html: require("@/assets/data/marketAnalyse/coupon").default,
            },
          },
          {
            path: "activity",
            component: detail,
            name: "activity",
            meta: {
              menuTitle: "活动分析", // 三级菜单
              html: require("@/assets/data/marketAnalyse/activity").default,
            },
          },
          {
            path: "channel",
            component: detail,
            name: "channel",
            meta: {
              menuTitle: "触达渠道分析", // 三级菜单
              html: require("@/assets/data/marketAnalyse/channel").default,
            },
          },
        ],
      },
    ],
  },
];
export default router;
