export default `<h1 id="faOPW" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="u384e48e8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 14px;">粉丝转化提供企业下公众号粉丝数所带来的收益情况，可按不同日期查看公众号的UV、下单人数、下单订单数等数据变化，帮助商家从转化率角度科学评估粉丝资产，进一步指导运营决策。</span>
</p>
<p id="u316f9481" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 14px;"></span>
</p>
<p id="u6357376a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(51, 51, 51); font-size: 14px">此外，基于企业存在有多个公众号情况，积木云策支持</span><strong><span class="ne-text" style="color: rgb(51, 51, 51); font-size: 14px">跨公众号查看运营状况</span></strong><span class="ne-text" style="color: rgb(51, 51, 51); font-size: 14px">，做到一个看板管理所有公众号，便于大盘评估，并为后续公众号运营提供指导</span>
</p>
<h1 id="QQysF" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、粉丝转化统计</span>
</h1>
<p id="uf73fc10a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 14px">可查看公众号指定日期范围内的独立访问数量以及关注公众号后用户的下单情况</span>
</p>
<p id="u6d65dbad" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/3c1a22e3e2b8bb8b2fb3508a31b77c8c.jpg" width="918.8889132311322" id="ubb6ee569" class="ne-image"/>
</p>
<p id="u3a772970" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/4be13f17e380f39e3b3fd6a3953d429d.jpg" width="1058.8889169398656" id="ucbe4d49a" class="ne-image"/>
</p>
<p id="u1ddc4bbc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·粉丝UV：指定日期，微信公众号的独立访问数量（1天内相同用户多次访问只计算为一次）</span>
</p>
<p id="ue4fc994c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·粉丝下单人数：指定日期下关注公众号的粉丝，在系统内下单的人数</span>
</p>
<p id="u6f47f9f1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·粉丝下单订单数：指定日期下关注公众号的粉丝，在系统内下单的订单数</span>
</p>
<h1 id="XRKus" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、转化图表</span>
</h1>
<p id="u4197a664" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可通过此图表查看指定日期下的粉丝收益情况</span>
</p>
<p id="ue5257b5d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/393b4d452dcf3d4db9791268780a8bbe.jpg" width="919.4444688014049" id="udb23fe9f" class="ne-image"/>
</p>
<p id="u548b4059" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·粉丝UV：指定日期，微信公众号的独立访问数量（1天内相同用户多次访问只计算为一次）</span>
</p>
<p id="ue45b825a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·粉丝下单人数：指定日期下关注公众号的粉丝，在系统内下单的人数</span>
</p>
<p id="ucfa7946e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·粉丝下单订单数：指定日期下关注公众号的粉丝，在系统内下单的订单数</span>
</p>
<p id="u9fadd1f8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·粉丝图文下单率：图文的每日新增从图文引流到小程序下单人数（不计是否支付，且退出后后续重新进入下单也视为下单）/图文引流uv数</span>
</p>
<p id="u6468d6cc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·粉丝下单金额：指定日期下关注公众号的粉丝，在系统内下单的订单金额总计</span>
</p>
<p>
<br/>
</p>`;
