import Vue from "vue";
import VueRouter from "vue-router";
// import store from "../store/index";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

import workbench from "./workbench";
import memberCenter from "./memberCenter";
import analysisEngine from "./analysisEngine";
import memberAnalyse from "./memberAnalyse";
import marketingCenter from "./marketingCenter";
import marketAnalyse from "./marketAnalyse";
import setting from "./setting";

// import Analyze from "./analyze";
// import FAQ from "./FAQ";
// import detailPage from "@/views/detailPage/index.vue";

const routes = [
  // 根目录跳转到工作台
  {
    path: "/",
    redirect: "/workbench",
  },

  ...workbench,
  ...memberCenter,
  ...analysisEngine,
  ...memberAnalyse,
  ...marketingCenter,
  ...marketAnalyse,
  ...setting,
  // ...FAQ,
  // ...Analyze,
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  next();
});

export default router;
