export default `<h1 id="fUcjm" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="u52e1f519" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">路径配置功能主要用于外部引流渠道创建时所选“小程序”、“APP”渠道时，可选择指定路径进行跳转查看；管理员可配置小程序、APP的活动路径至该模块后搭配渠道管理使用来达到引流更有效的目的。</span>
</p>
<h1 id="TH3JM" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、小程序路径</span>
</h1>
<h3 id="D4bi5" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（一）创建小程序路径</span>
</h3>
<p id="uef4e4e01" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「系统管理-路径配置-小程序」，可点击「新增页面路径」按钮来新建页面路径；</span>
</p>
<p id="u754b29b5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/9b7119536039f11198529696d768961b.jpg" width="1594.4444866827987" id="u512d5a0e" class="ne-image"/>
</p>
<p id="u8652f2f4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、弹出新建页面路径弹窗后，需填写「所属小程序」、「页面名称」、「页面路径」、「页面参数」来创建路径进行使用；</span>
</p>
<p id="u68bc385b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/8782fb713628ab824423b58412d0c4ae.jpg" width="1590.0000421206169" id="u72442c0c" class="ne-image"/>
</p>
<p id="u7d9c001d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·所属小程序：可下拉选择在「企业配置-小程序配置」中已创建好的小程序；</span>
</p>
<p id="ue82a06df" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·页面名称：可输入该页面路径的名称，用于内部管理查看；</span>
</p>
<p id="u9bd8c525" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·页面路径：输入在小程序中该页面的实际路径（备注：页面路径需要开发人员提供）；</span>
</p>
<p id="ub672be2e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·页面参数：可输入该此页面路径下的页面参数，用于跳转带有指定参数的页面；</span>
</p>
<h3 id="ngf9E" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（二）管理小程序路径</span>
</h3>
<p id="u6826e6c3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「系统管理-路径配置-小程序」，可下拉选择「所属小程序」、或输入「页面路径」关键词来模糊搜索查找页面路径；</span>
</p>
<p id="u6f0c5bf3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/cf062f14c1ca5d6c3974795b400ab5eb.jpg" width="1588.8889309800713" id="u6bc154a1" class="ne-image"/>
</p>
<p id="ufc465b48" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、可点击「编辑」、「删除」按钮来二次修改路径信息或删除页面路径；</span>
</p>
<p id="uf3490a97" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/2ecc42576416699c1e3ca75c17e00b95.jpg" width="1593.3333755422532" id="ua28fb1df" class="ne-image"/>
</p>
<h1 id="nHsox" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、APP路径</span>
</h1>
<h3 id="xBncE" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（一）创建APP路径</span>
</h3>
<p id="ub2adb123" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「系统管理-路径配置-APP」，可点击「新增页面路径」按钮来新建页面路径；</span>
</p>
<p id="u9041377f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/9f48b14b27f747fd07239f684ebe9345.jpg" width="1590.0000421206169" id="u61519f70" class="ne-image"/>
</p>
<p id="ufc4bc4ee" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、弹出新建页面路径弹窗后，需填写「所属APP」、「页面名称」、「页面路径」、「页面参数」来创建路径进行使用；</span>
</p>
<p id="u78586541" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/423aae5fdeb5041d2364116662512bf2.jpg" width="1590.0000421206169" id="u7c881829" class="ne-image"/>
</p>
<p id="uecc2263d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·所属APP：可下拉选择在「企业配置-APP配置」中已创建好的APP；</span>
</p>
<p id="u7536169a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·页面名称：可输入该页面路径的名称，用于内部管理查看；</span>
</p>
<p id="u5a59251a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·页面路径：输入在APP中该页面的实际路径（备注：页面路径需要开发人员提供）；</span>
</p>
<p id="u94e2445f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·页面参数：可输入该此页面路径下的页面参数，用于跳转带有指定参数的页面；</span>
</p>
<h3 id="y0HVy" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（二）管理APP路径</span>
</h3>
<p id="u21da8d4d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「系统管理-路径配置-APP」，可下拉选择「所属APP」、或输入「页面路径」关键词来模糊搜索查找页面路径；</span>
</p>
<p id="u09ecc90c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/a6b7ce28dc5bb5e9c729981119c7544c.jpg" width="1588.8889309800713" id="u1781e123" class="ne-image"/>
</p>
<p id="ud1de2ec3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、可点击「编辑」、「删除」按钮来二次修改路径信息或删除页面路径；</span>
</p>
<p id="u0726f6cf" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/de76e83e9bf5a9d8a2e8b420d583adbf.jpg" width="1594.4444866827987" id="ufbd660e1" class="ne-image"/>
</p>
<p>
<br/>
</p>`;
