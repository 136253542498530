export default `<h1 id="wJFm5" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="ucdf69bd2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">1、销售/导购在与客户交流过程中，给客户打上标签，如客户的【渠道来源】、【客户身份】、【所属行业】等，以便后续针对标签进行精细化和分层运营。</span>
</p>
<p id="uc8490623" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">2、可通过会员列表进行批量打标签操作；</span>
</p>
<p id="u5cc70608" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">3、基于标签支持进行会员群组创建，将符合标签的人员拉入群组内，并可查看群组内的数据分析及营销触达。</span>
</p>
<h1 id="mPzLY" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、创建标签</span>
</h1>
<p id="u682709cb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">1、管理员点击【会员中台-标签管理】，点击「新建标签」按钮</span>
</p>
<p id="b4828b4eec7bf4fdeaf31edd8e13b0ca" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">备注：在管理员为客户打标签之前，管理员需要在此模块新建标签信息</span>
</p>
<p id="3275b234f733bfbbb4558a6d7c35d8d5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/eb550652c1fc8fa1539f5c81bfb22743.jpg" width="1595.5555978233442" id="u0fa03396" class="ne-image"/>
</p>
<p id="u957a3d23" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、填写「标签名称」，选择运营标签的情况下无需选择其他内容，选择对应的关联标签组后点击「保存」即可创建标签；</span>
</p>
<p id="u00832f9d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/28e2b4ca396ab55219ec7526e90dbde1.jpg" width="1581.1111529962527" id="uf9405118" class="ne-image"/><span class="ne-text">（1）标签名称：可输入1-15字标签名称信息</span>
</p>
<p id="u8b6a4916" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）标签类型：</span>
</p>
<p id="u36d40211" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·运营标签：人工创建的标签，后续可通过此标签人工给用户打上标签内容</span>
</p>
<p id="uff89470d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·规则标签：可按用户的属性、行为进行筛选符合条件的用户，将符合条件的用户自动加入此标签</span>
</p>
<p id="uf0c585e3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）选择标签组：可下拉选择标签组信息</span>
</p>
<p id="uefdd3886" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、若选择「运营标签」的情况下，需选择用户的属性和行为信息，选择多个条件的情况下取条件之间的交集，创建完成后，将符合条件的用户自动打上此标签</span>
</p>
<p id="ub5ada9a1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/88cffea2552257d6f7e286c62d115ffb.jpg" width="1590.0000421206169" id="u370a5944" class="ne-image"/>
</p>
<p id="u1ab7eb1a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>用户属性：</strong>
</p>
<p id="u52f3cc20" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·VIP等级：可选择云策内定义的会员等级选择，将符合会员等级的用户即打上此标签；</span>
</p>
<p id="u85b1c28c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·性别：按用户的性别筛选（根据对接的业务系统返回的用户性别筛选，支持二次编辑）；</span>
</p>
<p id="u6990cc6d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·所在城市：按用户所在的城市进行筛选，点击可弹出地理位置选择器，选择省份及对应的城市（根据对接的业务系统返回的用户所在城市进行筛选，支持二次编辑）；</span>
</p>
<p id="u2e7d2f3e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·出生年份：按时间范围筛选用户的出生年份信息（根据对接的业务系统返回的用户出生年份进行筛选，支持二次编辑）；</span>
</p>
<p id="u6f410052" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·注册时间：可选固定/相对时间范围内注册或者进入业务系统的用户；</span>
</p>
<p id="u606a8a71" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·生日：可选固定/相对时间范围内生日的用户（生日信息通过对接业务系统获取，支持二次编辑）；</span>
</p>
<p id="ub5e821ee" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·客单价：可按用户在平台的客单价筛选用户信息（客单价=客户在平台的消费金额/购次）；</span>
</p>
<p id="u233b63f3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·消费次数：可按客户累计在平台内支付成功的订单数量进行筛选，筛选出指定消费次数的用户；</span>
</p>
<p id="ud02b20c2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·累计消费金额：可按客户累计消费金额进行筛选；</span>
</p>
<p id="u1701b829" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·消费行为：可选指定时间内，购买/未购买过商品的客户（以支付成功为准，不剔除退款）；</span>
</p>
<p id="u3bdc86af" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·积分：可选客户当前拥有的积分范围进行筛选，筛选出符合所选条件的用户；</span>
</p>
<p id="u3d808b85" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·来源渠道：可根据用户的添加渠道进行筛选，目前支持通过小程序/自然注册/自定义渠道三种进行筛选；</span>
</p>
<p id="u140f008a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>用户行为：</strong>
</p>
<p id="ub199d4bc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问时长：可选择用户访问时长的时间范围，来筛选符合用户访问时长的用户；</span>
</p>
<p id="u060887eb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问次数：可按用户访问业务系统的次数来筛选用户，</span>
</p>
<p id="uac80c88f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·领取优惠券次数：可按用户领取优惠券的次数来筛选用户（以券为单位，领取单张券为1次）</span>
</p>
<p id="u852927c9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·使用优惠券次数：可按用户使用优惠券的次数来筛选用户</span>
</p>
<p id="uc4ad3606" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·是否关注公众号：按用户是否关注公众号来筛选用户（业务系统需要有公众号用户体系，才可使用此筛选功能）</span>
</p>
<p id="u1fca3e7f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·参与活动次数：按用户参与营销活动的次数来筛选用户（活动指的是用户参与积木云策下发的人群/场景营销活动）</span>
</p>
<p id="u65ca3054" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·是否分享：按用户是否存在分享行为来筛选用户（指的是在业务系统层面上进行的分享动作）</span>
</p>
<p id="ud3ffdfd0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·订单次数：筛选时间范围内符合下单次数的用户（指在业务系统上存在下单行为）</span>
</p>
<p id="u6c92ffde" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·取消订单次数：筛选时间范围内符合取消订单次数的用户（指在业务系统上存在取消订单行为）</span>
</p>
<p id="u1e604779" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·短信点击次数：筛选时间范围内用户点击短信短链接的次数</span>
</p>
<p id="uf6c3a4b1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·push点击次数：筛选时间范围内点击过app PUSH推送的用户</span>
</p>
<p id="u2d16b28d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·公众号点击次数：筛选时间范围内点击过公众号模板消息的用户</span>
</p>
<p id="u498b1430" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·全端启动：筛选时间范围内进入过业务系统的用户（业务系统指的是对接积木云策的小程序/APP/网站）</span>
</p>
<p id="u408cfda3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·完成营销活动：筛选时间范围内完成过营销活动的用户（完成营销活动指的是积木云策下发的人群/营销活动内的完成活动定义。）</span>
</p>
<h1 id="rpXBP" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、创建标签组与查找标签</span>
</h1>
<p id="u85093521" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「会员中台-标签管理」，点击「标签组」进入标签组列表，可点击「新增标签组」按钮添加标签组</span>
</p>
<p id="u2754aaf4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/8e0e73f543815e70861bf51f779f40c3.jpg" width="786.1111319359444" id="ua3b955b6" class="ne-image"/>
</p>
<p id="u0b982bb9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入标签组列表，点击「新增标签组」填写1-10字标签组名称后创建标签组</span>
</p>
<p id="ufd879317" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/4e3d5e702c0f752086382c14b8611ccd.jpg" width="786.1111319359444" id="ud28ff787" class="ne-image"/>
</p>
<p id="u47953864" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、可通过输入标签组名称「关键字」来搜索标签组</span>
</p>
<p id="u6e4d19dc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d96426d3a1c8fd98208e8225a023b929.jpg" width="786.1111319359444" id="ub8fd2ac7" class="ne-image"/>
</p>
<p id="ud734b840" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">4、管理员进入「会员中台-标签管理」，可通过标签「关键字」或筛选标签组来查找标签</span>
</p>
<p id="ua4ed4e39" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/7c5b5d9e2b072ac8cbd0123c5d56d798.jpg" width="783.8889096548534" id="u00dc8ce3" class="ne-image"/>
</p>
<h1 id="AuI9n" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">四、使用标签</span>
</h1>
<p id="uddd3d161" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>管理员给用户批量打标签</strong>
</p>
<p id="ue17877c1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">1、进入【会员中台-会员列表】，通过筛选会员名称、会员来源、注册时间后，选择客户并点击「批量打标签」</span>
</p>
<p id="ud1e57de6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/1c5909de34085d5db108d3303e05f4b2.jpg" width="778.3333539521258" id="uc3c37b68" class="ne-image"/>
</p>
<p id="uf33651ba" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入批量打标签页面，选择需要打上的标签，点击「确定」即可完成批量打标签操作</span>
</p>
<p id="ubf2fefbc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/cabf67106b6df1b3d265cb1f0ccfbaf9.jpg" width="785.0000207953989" id="u65575f50" class="ne-image"/>
</p>
<p id="ub7536e8f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>管理员通过标签来创建顾客群组</strong>
</p>
<p id="u29f0499f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">1、进入【会员中台-会员群组】，选择点击「新建会员组」；</span>
</p>
<p id="u56278144" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/ba3eec1f0e33ce427b5b37f763defd8a.jpg" width="784.4444652251261" id="u180937b5" class="ne-image"/>
</p>
<p id="u49730207" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、填写「分组名称」、「分组描述」、「业务系统」、「更新方式」后，选择创建方式为「标签创建」；</span>
</p>
<p id="u81667afa" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/3d7d45429c714bd9d0e7fe2ce81e290f.jpg" width="786.666687506217" id="ua830b526" class="ne-image"/>
</p>
<p id="uaa592068" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、进入【分组规则】页面，选择标签后，选择「满足任意一个标签」或「同时满足所有标签」，可点击「计算人群」来查看符合条件的人数，确认无误后点击「创建」按钮即可创建分组；</span>
</p>
<p id="u811b2748" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/f2db877872b9058a9f252151ddd437f7.jpg" width="781.1111318034896" id="u358f1c1e" class="ne-image"/>
</p>
<p id="ub92dbb26" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/e4a31521b8a10ea0673103ac33c79cb1.jpg" width="787.7777986467626" id="ufea8276a" class="ne-image"/>
</p>
<h1 id="ndnZd" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">五、常见问题</span>
</h1>
<p id="u52edd8a5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">Q:我有企业微信，且有完善的标签体系，积木云策的标签体系支持同步企业微信吗？</span>
</p>
<p id="bb4fdd8ca2d4ff66394136e288d891f4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">A:积木云策标准版本不支持同步，可通过定制开发的形式增加此功能</span>
</p>
<p id="0524f267050a6fe44c91fedf12b64f13" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px"></span>
</p>
<p id="dc9019918c554e4bf3372233d00a8b7d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">Q:积木云策中的客户标签最多能输入几个字？</span>
</p>
<p id="5638d09e903e204c60c67b9b9c4b3be5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">A:汉字/英文/数字/符号加起来不超过15个字。</span>
</p>
<p id="899100fa7ad274c22cafc8c6d917ac5e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px"></span>
</p>
<p id="35e773facc7776a0f394a8eebbbf4b6d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">Q:积木云策可以查看一个客户标签下有多少客户吗</span>
</p>
<p id="ec7303e0cdee3eb1cdc27bc9fd68104d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">A:可以，在积木云策-会员中台-会员列表中通过标签筛选该标签下有多少客户</span>
</p>
<p>
<br/>
</p>`