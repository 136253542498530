<template>
  <div class="main">
    <top-menu class="m-top" />
    <div class="m-main">
      <left-menu class="m-left" />
      <router-view class="m-page"></router-view>
    </div>
  </div>
</template>

<script>
import leftMenu from "./common/leftMenu.vue";
import topMenu from "./common/topMenu.vue";
export default {
  components: {
    leftMenu,
    topMenu,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less">
.main {
  .m-top {
    position: fixed;
    width: 100%;
  }
  .m-main {
    .m-left {
      height: calc(100vh - 69px);
      position: fixed;
      top: 69px;
    }
    .m-page {
      margin-left: 262px;
      padding: 89px 20px 20px 20px;
      overflow-y: auto;
    }
  }
}
</style>
