export default `<h1 id="sozm5" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="udc25213a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">活动大盘数据概览，全盘了解，全面了解一段时间内，营销活动进行情况 ；详解投入产出比、活动转化趋势、效果趋势，全面总结活动效果；</span>
</p>
<h1 id="xoGni" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、各模块数据看板介绍</span>
</h1>
<h3 id="eJ6fb" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1、活动分析</span>
</h3>
<p id="u614ef613" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">系统将营销活动大盘数据汇总在此模块，管理员可快速查看营销活动执行情况以及参与活动用户情况</span>
</p>
<p id="ucb1fdd22" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/dbb8b5e5dffb913faf112da03b6bfb09.jpg" width="1330.0000352329687" id="u2ceb3a1a" class="ne-image"/>
</p>
<p id="u8dab2bae" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="u40f9a4f9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）历史活动总数：周期内正在执行的长期活动+单次活动数量</span>
</p>
<p id="ue7290acf" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）进行中的活动：周期内在进行中的长期活动+单次活动数量</span>
</p>
<p id="ucba92d6e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）参与活动的用户数：周期内参与过活动的用户数（多次参加去重）</span>
</p>
<p id="ufd9ad1b8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）人均参与活动次数：周期内参与活动的次数/周期内参与过活动的用户数x100%=人均参与次数</span>
</p>
<p id="ufd125a16" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）日期筛选：可选择昨日/近7日/近15日/近30日/近半年/近一年或自定义日期来筛选所属日期范围内创建的活动数量、进行中的活动、在这个日期内参与活动的人数、人均参与次数</span>
</p>
<p id="ud224b49c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：只有昨日会展示环比/同比，筛选日期后不展示同比/环比</span>
</p>
<h3 id="eXAeI" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2、活动参与排行榜</span>
</h3>
<p id="ub193d4c7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看近30天创建活动的参与排名情况，用于快速分析活动情况</span>
</p>
<p id="u51d919cb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/6803e944dfdfebf12ccbf36c3ce46d2a.jpg" width="634.444461251483" id="u7a26010c" class="ne-image"/>
</p>
<p id="u68636d28" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="ubfdf85f4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）排名：按参与人数倒序排名，越高越靠前</span>
</p>
<p id="u8ddc1d51" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）活动名称：创建该营销计划时填写的名称</span>
</p>
<p id="ue1daf0e5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）参与人数：活动的参与人数</span>
</p>
<p id="u19163812" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）完成人数：该活动完成的人数</span>
</p>
<p id="u4dba3cf9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）触达人数：该活动触达的人数（实际通知成功的人数，当多渠道通知时，同一个用户多渠道通知也是近计算为1人的）</span>
</p>
<h3 id="UT9vq" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">3、线上参与活动漏斗</span>
</h3>
<p id="uecf46c7c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">以横行柱状图的形式代替漏斗，展示过去30天的总活动参与人数、完成活动人数、支付人数，以及三个阶段之间的转化率，通过此漏斗可分析用户在哪一个步骤流失人数最多，进行针对性活动优化。</span>
</p>
<p id="u4de5df99" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/3e251a55788953df8c97c6394b4a9fb7.jpg" width="640.0000169542105" id="ua4672b6b" class="ne-image"/>
</p>
<p id="ud7163273" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="u87a2c833" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）参与活动人数：参与活动的总人数（点击进入就算参与）</span>
</p>
<p id="u947d8e04" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）完成活动人数：完成活动的总人数（创建活动时可定义完成活动条件）</span>
</p>
<p id="uc5a09ffa" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）支付人数：参与活动并且最终支付的人数</span>
</p>
<p id="u701f3b13" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）参与-完成转化率：完成活动人数/参与人数x100%</span>
</p>
<p id="u4f616c2f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）完成-支付转化率：支付人数/完成活动人数x100%</span>
</p>
<p id="ue19d6f1a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（6）参与-支付转化率：支付人数/参与人数x100%</span>
</p>
<h3 id="OXBuI" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">4、一个月内参与活动的用户消费额分布</span>
</h3>
<p id="u69ec2744" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">展示过去30天的参与活动用户消费额分布，通过此分析得知活动带来的价值带分布，更准确分析活动与商品价格之间的关系。</span>
</p>
<p id="u8543c181" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/e3007a199a91d815ff01126176070dbb.jpg" width="644.4444615163925" id="u877e7ff4" class="ne-image"/>
</p>
<p id="uf411fce1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="u34556581" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）坐标轴：x轴为消费额区间，固定为：10-30(不含30)、30-50(不含50)、50-100(不含100)、100-200(不含200)、200-500(不含500)，y轴为百分比占比</span>
</p>
<p id="u3c3dd43d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）浮窗：鼠标移入后，展示消费额区间、总体xx%</span>
</p>
<p id="u2b130d92" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）消费额占比计算规则：一个月内的消费额类型（如：10～30）/总消费额x100%</span>
</p>
<h3 id="O1PTr" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">5、活动新增用户排行榜</span>
</h3>
<p id="udfbc45cb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">展示近30天内通过活动新增用户的排行榜，通过此排行榜可快速得知哪一个活动对于新用户吸引力更大，因此为后续上新活动提供有效建议。</span>
</p>
<p id="u106dec08" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/b7840340e25ef0c0b1c6555aa14ead79.jpg" width="661.1111286245751" id="ua38486f8" class="ne-image"/>
</p>
<p id="ud32e4be1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="u21e04f82" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）排名：按照新客户人数排名，越高的越靠前</span>
</p>
<p id="u0dfd3d11" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）活动名称：展示创建营销活动时的名称，营销活动包含人群营销、场景营销</span>
</p>
<p id="u629e4bc0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）参与人数：展示参与活动的人数（用户点击进入业务系统就算）</span>
</p>
<p id="u45cb3c4b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）新客户人数：进入该渠道的新用户人数（首次打开并进入系统注册）</span>
</p>
<p id="u1352ad11" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）老客户人数：进入该渠道的老用户人数（首次打开或者之前已打开，并且之前已在系统内注册过）</span>
</p>
<h3 id="LFYAZ" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">6、活动用户后续留存</span>
</h3>
<p id="u71c45db1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可按日期查看对应参与活动的人数后续的留存情况，用于分析用户对于平台的喜好。</span>
</p>
<p id="ua4bc1f02" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/42e72ec1707b38f775524a4080072cbb.jpg" width="632.222238970392" id="uf32a1622" class="ne-image"/>
</p>
<p id="ub62907f2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="u8271d67d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）指定日期总人数：参与活动的用户人数</span>
</p>
<p id="ud492acc7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）指定日期的第1-7天留存率：</span>
</p>
<p id="u80a75e4a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·如：有100个用户参与活动后，第1天有50个用户访问了，就代表第1天的留存率为50%</span>
</p>
<p id="ub3b457f0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·参与活动的人数：系统默认为最近7天参与活动的人数</span>
</p>
<p id="udef52883" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）指定日期连续1-7天未登录客户数</span>
</p>
<p id="ubd97cd14" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·如：有100个用户参与活动后，第1天有50个用户未登录，则代表第1天的未登录客户数为50人</span>
</p>
<p id="u3e50ba53" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·参与活动的人数：系统默认为最近7天参与活动的人数</span>
</p>
<h3 id="E3zrR" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">7、活动敏感度</span>
</h3>
<p id="u074386dd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可通过此模块知悉平台内哪一个活动带来的价值最高，可用于分析不同文案、发放内容、平台产品对于用户的吸引力。</span>
</p>
<p id="ud08419af" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/3279637001327c9a37915dd99d33ba1f.jpg" width="587.5" id="u1eeddd0c" class="ne-image"/>
</p>
<p id="uf871277a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="ubd9b249d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）活动新增人数：本次活动新增的用户（活动时间内的注册新用户）</span>
</p>
<p id="u661e9288" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）参与人数：参与活动的总人数（参加活动的人数，点击视为参加</span>
</p>
<p id="udce313b7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）活动价值：活动带来的收入金额总计</span>
</p>
<p id="ud19f0bab" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）参与率：触达人数/参与人数</span>
</p>
<p>
<br/>
</p>`;
