export default `<h1 id="sozm5" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="u914b6925" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">展示优惠券使用、领取情况进行多维度分析，可快速知悉平台优惠券价值，以及优惠券带来的经营收入等重要数据。</span>
</p>
<h1 id="xoGni" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、各模块数据看板介绍</span>
</h1>
<h3 id="eJ6fb" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1、优惠券分析</span>
</h3>
<p id="u614ef613" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">系统将优惠券大盘数据汇总在此模块，管理员可快速知悉平台优惠券情况，针对性优化优惠券投放策略；</span>
</p>
<p id="u5f676154" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/0a5cae13157ea67dfb0d74c0ec5e986c.jpg" width="1297.7778121571491" id="udafd0f31" class="ne-image"/>
</p>
<p id="u7a1b3dc7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="u2ec4debd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）昨日券的发放量：指的是创建营销活动昨日优惠券的发放总量+会员等级发放+领券大厅发放总量</span>
</p>
<p id="u56407513" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）昨日券的领取量：营销活动+会员等级+领券大厅总量</span>
</p>
<p id="u9b5f39af" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）昨日券的使用量：用户实际下单使用的订单数量（不包含已取消的）</span>
</p>
<p id="u9f0df8ac" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）昨日券的领取人数：昨日通过营销活动、会员等级、领券大厅获取到券的人数</span>
</p>
<p id="u893690a7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）昨日券的使用人数：昨日使用优惠券下单的人数（不包含已取消的）、</span>
</p>
<p id="u6601486a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（6）昨日用券的新用户数：昨日使用优惠券下单且为当日注册的新用户人数（不包含已取消订单的）</span>
</p>
<p id="u233dbd01" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（7）优惠券的领取率=周期内发放量/领取量x100%、</span>
</p>
<p id="u0e2d46ec" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（8）优惠券的使用率=领取量/使用量x100%</span>
</p>
<p id="u22873ff6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：只有选择“昨日”的情况下，展示环比/同比和最新日期，若选择其他日期的情况下，不展示环比/同比和最新日</span>
</p>
<h3 id="O3yCH" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2、用券的订单总额</span>
</h3>
<p id="u45ca14e5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看过去7天优惠券的实际使用下单情况，以及优惠券所带来的订单金额合计</span>
</p>
<p id="uff227c66" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/c4e5c7f014f9fff10174a43d2da73482.jpg" width="644.4444615163925" id="ueee2bf5b" class="ne-image"/>
</p>
<p id="u4e6a0790" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）统计时间范围：固定为过去7天至昨日</span>
</p>
<p id="uf8dd328a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）同比/环比：展示该日期下的订单总额的同比/环比增长情况（同比是同比7天前、环比是环比昨天）</span>
</p>
<p id="uc203b63c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）合计/均值：合计是指统计日期的数值合计（即近7天，订单金额总和），均值为近7日每日的使用优惠券的订单总额/7天</span>
</p>
<p id="u49808864" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）坐标轴：x轴按日计算，近7日展示7个坐标，y轴为适配的数值（根据近7天最高和最低的数值自适应展示数值）</span>
</p>
<p id="u5112e2c9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）支付订单的订单金额总和：统计该日期下，使用优惠券下单的订单金额合计</span>
</p>
<h3 id="pnW8Q" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">3、优惠券对客单价的影响</span>
</h3>
<p id="ua142020c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">显示近7天，有用优惠券订单的客户单价与无用优惠券订单的客单价对比，通过对比可得知优惠券是否对客单价增长成果。</span>
</p>
<p id="ufb93e8d8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/30f3d0d1236511a5ebff71e94f6c2c28.jpg" width="637.7777946731195" id="u702850f6" class="ne-image"/>
</p>
<p id="ub5a297d0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）统计时间范围：固定为过去7天至昨日</span>
</p>
<p id="u11b95ce1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）坐标轴：x轴按日计算，近7日展示7个坐标，y轴为适配的数值（根据近7天最高和最低的数值自适应展示数值）</span>
</p>
<p id="u625eae9f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）优惠券后客单价：使用优惠券的订单消费金额/使用优惠券的消费订单数量x100%</span>
</p>
<p id="uc7781291" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）普通客单价：不使用优惠券的订单消费金额/不使用优惠券的消费订单数量x100%</span>
</p>
<h3 id="ppUHf" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">4、优惠券补贴率</span>
</h3>
<p id="u244696f5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">显示近7天系统的整体优惠券补贴情况，用于分析优惠券的实际使用情况补贴率=补贴金额/订单优惠前金额x100%</span>
</p>
<p id="u39480562" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/792b24be10b008fb0f95e1bb227dbf69.jpg" width="1292.2222564544215" id="ud8907280" class="ne-image"/>
</p>
<p id="u7e4bbd9e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<p id="u611195fa" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）统计时间范围：固定为过去7天至昨日</span>
</p>
<p id="u13b7f5ec" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）坐标轴：x轴按日计算，近7日展示7个坐标，y轴为适配的补贴率数值（根据近7天最高和最低的数值自适应展示数值）</span>
</p>
<p id="ua5ddf65a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）优惠券补贴率：优惠券补贴金额/订单优惠前金额x100%</span>
</p>
<p id="uf938a413" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：优惠券补贴金额根据优惠券实际抵扣金额计算</span>
</p>
<h3 id="gRYMF" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">5、不同优惠券的使用情况</span>
</h3>
<p id="u1ad6ca22" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">显示近7天业务系统使用优惠券次数最多的前15个优惠券，分析优惠券对于客户的吸引力</span>
</p>
<p id="u503df215" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/0daab2742916db04b20bfb7e67b7da27.jpg" width="645.555572656938" id="u89cbbb67" class="ne-image"/>
</p>
<p id="ue0c4bca0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）统计时间范围：固定为过去7天至昨日</span>
</p>
<p id="u3a903c57" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）坐标轴：x轴按日计算，近7日展示7个坐标，y轴为数值（根据近7天最高和最低的数值自适应展示数值）</span>
</p>
<p id="ud4392ce1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）优惠券使用次数：根据下单时使用优惠券统计（用户后续取消订单也包含入内）</span>
</p>
<h3 id="kK3CF" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">6、近7日各类型优惠券的使用情况</span>
</h3>
<p id="u1a5af5e0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">展示近7日三个不同类型的优惠券使用情况，用于分析不同类型优惠券对于用户的吸引力。</span>
</p>
<p id="u2f405553" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/8b79da066c777dbd7c892f98859be6ef.jpg" width="634.444461251483" id="u55925443" class="ne-image"/>
</p>
<p id="u7dcd8379" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）代金券：近7天代金券的使用次数/百分比</span>
</p>
<p id="ube2b2006" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）折扣券：近7天折扣券的使用次数/百分比</span>
</p>
<p id="u4d430069" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）满减券：近7天满减券的使用次数/百分比</span>
</p>
<p id="u7a4b41b3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）浮窗：日期、优惠券类型、使用次数+百分比</span>
</p>
<h3 id="I7Nnz" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">7、优惠券分析</span>
</h3>
<p id="u08f13f8e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">展示正常成交客户与用券成交客户饼状图+不同面额优惠券的使用率</span>
</p>
<p id="u83d00197" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/4525cabc348a440ba0e046006b3e18bd.jpg" width="640.0000169542105" id="u41cdeec4" class="ne-image"/>
</p>
<p id="udd74956c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）发券数：展示周期内，所有优惠券通过等级发放、营销活动、领券大厅领取优惠券的数量</span>
</p>
<p id="u95ecc4c9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）用券数：展示周期内，使用优惠券下单的使用次数（不是人数、不是订单数，只是计算优惠券的使用次数）</span>
</p>
<p id="u324eac81" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）用券成交用户：使用优惠券成交客户的数量（不计算使用优惠券后又取消订单的用户） </span>
</p>
<p id="u8376216c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）正常成交用户：未使用优惠券成交的客户数量</span>
</p>
<p id="u115d84a1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）优惠券面额：根据优惠券实际可抵扣的面额进行统计，统计近7天不同面额的使用率（使用率计算规则：指定面额区间的优惠券使用数量/指定面额区间的优惠券发放数量）</span>
</p>
<h3 id="h9960" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">8、优惠券敏感度</span>
</h3>
<p id="u07f0bbdb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">展示统计日期内整体复购率最高的前五个优惠券，用于分析优惠券带来的复购收益情况</span>
</p>
<p id="ua4103eb1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/7650950f2a502dafbe13baf46e24ce99.jpg" width="652.2222395002111" id="u8e5b8aa5" class="ne-image"/>
</p>
<p id="u05165470" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）复购率计算规则：使用优惠券的人群近7天内复购的人数/使用优惠券的人群x100%</span>
</p>
<p id="u6d60f44f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）时间范围：可按7天/15天/30天/自定义时间范围筛选优惠券敏感度数据，默认为7天</span>
</p>
<p id="u83e46045" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）副标题展示规则：按统计时间内，复购率最高的优惠券，展示具体名称，如：[中秋欢庆]优惠券对于复购的影响力更大，多给新用户发送吧～</span>
</p>
<p id="uaec4b2d5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）坐标轴：x轴为具体日期，y轴为百分比</span>
</p>
<p id="u2e617985" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）浮窗：鼠标移入后，展示前五个优惠券的复购率百分比</span>
</p>
<p>
<br/>
</p>`;
