export default `<h1 id="gbmsr" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="ua3906555" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 14px;">提供商家粉丝数的动态变化，帮助商家从数量、转化率多角度科学评估粉丝资产，进一步指导运营决策</span>
</p>
<h1 id="HyWfr" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、粉丝分析</span>
</h1>
<p id="uc66635ee" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgb(51, 51, 51); font-size: 14px">支持按上方公众号筛选查看企业旗下公众号粉丝资产及其变化趋势</span>
</p>
<p id="ue933b01e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/26c7a897d1287f5eba0b1de1e3a01294.jpg" width="921.1111355122231" id="u3070c506" class="ne-image"/>
</p>
<p id="u9222b3cc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·累计粉丝：累计粉丝指的是微信公众号的关注人数</span>
</p>
<p id="ub55ce4f4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新增粉丝：指的是筛选时间内关注公众号的人数</span>
</p>
<p id="u44005ab3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·流失粉丝：流失指的是取消微信公众号的关注人数</span>
</p>
<p id="u138c5287" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·净增粉丝：净增粉丝数=新增粉丝数-取消粉丝数</span>
</p>
<h1 id="zj91z" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、粉丝趋势图</span>
</h1>
<p id="u25c65c53" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">按折线图图表查看日期范围的粉丝新增、流失、净增、累计情况，更直观的查看旗下公众号的资产情况</span>
</p>
<p id="uab7e3241" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/3640ca1c1b9597d87fd1aa8646dbfb57.jpg" width="916.1111353797684" id="u36f30a62" class="ne-image"/>
</p>
<p id="ue5decb68" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<p>
<br/>
</p>`;
