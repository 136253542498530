export default `<h1 id="sQuAW" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="ua50b0df7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">积木云策按用户属性、行为、营销等三个不同维度进行用户透视分层。基于用户生命周期与画像特征自定义用户群体并进行分层经营。</span>
</p>
<p id="ucc20adc9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<p id="u3ba0f16b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：业务透视为定制业务项，企业可根据自身需要定制不同的业务透视分层。</span>
</p>
<p id="uc1f9ad7e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/87e3ee34630fc15a85d694dcaea2dc76.jpg" width="1058.8889169398656" id="u77cb5f6d" class="ne-image"/>
</p>
<h1 id="qQWOG" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、基础透视</span>
</h1>
<p id="u7746c622" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.管理员进入「会员事件-基础透视」后，点击任意透视进入详情页</span>
</p>
<p id="uab29648e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/1e944622ef48271a18cf6623b4352f6c.jpg" width="1060.555583650684" id="u3e286704" class="ne-image"/>
</p>
<p id="u5274b6ce" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2.进入「详情页-人数分布」，来查看该透视模型的人数分布情况，来判断该分层下不同的人数</span>
</p>
<p id="uce97dac7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/a0f6b080d59b547cbddb1464e8ed4cef.jpg" width="1054.4444723776837" id="u40a839b7" class="ne-image"/>
</p>
<p id="u94885875" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）总人数：支持查看符合该模型规则的总人数</span>
</p>
<p id="ubc4f6795" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）分层：可按不同分层来查看不同区间/类型的具体人数</span>
</p>
<p id="u04442ee8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3.进入「详情页-历史详情」，可查看该分层类型再指定日期内的数据。如：地域分层，可查看指定日期内属于不同省份的人数</span>
</p>
<p id="ua2457272" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/5b40649412651bf7209b86cf714e1461.jpg" width="925.0000245041324" id="uf3105a17" class="ne-image"/>
</p>
<p id="u2025f633" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">4.可查看不同日期范围下各类型的人数，支持查看用户以及添加至用户分组进行后续运营动作。如地域分层，可查看不同省份下在指定日期涌入的人数，以此来判断应用下的用户所在城市消费力。</span>
</p>
<p id="u4e888b25" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/cc8b2224c0d4dae1a5dbb07e21015b71.jpg" width="900.0000238418586" id="u9b03fab2" class="ne-image"/>
</p>
<p id="ufb2b52ee" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·查看用户列表：点击跳转到会员列表，并展示所选用户</span>
</p>
<p id="u09988a76" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·去运营：点击可直接创建人群营销，直接触达该人群</span>
</p>
<p id="uc55c95a6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·添加顾客分组：点击可将该日期下对应类型的人数添加为顾客分组，添加后可进行进一步顾客画像洞察以及营销触达</span>
</p>
<h1 id="nmKxT" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、透视事件规则配置</span>
</h1>
<p id="u4094166a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.管理员进入「系统管理-规则配置-透视事件」，支持将用户透视内的规则进行二次调整（只可修改数值，不可修改规则）</span>
</p>
<p id="ud6f0b60d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/deeda701ddaf2d34c7d38774c0ca92d5.jpg" width="1059.4444725101384" id="ub9ef7e38" class="ne-image"/>
</p>
<p id="u6000f27e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2.点击底部「编辑」按钮，进入编辑状态，修改分层的数值后，再次点击「保存」按钮即可保存修改，保存完成后会员事件规则同步修改</span>
</p>
<p id="u0b9ddc37" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/07f1c80ff45e01c03cf6d0fc8153aa99.jpg" width="1043.8889165425014" id="u6de0c8f7" class="ne-image"/>
</p>
<p id="uaa422880" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3.支持将所有分层恢复为默认效果，恢复后将按原有规则继续统计展示。</span>
</p>
<p id="uba18af38" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/2a798cf00a085ce1827947e5624ffcbf.jpg" width="1047.222249964138" id="u210d8ed0" class="ne-image"/>
</p>
<p>
<br/>
</p>`;
