export default `<h1 id="jS30A" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="ua6c5026f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">管理员首次使用积木云策产品时，需在该模块配置好所有相关的渠道，通过配置渠道来实现渠道创建、营销触达等功能</span>
</p>
<h1 id="dK65R" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、短信配置</span>
</h1>
<p id="uf7894ff4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">积木云策对接阿里云短信功能，管理员需要配置短信密钥等信息来实现营销活动的短信触达功能</span>
</p>
<p id="u139e41fa" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/82f4bb2d289d6b275e398491e3fa8156.jpg" width="1587.7778198395258" id="UtT7H" class="ne-image"/>
</p>
<h3 id="D4bi5" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（一）创建短信</span>
</h3>
<p id="u48e231c3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「系统管理-企业配置-短信配置」，可查看短信渠道配置基本信息，可点击「创建账号」进入新增短信渠道页面</span>
</p>
<p id="uad5b49ac" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：创建短信渠道需注册</span><a href="https://www.aliyun.com/" data-href="https://www.aliyun.com/" target="_blank" class="ne-link"><span class="ne-text">阿里云账号</span></a>
</p>
<p id="ud0e7fba9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/de54b976b379c40dba4ab4c30c7de6a9.jpg" width="1586.6667086989803" id="ubc04e595" class="ne-image"/>
</p>
<p id="u32b67802" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、点击创建账号弹出弹窗后，需填写「账号名称」、「Accesskey」、「Accesskeysecret」等信息来创建</span>
</p>
<p id="u063925cf" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/09afa86f2a8e671d69919ccb22818cd3.jpg" width="1587.7778198395258" id="u927c6045" class="ne-image"/>
</p>
<p id="u06cd0ac8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·账号名称：填写1-10字账号名称信息，该账号用于内部查看使用；</span>
</p>
<p id="u497ca3a2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·Accesskey：可填写1-32位密钥信息，key需要通过阿里云平台获取；</span>
</p>
<p id="u053d1f5a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·Accesskeysecret：可填写1-32位密钥信息，密钥需要通过阿里云平台获取；</span>
</p>
<p id="u51d15c32" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·如何获取密钥：</span><a href="https://help.aliyun.com/document_detail/53045.html" data-href="https://help.aliyun.com/document_detail/53045.html" class="ne-link"><span class="ne-text">点击查看获取密钥规则</span></a>
</p>
<h3 id="EgiGj" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（二）启用/停用短信与测试发送功能</span>
</h3>
<p id="u465ff751" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、创建账号成功后，返回短信列表，默认为停用效果，点击启用的情况下，需走测试发送流程，必须要调用第三方接口成功后，才可正常启用</span>
</p>
<p id="uc6dc7805" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/ebbf22e4d1c2d7c1c3f4d1f2fd86f788.jpg" width="1584.4444864178893" id="u22fb4eb8" class="ne-image"/>
</p>
<p id="u1076d34d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、首次点击「启用」弹出测试发送弹窗后，填写「手机号码」、「短信签名」、「短信模板」后，点击「确定」进入确认发送页</span>
</p>
<p id="u3ff161f3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/64e22be89729b6309d37c8c7a0e1e530.jpg" width="1594.4444866827987" id="u14b4f19b" class="ne-image"/>
</p>
<p id="u19aa3495" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·手机号码：输入11位纯数字手机号</span>
</p>
<p id="ud36d507d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·短信签名：下拉展示短信签名名称，只可单选</span>
</p>
<p id="u250354d2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·短信模板：下拉展示短信模板名称，只可单选</span>
</p>
<p id="u8135bdc3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：选择短信模板后，还需填写变量信息</span>
</p>
<p id="ue2cfa0fc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、进入确认发送页，确认内容后，点击「发送」按钮即可调用阿里云接口确认短信功能是否可正常使用；</span>
</p>
<p id="u0b09174b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/a28d22e0703b40ff866beb6936c22bdb.jpg" width="1590.0000421206169" id="uf940a69d" class="ne-image"/>
</p>
<h3 id="lJNUT" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（三）短信模板</span>
</h3>
<p id="u59570cfe" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">1、管理员点击「系统管理-企业配置-短信配置-短信模板」进入短信模板列表；</span>
</p>
<p id="f132b358cf9744b73b2c0ecac93918bc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/a86cc0fa1d7af3c04d25651d956ee137.jpg" width="1578.8889307151617" id="u4d427f78" class="ne-image"/>
</p>
<p id="u8e4b8536" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入模板管理页面，点击「新建模板」按钮进入新建页面</span>
</p>
<p id="847292d855f67ed165d0c28f6bacc597" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/22dc8d4c3240b9747103f6a371d67282.jpg" width="1591.1111532611624" id="u8b65a3d6" class="ne-image"/>
</p>
<p id="uf3450555" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、进入新建模板页面后，填写「模板名称」、「模板内容」、「申请说明」、「模板类型」后创建短信模板</span>
</p>
<p id="956cb929baccd3cc90361ec25351785d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：教育、医疗、酒类、引导加微信短信容易被运营商屏蔽，</span><a href="https://help.aliyun.com/document_detail/108253.html" data-href="https://help.aliyun.com/document_detail/108253.html" target="_blank" class="ne-link"><span class="ne-text">了解更多屏蔽规则</span></a>
</p>
<p id="7ba953c7da5ba1b8963a256ad9de7457" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/b4e16b79c2fce109078644a44e278021.jpg" width="1586.6667086989803" id="u2ee8a616" class="ne-image"/>
</p>
<p id="44af04010320a2e5746fa7c054dc4885" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·模板名称：可输入2-8字模板名称，用于内部查看管理</span>
</p>
<p id="09a49376de305b4c2eead41364dfdf2e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·模板内容：输入1-500字短信模板（含变量）</span>
</p>
<p id="88a3c29152212b85655192a719c58207" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）为避免与签名混淆，在模板内容任意位置均不能使用【】，在模板内容首尾不能使用[]。</span>
</p>
<p id="fbb42ae5df27ac6b1b84498ee869417c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）不支持特殊符号例如# 『』「」〖〗 m² • ①★※→等。</span>
</p>
<p id="562ac11a1c0eceb318db45a22804a5d0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）插入变量：可插入变量，用于短信发送时使用变量信息</span>
</p>
<p id="3bd53f77aff81a4357069dd97b5f4c04" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·申请说明：填写使用该短信模板的主要原因</span>
</p>
<p id="408db1029e22c5e1bd39fcab884e0a7d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·模板类型：按实际情况选择「通知类」、「推广类」</span>
</p>
<p id="19736cfb8c5091776f62b18837ab65d7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<p id="ua3bc8572" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">4、创建完成后，可在列表通过模板「审核状态」来筛选模板内容</span>
</p>
<p id="c9198291d68972950e68822c81dbe477" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/6aaa056e5131e9507c9cfd4b3ff3d6fd.jpg" width="1587.7778198395258" id="ub35da977" class="ne-image"/>
</p>
<p id="uf462cd9a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">5、可点击「详情」或「删除」按钮来查看模板信息或者删除模板</span>
</p>
<p id="uf2b761b7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/62226af28747610caee89826d132bdc7.jpg" width="1587.7778198395258" id="ue142243f" class="ne-image"/>
</p>
<h3 id="BA9e3" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（四）短信签名</span>
</h3>
<p id="ue08a5451" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 15px">1、管理员点击「系统管理-企业配置-短信配置-短信签名」来为企业创建签名</span>
</p>
<p id="72ed69da0d66c1247dd3bd058bf89adf" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/27dbc3b846cfed3d75d63efc57b8b3be.jpg" width="1588.8889309800713" id="ua74941a1" class="ne-image"/>
</p>
<p id="u77fba130" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入签名管理页面，可点击「新建签名」进入新建签名页面</span>
</p>
<p id="18a5522add5cb90bdb024df852c44b3f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/4bc768e26d614c2444efa970734d1f31.jpg" width="1590.0000421206169" id="u4c8b48f2" class="ne-image"/>
</p>
<p id="ua3f79d3f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、进入短信签名新建页面，填写「签名名称」、「签名用途」、「营业执照」、「营业执照有效期」、「备注信息」后即可提交短信签名。</span>
</p>
<p id="2ecd220f6462304c91e01fcec61a2c7e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">例：签名设置【积木云客】，客户收到的短信将是：【积木云客】xxxxx</span>
</p>
<p id="2a4228dd0cda24de9d6ec655ef807da3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：短信签名规范请参考</span><a href="https://help.aliyun.com/document_detail/108076.html" data-href="https://help.aliyun.com/document_detail/108076.html" target="_blank" class="ne-link"><span class="ne-text">阿里云短信签名审核规范</span></a>
</p>
<p id="4e7ff48e884911d17d2c2219b42819b8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/7681e393b21e0514c2bf79841894ed72.jpg" width="1588.8889309800713" id="u7fafb277" class="ne-image"/>
</p>
<p id="24a24fecec118b4a77588058f99d0a67" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·签名名称：可输入2-8字汉字/数字/字母</span>
</p>
<p id="04eedb327642d000804199a7f7ce6e5f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·签名用户：可根据实际情况选择「自有产品」或「非自有产品」</span>
</p>
<p id="a99aef55eefd32aa343cef8e3033bd8a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·营业执照：上传正面清晰的PNG/JPG的营业执照图片，大小不超过5m</span>
</p>
<p id="7928911482bdf7a5b0007e8f11014ab0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·营业执照有效期：按营业执照实际情况填写「时间段」或「长期有效」</span>
</p>
<p id="c9c479c6f684b22b44f665e835c6c082" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·其他证明文件：若选择了「非自有产品」的情况下，请按实际情况填写阿里云提供的</span><a href="https://jmyk.yuque.com/docs/share/a63c0ecd-945a-4ea2-89f4-b83f1d6ac0b0?" data-href="https://jmyk.yuque.com/docs/share/a63c0ecd-945a-4ea2-89f4-b83f1d6ac0b0?" target="_blank" class="ne-link"><span class="ne-text">【授权委托书】</span></a><span class="ne-text">，来证明拥有使用此签名的权力。</span>
</p>
<p id="ab59578f6f0503f3f6e36f1b424667cc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<p id="u71fb01fa" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">4、创建完成后，请等待阿里云短信平台审核，审核通过后即可使用</span>
</p>
<p id="051d193e079cbe83cf020486dce0693e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/009c2520b12d7829df569df281297c99.jpg" width="1592.2222644017077" id="ubfddafce" class="ne-image"/>
</p>
<p id="u0a535b2d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">5、可在列表通过模板「审核状态」来筛选模板内容</span>
</p>
<p id="u9f020d8e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/34b1d70c1381fa5876abbd27f47d7f6b.jpg" width="1586.6667086989803" id="u8e2d49d7" class="ne-image"/>
</p>
<p id="u6ad0153c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">6、可点击「详情」或「删除」按钮来查看签名信息或者删除签名</span>
</p>
<p id="u31dbfe56" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/93f3f65ba717c9d0955ec89b4adf192e.jpg" width="1594.4444866827987" id="ub9779a9a" class="ne-image"/>
</p>
<p id="uac700635" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<h1 id="zoFqf" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、推送配置</span>
</h1>
<p id="ua0675b36" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">积木云策对接极光推送、友盟推送功能，管理员可根据实际拥有渠道来创建对应渠道账号来实现营销活动的APPpush触达功能</span>
</p>
<p id="uffe2f784" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/4dd2f976363f95c5cb2737723be5e975.jpg" width="1588.8889309800713" id="u5f8135e3" class="ne-image"/>
</p>
<h3 id="X7PFF" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（一）创建推送渠道</span>
</h3>
<p id="ucd4b8d46" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员点击「系统管理-企业配置-推送配置-创建账号」，弹出创建账号弹窗。输入「账号名称」、「appkey」、「app_secret」内容后即可创建账号成功</span>
</p>
<p id="u1357f0bc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：登录</span><a href="https://www.jiguang.cn/" data-href="https://www.jiguang.cn/" target="_blank" class="ne-link"><span class="ne-text">极光</span></a><span class="ne-text">、</span><a href="https://www.umeng.com/push" data-href="https://www.umeng.com/push" target="_blank" class="ne-link"><span class="ne-text">友盟</span></a><span class="ne-text">平台，获得 AppKey 和 Master Secret 两个参数，如没有极光/友盟账号，需要先创建账号及应用。</span>
</p>
<p id="uab6c7311" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/a19f34b725f94c37f2088a31396a079f.jpg" width="1587.7778198395258" id="uf17b8515" class="ne-image"/>
</p>
<p id="u1c7dddd1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·账号名称：可填写1-10字账号名称，用于内部展示用</span>
</p>
<p id="u0581dd71" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·appkey：填写1-32位密钥信息，需要在指定平台上获取</span>
</p>
<p id="u26ba06f4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·appsecret：填写1-32位密钥信息，需要在指定平台上获取</span>
</p>
<h3 id="YxOmZ" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（二）启用/停用推送渠道与编辑删除功能</span>
</h3>
<p id="u5da681e0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、创建账号成功后，可点击开启/关闭来控制push渠道使用。</span>
</p>
<p id="u746bdd5f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：创建时系统不校验信息真实性，创建后系统调用推送功能时校验。</span>
</p>
<p id="u47b41ec5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/0051e65ad06e1a5f6c7ccb39648f9e30.jpg" width="1586.6667086989803" id="u5321ce3c" class="ne-image"/>
</p>
<p id="u2a649129" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、点击「编辑」、「删除」来二次编辑账号信息或删除账号</span>
</p>
<p id="u1a626ce3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/0051e65ad06e1a5f6c7ccb39648f9e30.jpg" width="1586.6667086989803" id="bgQcB" class="ne-image"/>
</p>
<h1 id="bX3DS" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">四、公众号配置</span>
</h1>
<p id="u87225666" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">积木云策对接微信公众号，在此模块配置公众号密钥等信息后，即可使用营销功能的公众号触达功能，以及进行粉丝数据分析等功能</span>
</p>
<p id="u61d725ac" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/e52019f470d306ccb29ce876c75f3b16.jpg" width="1593.3333755422532" id="uf8974ab9" class="ne-image"/>
</p>
<h3 id="rICbc" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（一）创建公众号渠道</span>
</h3>
<p id="u2bfc4dd8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员点击「系统管理-企业配置-公众号配置-创建账号」，弹出创建账号弹窗。输入「账号名称」、「app_id」、「app_secret」内容后即可创建账号成功</span>
</p>
<p id="u1edca0e8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：登录</span><a href="https://mp.weixin.qq.com/" data-href="https://mp.weixin.qq.com/" target="_blank" class="ne-link"><span class="ne-text">微信公众平台</span></a><span class="ne-text">，登录公众号即可获得 AppKey 和 Master Secret 两个参数，如没有微信公众平台账号，需要先注册账号。</span>
</p>
<p id="uaac4d89d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/51413e0e619c92a6e6e743f6c62ea6ee.jpg" width="1590.0000421206169" id="u87fbd973" class="ne-image"/>
</p>
<p id="ubbb41608" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·账号名称：可填写1-10字账号名称，用于内部展示用</span>
</p>
<p id="ud002c7da" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·app_id：填写1-32位密钥信息，需要在指定平台上获取</span>
</p>
<p id="ucb3c905c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·app_secret：填写1-32位密钥信息，需要在指定平台上获取</span>
</p>
<h3 id="OXvWt" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（二）启用/停用推送渠道与编辑删除功能</span>
</h3>
<p id="u4529a6f2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、创建账号成功后，可点击开启/关闭来控制公众号渠道使用。</span>
</p>
<p id="u4ee98d1a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：创建时系统不校验信息真实性，创建后系统调用推送功能时校验。</span>
</p>
<p id="u7b1a793b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/e006c3d54e8f3fe022d29595fd1c9f9b.jpg" width="1597.7778201044352" id="u22fe59ff" class="ne-image"/>
</p>
<p id="u8bab4283" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、点击「编辑」、「删除」来二次编辑账号信息或删除账号</span>
</p>
<p id="u4f47fc0b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/0c31f4f2470bd2a23a7bbeb484b1f8c6.jpg" width="1595.5555978233442" id="ud8d4e270" class="ne-image"/>
</p>
<h1 id="MVlxB" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">五、小程序配置</span>
</h1>
<p id="udc3c45c2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">积木云策对接微信小程序，在此模块配置小程序id、密钥等信息后，即可使用小程序相关数据分析与小程序链接渠道功能；</span>
</p>
<p id="u8ef3d695" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/626cef24e5990e0ed0e59b5978a9067e.jpg" width="1597.7778201044352" id="u694a4f4a" class="ne-image"/>
</p>
<h3 id="SNEk6" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（一）创建小程序渠道</span>
</h3>
<p id="uad8a8674" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员点击「系统管理-企业配置-小程序配置-创建账号」，弹出创建账号弹窗。输入「账号名称」、「app_id」、「app_secret」内容后即可创建账号成功</span>
</p>
<p id="u07e2d29c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：登录</span><a href="https://mp.weixin.qq.com/" data-href="https://mp.weixin.qq.com/" target="_blank" class="ne-link"><span class="ne-text">微信公众平台</span></a><span class="ne-text">，登录小程序平台即可获得 AppKey 和 Master Secret 两个参数，如没有微信公众平台账号，需要先注册账号。</span>
</p>
<p id="u6445aa46" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/8aaddf3be174e211e18b4f882f38113e.jpg" width="1592.2222644017077" id="u02af63e8" class="ne-image"/>
</p>
<p id="ufcb9e20e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·账号名称：可填写1-10字账号名称，用于内部展示用</span>
</p>
<p id="ucd9f680d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·app_id：填写1-32位密钥信息，需要在指定平台上获取</span>
</p>
<p id="u3a7a2707" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·app_secret：填写1-32位密钥信息，需要在指定平台上获取</span>
</p>
<h3 id="ScAJd" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（二）启用/停用推送渠道与编辑删除功能</span>
</h3>
<p id="ud9237c1a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、创建账号成功后，可点击开启/关闭来控制公众号渠道使用。</span>
</p>
<p id="uae5e3378" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：创建时系统不校验信息真实性，创建后系统调用推送功能时校验。</span>
</p>
<p id="u6ee06552" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/e1e1fe0c27f25511d0ad42cabb469973.jpg" width="1595.5555978233442" id="u6f652d4e" class="ne-image"/>
</p>
<p id="ufc134eb1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、点击「编辑」、「删除」来二次编辑账号信息或删除账号</span>
</p>
<p id="u37c2c063" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/79346275dbb6b9ac0312d96e7f005974.jpg" width="1595.5555978233442" id="u32aa417b" class="ne-image"/>
</p>
<h1 id="JNZSs" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">六、APP配置</span>
</h1>
<p id="u3fd8d512" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">积木云策对接客户自有业务系统APP后，在此模块配置APP的专属id、密钥等信息后，即可使用APP渠道链接功能；</span>
</p>
<p id="u5fce73a2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：id、密钥等内容需要联系客户经理获取。</span>
</p>
<p id="uac11eed7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/f5bcfc3c470c8571146f45c060459e11.jpg" width="1590.0000421206169" id="u5a6cba5e" class="ne-image"/>
</p>
<h3 id="PnoGm" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（一）创建APP渠道</span>
</h3>
<p id="u31ab2549" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员点击「系统管理-企业配置-APP配置-创建账号」，弹出创建账号弹窗。输入「账号名称」、「app_id」、「app_secret」内容后即可创建账号成功</span>
</p>
<p id="ub1fe1f83" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：id、密钥等内容需要联系客户经理获取。</span>
</p>
<p id="u5376cdc4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/103ed48ebfb0e299a5ca9264214bbbc2.jpg" width="1595.5555978233442" id="u5ab151a3" class="ne-image"/>
</p>
<p id="u6461cc17" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·账号名称：可填写1-10字账号名称，用于内部展示用</span>
</p>
<p id="uf093a41c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·app_id：填写1-32位密钥信息，需要联系客户经理获取</span>
</p>
<p id="uc1985234" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·app_secret：填写1-32位密钥信息，需要联系客户经理获取</span>
</p>
<h3 id="caaCG" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（二）启用/停用推送渠道与编辑删除功能</span>
</h3>
<p id="u374aaef9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、创建账号成功后，可点击开启/关闭来控制APP渠道使用。</span>
</p>
<p id="ude922f13" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：创建时系统不校验信息真实性，创建后系统调用推送功能时校验。</span>
</p>
<p id="ucfb196f9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/e049ed94d1f448babe313d2b7b1f2cf2.jpg" width="1592.2222644017077" id="ua520d0d3" class="ne-image"/>
</p>
<p id="uda01c6e2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、点击「编辑」、「删除」来二次编辑账号信息或删除账号</span>
</p>
<p id="ue7f69bfc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/8fc09e42fb7f09ed277a83fc5c410fb0.jpg" width="1592.2222644017077" id="u83c1abc5" class="ne-image"/>
</p>
<p>
<br/>
</p>`;
