import detail from "@/views/detail";

const router = [
  {
    path: "/analysisEngine",
    component: detail,
    name: "analysisEngine",
    meta: {
      menuTitle: "分析引擎", // 一级菜单
      menuIcon: "icon-analysisEngine",
    },
    children: [
      {
        path: "/analysisEngine",
        component: detail,
        name: "analysisEngine",
        redirect: "/analysisEngine/event",
        meta: {
          menuTitle: "高级分析", // 二级菜单
        },
        children: [
          {
            path: "event",
            component: detail,
            name: "event",
            meta: {
              menuTitle: "事件分析", // 三级菜单
              html: require("@/assets/data/analysisEngine/event").default,
            },
          },
          {
            path: "funnel",
            component: detail,
            name: "funnel",
            meta: {
              menuTitle: "漏斗分析", // 三级菜单
              html: require("@/assets/data/analysisEngine/funnel").default,
            },
          },
          {
            path: "distribution",
            component: detail,
            name: "distribution",
            meta: {
              menuTitle: "分布分析", // 三级菜单
              html: require("@/assets/data/analysisEngine/distribution").default,
            },
          },
          {
            path: "retain",
            component: detail,
            name: "retain",
            meta: {
              menuTitle: "留存分析", // 三级菜单
              html: require("@/assets/data/analysisEngine/retain").default,
            },
          },
        ],
      },
      {
        path: "/analysisEngine",
        component: detail,
        name: "analysisEngine",
        redirect: "/analysisEngine/event",
        meta: {
          menuTitle: "归因分析", // 二级菜单
        },
        children: [
          {
            path: "attribution",
            component: detail,
            name: "attribution",
            meta: {
              menuTitle: "归因分析", // 三级菜单
              html: require("@/assets/data/analysisEngine/attribution").default,
            },
          },
        ],
      },
      {
        path: "/analysisEngine",
        component: detail,
        name: "analysisEngine",
        redirect: "/analysisEngine/event",
        meta: {
          menuTitle: "分析指标", // 二级菜单
        },
        children: [
          {
            path: "custom",
            component: detail,
            name: "custom",
            meta: {
              menuTitle: "自定义指标", // 三级菜单
              html: require("@/assets/data/analysisEngine/custom").default,
            },
          },
        ],
      },
      {
        path: "/analysisEngine",
        component: detail,
        name: "analysisEngine",
        redirect: "/analysisEngine/event",
        meta: {
          menuTitle: "数据仪表盘", // 二级菜单
        },
        children: [
          {
            path: "BI",
            component: detail,
            name: "BI",
            meta: {
              menuTitle: "BI驾驶舱", // 三级菜单
              html: require("@/assets/data/analysisEngine/BI").default,
            },
          },
        ],
      },
    ],
  },
];
export default router;
