export default `<h3 id="XHRIh" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1.简介</span>
</h3>
<p id="u9ac12aa8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">分布分析主要目的为告诉平台某个事件指标的用户分布情况。比如，查看订单金额在100元以下、100元至200元、200以上三个区间的用户分布情况。</span>
</p>
<h3 id="es7uV" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.使用说明</span>
</h3>
<h3 id="iiQGA" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.1构建事件指标</span>
</h3>
<p id="u7ba7f140" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.在左侧下拉框选择指标，如“下单购买”（与事件分析一致）</span>
</p>
<p id="u1e66e0e8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/6db1ef0520572796e1de1f4c20830897.jpg" width="953.7499857880177" id="u59cc62f7" class="ne-image"/>
</p>
<p id="ucc369869" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2.在右侧下拉框选择对应的指标，</span><span class="ne-text" style="color: rgba(0, 0, 0, 0.85)">如“总次数”，或者“下单金额”（</span><span class="ne-text">与事件分析一致</span><span class="ne-text" style="color: rgba(0, 0, 0, 0.85)">）</span>
</p>
<p id="uf5ff55f4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/33b13af86a4925ceb374ceaa4c5d4b5a.jpg" width="1128.7499831803145" id="EQmgB" class="ne-image"/>
</p>
<p id="uf18d3a71" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3.</span><span class="ne-text" style="color: rgba(0, 0, 0, 0.85)">选择分布图内区间的划分方式，可以按照默认区间查看人数分布，也可以自定义区间</span>
</p>
<p id="u633d9995" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<p id="ud35352dc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">*负无穷和正无穷代表以下、以上。</span>
</p>
<p id="ub5153c03" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">例如：</span>
</p>
<p id="u3818d2ef" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">区间1:负无穷-1</span>
</p>
<p id="uaa557d22" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">区间2:1-2</span>
</p>
<p id="u3340f87c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">区间3:2-正无穷，</span>
</p>
<p id="ueae60339" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<p id="u3be31f3a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">那么图表则展示以下分布：1次以下、1-2次、2次以上</span>
</p>
<p id="ueaac42ab" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/669d37eed51af0b033053b65db82d537.jpg" width="573.7499914504589" id="ue2c5e0af" class="ne-image"/>
</p>
<h3 id="RedLl" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.3事件满足</span>
</h3>
<p id="ufdb89ce0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">1.添加所选事件的筛选条件，支持且、或条件。</span>
</p>
<p id="ufdb5f77e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d468663f697271632ca99b86b6e97e45.jpg" width="966.2499856017532" id="u7f3672c3" class="ne-image"/>
</p>
<h3 id="Q3r6S" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.4用户分群</span>
</h3>
<p id="u8f04631c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可通过积木云策创建的群组，选择目标人群来分析他们的行为特征，不选择默认为全部用户</span>
</p>
<p id="u5dc65d55" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/357e3238046dad568966a92338e056e0.jpg" width="663.7499901093544" id="FCoQG" class="ne-image"/>
</p>
<h3 id="Ap7g9" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.5创建群组</span>
</h3>
<p id="ueb19a366" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.点击图表中的单元格，可进行该分布的圈选创建人群</span>
</p>
<p id="u72eab9ca" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/3b02550a39ba3b15eef7a42123f82ce7.jpg" width="1088.749983776361" id="ud4b84335" class="ne-image"/>
</p>
<p id="u58b66ceb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2.创建人群时进入新增人群页，自动带入信息，并且不支持更新方式、创建方式的规则设置。</span>
</p>
<p id="u301d2cf4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/1f56e2cd72af6baaf0e744b9aaa5ee5e.jpg" width="1466.2499781511726" id="E8WKp" class="ne-image"/>
</p>
<p>
<br/>
</p>`;
