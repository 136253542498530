export default `<h3 id="ZMzgB" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1.简介</span>
</h3>
<p id="u89ba4152" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">应用内部分事件（常见如：广告位）作为站内流量引导关键点，在整个转化流程中起着重要作用，“归因分析”报告可帮助系统了解事件对于最终转化所做的贡献，从而指导优化设计和运营。</span>
</p>
<h3 id="lsSu9" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.使用说明</span>
</h3>
<h3 id="zdbtB" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.1选择目标转化事件</span>
</h3>
<p id="u8cc386bd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">选择产品的目标转化事件，一般为与收益相关的事件，如：加入购物车、支付，若无转化事件，可联系售前客户经理对接定制增加。</span>
</p>
<p id="u74a6a19f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/8e22ac1aa6f21afcf9efb8c5a71883c8.jpg" width="1326.2499802373352" id="u01d2d1c0" class="ne-image"/>
</p>
<h3 id="nuwtg" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.2选择待归因事件</span>
</h3>
<p id="u73683657" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">选择待归因事件，一般与运营相关事件相关，如：点击运营位、参与活动</span>
</p>
<p id="u9c0a5841" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/69d1cd9567d1ae365beff960dfea882d.jpg" width="433.74999353662145" id="u54ccb16f" class="ne-image"/>
</p>
<h3 id="ytSYl" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.3选择用户分群</span>
</h3>
<p id="uc45b2cbb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可通过积木云策创建的群组，选择目标人群来分析他们的行为特征，不选择默认为全部用户</span>
</p>
<p id="u5c15c1ad" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/3fdcd36d81facc8c19e93f36b8bc9fb7.jpg" width="324.99999515712267" id="u0ccf7c47" class="ne-image"/>
</p>
<h3 id="NmNht" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.4选择回溯时间窗口</span>
</h3>
<p id="u9931428b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">回溯时间窗口，是指在分析某个转化行为时，待归因事件（坑位事件）可以是转化行为多久之前发生过的，即根据转化目标事件的发生时间，往前倒推选择待归因坑位事件可发生的窗口期，您可选择当天、1天、2天、3天、4天、5天、6天、7天、14天、30天，默认1天。 举个例子，例如转化为“下单”，待归因事件为“点击Banner”，选择回溯时间窗口为1天，那么在下单行为2天前的点击Banner事件将不会参与归因，而下单行为前1天以及当天的点击Banner事件会参与归因计算。</span>
</p>
<p id="ue8538921" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/43b801676a51d22886b8b6de893aef78.jpg" width="269.99999597668653" id="uf16d317e" class="ne-image"/>
</p>
<h3 id="ZQ4wm" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.5选择归因模型</span>
</h3>
<table id="u9ROM" class="ne-table" width="750">
<tbody>
    <tr style="height: 33px" class="firstRow">
        <td width="250" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u637d3600" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">模型</span></strong>
            </p>
        </td>
        <td width="250" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="u02c73714" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">说明</span></strong>
            </p>
        </td>
        <td width="250" style="border-color: rgb(217, 217, 217); background-color: rgb(251, 251, 251);">
            <p id="ua1719728" class="ne-p" style="min-height: 24px;">
                <strong><span class="ne-text" style="font-size: 14px;">适用场合</span></strong>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="250" style="border-color: rgb(217, 217, 217);">
            <p id="u54ed5e26" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">首次点击归因模型</span>
            </p>
        </td>
        <td width="250" style="border-color: rgb(217, 217, 217);">
            <p id="u2cd39005" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">首次点击模型将 100% 的转化价值归功于用户点击的第一个渠道。</span>
            </p>
        </td>
        <td width="250" style="border-color: rgb(217, 217, 217);">
            <p id="udcd36cf2" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">如果您的产品并不为人所知，您的推广目标是拉新，在归因分析时，您需要更多关注首次将您的产品展现给消费者的渠道上。</span>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="250" style="border-color: rgb(217, 217, 217);">
            <p id="u070d3599" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">最终点击归因模型</span>
            </p>
        </td>
        <td width="250" style="border-color: rgb(217, 217, 217);">
            <p id="udf182a29" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">最终互动模型将 100% 的转化价值归功于用户在进行购买或转化之前点击的最后一个渠道。</span>
            </p>
        </td>
        <td width="250" style="border-color: rgb(217, 217, 217);">
            <p id="u5746e82b" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">业界常用的归因模型。如果您只想关注带来最终转化的渠道，您可以使用这个模型。</span>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="250" style="border-color: rgb(217, 217, 217);">
            <p id="u6c0dff83" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">线性归因模型</span>
            </p>
        </td>
        <td width="250" style="border-color: rgb(217, 217, 217);">
            <p id="u0d87d79f" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">线性模型将功劳平均分配给转化路径中的每个渠道点击。</span>
            </p>
        </td>
        <td width="250" style="border-color: rgb(217, 217, 217);">
            <p id="ua4a12922" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">如果您觉得每个渠道触点都给最终转化带来了贡献，您可以使用这个模型。</span>
            </p>
        </td>
    </tr>
    <tr style="height: 33px">
        <td width="250" style="border-color: rgb(217, 217, 217);">
            <p id="udbfa3808" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">时间衰减归因模型</span>
            </p>
        </td>
        <td width="250" style="border-color: rgb(217, 217, 217);">
            <p id="ua9862f0d" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgb(71, 86, 105); font-size: 14px">多个「待归因事件」对同一个「目标转化事件」作出贡献时，认为越靠近「目标转化事件」做出的贡献越大</span>
            </p>
            <p id="u5d73f952" class="ne-p" style="min-height: 24px;">
                <span class="ne-text" style="color: rgb(71, 86, 105); font-size: 14px">备注：可以理解为</span><span class="ne-text" style="color: rgb(51, 51, 51);">对于路径上的渠道，距离转化的时间越短的渠道，可以获得越多的功劳权重。</span>
            </p>
        </td>
        <td width="250" style="border-color: rgb(217, 217, 217);">
            <p id="uffe7bcc9" class="ne-p" style="min-height: 24px;">
                <span class="ne-text">客户决策周期短、销售周期短的情况。比如，做短期的促销，就打了两天的广告，那么这两天的广告理应获得较高的权重</span>
            </p>
        </td>
    </tr>
</tbody>
</table>
<h3 id="vVqzw" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.6指标说明</span>
</h3>
<p id="u652b00c6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.触发次数：该对应事件触发的次数，单个用户触发多次都计入内。</span>
</p>
<p id="ub4a19b6f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">2.触发用户数：对应事件触发的用户数，与转化事件无关。</span>
</p>
<p id="u4e966f32" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">3.目标转化事件用户数：按照选择的归因模型，对应坑位的目标转化事件的触发用户数。举个例子，如果选择了末次点击归因模型，用户在触发转化事件之前，最晚触发了事件A，则事件A对应的目标转化用户数+1。</span>
</p>
<p id="ubf26e5da" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">4.用户转化率：目标转化事件用户数/触发用户数。</span>
</p>
<p id="ua1b84bb0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">5.目标转化事件次数：按照您选择的归因模型，对应坑位的目标转化事件的触发次数。举个例子，如果选择了末次点击归因模型，用户在触发转化事件之前，最晚触发了坑位事件A，则坑位事件A对应的目标转化事件数+1。</span>
</p>
<p id="ucb380336" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">6.指标贡献度：此行坑位对应的选择指标值/指标总值，比如选择了目标转化事件次数，为对应坑位的目标转化事件次数/目标转化事件总次数。 根据指标贡献度的高低，可能可以发现新的增长点，进而对坑位进行相应调整和优化。</span>
</p>
<p id="u163363b2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px"></span>
</p>
<h3 id="ecQDk" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.7切换指标说明</span>
</h3>
<p id="uf0d046e0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">点击「目标转化事件的总次数」的指标切换，可选：总次数/商品数量的总和/商品单价的总和/商品总价的总和/商品实付金额的总和</span>
</p>
<p id="ucec7d1c0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/01b493a5c8355083e2261adb8aee74bd.jpg" width="543.7499918974937" id="u3bb3050a" class="ne-image"/>
</p>
<p>
<br/>
</p>`;
