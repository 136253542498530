import detail from "@/views/detail";

const router = [
  {
    path: "/memberCenter",
    component: detail,
    name: "memberCenter",
    meta: {
      menuTitle: "会员中台", // 一级菜单
      menuIcon: "icon-memberCenter",
    },
    children: [
      {
        path: "/memberCenter",
        component: detail,
        name: "memberCenter",
        redirect: "/memberCenter/memberlist",
        meta: {
          menuTitle: "会员管理", // 二级菜单
        },
        children: [
          {
            path: "memberlist",
            component: detail,
            name: "memberlist",
            meta: {
              menuTitle: "会员列表", // 三级菜单
              html: require("@/assets/data/memberCenter/memberlist").default,
            },
          },
          {
            path: "memberGroup",
            component: detail,
            name: "memberGroup",
            meta: {
              menuTitle: "会员群组", // 三级菜单
              html: require("@/assets/data/memberCenter/memberGroup").default,
            },
          },
          {
            path: "memberExport",
            component: detail,
            name: "memberExport",
            meta: {
              menuTitle: "会员导入", // 三级菜单
              html: require("@/assets/data/memberCenter/memberExport").default,
            },
          },
        ],
      },
      {
        path: "/memberCenter",
        component: detail,
        name: "memberCenter",
        redirect: "/memberCenter/memberlist",
        meta: {
          menuTitle: "会员画像", // 二级菜单
        },
        children: [
          {
            path: "tagManage",
            component: detail,
            name: "tagManage",
            meta: {
              menuTitle: "标签管理", // 三级菜单
              html: require("@/assets/data/memberCenter/tagManage").default,
            },
          },
        ],
      },
      {
        path: "/memberCenter",
        component: detail,
        name: "memberCenter",
        redirect: "/memberCenter/memberlist",
        meta: {
          menuTitle: "渠道管理", // 二级菜单
        },
        children: [
          {
            path: "channelList",
            component: detail,
            name: "channelList",
            meta: {
              menuTitle: "渠道列表", // 三级菜单
              html: require("@/assets/data/memberCenter/channelList").default,
            },
          },
        ],
      },
    ],
  },
];
export default router;
