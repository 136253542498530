export default `<h1 id="EhvxT" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="udd9152b7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">营销看板可以帮助企业的运营和营销人员快速查看营销计划的执行情况，并实时监测活动效果，从而提升关键指标和运营效率。营销看板包含计划分类管理、计划列表的数据展现。</span>
</p>
<h1 id="wkKnJ" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、计划列表的查看与管理</span>
</h1>
<p id="u1d3166cd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、</span><span class="ne-text" style="font-size: 15px">管理员进入「营销中台-营销看板」，可查看在积木云策已创建完成的营销计划，可按分类查看营销计划</span>
</p>
<p id="u51878466" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d7080efd1fd4672ac80eb313fd6e7338.jpg" width="1584.4444864178893" id="u8e01f437" class="ne-image"/>
</p>
<p id="u47fa6610" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）待开始：</span>
</p>
<p id="u84c57e35" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·计划名称：展示计划名称信息，</span>
</p>
<p id="u94c0df30" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·类型：右上角展示营销事件类型（氛围：人群/场景）</span>
</p>
<p id="ued5c3bd4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·创建时间：展示营销事件的创建时间</span>
</p>
<p id="ubf847a9c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·状态：展示待开始状态</span>
</p>
<p id="uc1365e0e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·触达人数：触达人数默认展示“-”（因为还未开始，所以没有触达人数）</span>
</p>
<p id="ud7d51821" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/6a883f2ae108490ffea8a2a9ea2978ee.jpg" width="220.00000582800988" id="u45e305df" class="ne-image"/>
</p>
<p id="ua172d088" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）执行中-单次/重复：</span>
</p>
<p id="ude5f32ef" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·计划名称：展示计划名称信息，</span>
</p>
<p id="u412f6a7e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·类型：右上角展示营销事件类型（氛围：人群/场景）</span>
</p>
<p id="u7f08e8f0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·创建时间：展示营销事件的创建时间</span>
</p>
<p id="u0a500f40" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·状态：展示执行中状态</span>
</p>
<p id="u77b15e0f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·最近触发时间：展示该营销事件最近一次的触达用户的时间</span>
</p>
<p id="u2dc38912" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·目标完成率：展示当前该营销事件的完成率，以及和环比昨天的增长下降情况（目标完成率为：完成营销事件人数/触达人数）</span>
</p>
<p id="ub82e3507" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·缩略折线图：展示最近5天的目标完成率折线缩略图</span>
</p>
<p id="uf558cafa" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/52e0ed41a163b9220757765e665b0ec6.jpg" width="207.77778328200932" id="u60fb2848" class="ne-image"/>
</p>
<p id="u9ba84175" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）已结束：</span>
</p>
<p id="u52e71d97" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·计划名称：展示计划名称信息，</span>
</p>
<p id="u0ac1ed82" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·类型：右上角展示营销事件类型（氛围：人群/场景）</span>
</p>
<p id="u804b8665" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·创建时间：展示营销事件的创建时间</span>
</p>
<p id="ub49df3e4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·状态：展示“完成”状态</span>
</p>
<p id="uad96068d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·目标完成率：展示当前该营销事件的整体完成率，以进度条的形式呈现（完成事件的人数/推送人数）</span>
</p>
<p id="ua7c0cccc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/277dd43a76d1d35da52879840660cf3a.jpg" width="211.11111670364582" id="uffbfe869" class="ne-image"/>
</p>
<p id="ub1e5b168" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、</span><span class="ne-text" style="font-size: 15px">管理员进入「营销中台-营销看板」，可点击顶部「人群营销」、「场景营销」分类来切换营销看板展示内容；</span>
</p>
<p id="uf191f01c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/296691b24ea37e572546c3a465caa36c.jpg" width="1585.5555975584348" id="uf3585014" class="ne-image"/>
</p>
<p id="u2fb4b832" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、可输入「营销计划名称」关键词、业务系统、触达渠道类型来筛选营销计划</span>
</p>
<p id="u22022bd3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/c66f947492553077e9a115a102497798.jpg" width="1590.0000421206169" id="u16976821" class="ne-image"/>
</p>
<p id="u1dfcbe80" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">4、可点击营销计划进入营销计划数据分析来查看数据分析</span>
</p>
<p id="udf87f092" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/36d2501e95817f5c8d7545aaf09b138f.jpg" width="1582.2222641367982" id="u45f9c719" class="ne-image"/>
</p>
<p id="ue869197c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">5、进入数据分析页，可查看营销计划的数据汇总、转化效果、活动完成情况、活动用户后续留存等多维度数据来查看此活动的有效性；</span>
</p>
<p id="u63c4276d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/7c7ace4de5a8a89a061d4e7145b71754.jpg" width="1582.2222641367982" id="u05aa487a" class="ne-image"/>
</p>
<p id="uf3dd5c68" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/268b87709d104c1be0ac9d5bd33f4bb4.jpg" width="1592.2222644017077" id="u50a269d8" class="ne-image"/>
</p>
<p id="u04435709" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）数据汇总：</span>
</p>
<p id="u7a843333" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·通知成员人数：按实际收到推送的用户总数统计（预计与实际存在误差， 因为不同的触达渠道可能会存在无法通知到用户的情况）；</span>
</p>
<p id="u40f0c0e9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访客数：</span><span class="ne-text">通过本次营销计划访问系统的用户总数，跨天去重；</span>
</p>
<p id="u3af333f3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·下单人数：</span><span class="ne-text">通过本次营销计划进入系统后的创建订单的用户总数，既计划执行期间的下单人数；</span>
</p>
<p id="u79ef9c05" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·支付人数：通过营销计划进入业务系统后</span><span class="ne-text">成功调用支付的用户人数；</span>
</p>
<p id="ua66c3148" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·支付订单数：</span><span class="ne-text">通过营销计划进入业务系统后成功支付的订单总数；</span>
</p>
<p id="ud10b6a25" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·支付金额：通过营销计划进入业务系统后支付订单总金额总数；</span>
</p>
<p id="u099a88c1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）转化效果：</span>
</p>
<p id="u9d30ce12" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·推送成功人数：按实际收到推送的用户总数统计（预计与实际存在误差， 因为不同的触达渠道可能会存在无法通知到用户的情况）；</span>
</p>
<p id="u741efb81" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访客人数：</span><span class="ne-text">通过本次营销计划访问系统的用户总数，跨天去重；</span>
</p>
<p id="u69097940" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·支付人数：通过营销计划进入业务系统后</span><span class="ne-text">成功调用支付的用户人数；</span>
</p>
<p id="uee19abb6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·通知-支付转化率：</span><span class="ne-text">支付人数/通知人数x100%；</span>
</p>
<p id="u6072ece5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问-支付转化率：</span><span class="ne-text">支付人数/访客人数x100%；</span>
</p>
<p id="u95ea25fe" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·通知-访问转化率：</span><span class="ne-text" style="font-size: 15px;">访客人数/通知人数x100%；</span>
</p>
<p id="ue69b10b1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）活动完成情况：</span>
</p>
<p id="u117a4e92" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·目标完成率：</span><span class="ne-text" style="font-size: 15px;">指定日期下，完成活动人数/参与人数x100%</span>
</p>
<p id="u351497bb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·发送成功人数：</span><span class="ne-text" style="font-size: 15px;">指定日期下，计划收到推送的用户总数</span>
</p>
<p id="u91653c7a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·目标完成人数：</span><span class="ne-text" style="font-size: 15px;">指定日期下，完成活动人数</span>
</p>
<p id="ud2c72e53" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·发送失败人数：</span><span class="ne-text" style="font-size: 15px;">指定日期下，实际未发送成功的人数（选择的触达渠道无法通知到某用户的情况）</span>
</p>
<p id="ub12b4631" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·用户拒收人数：</span><span class="ne-text" style="font-size: 15px;">指定日期下，用户拒收的人数（短信、app情况下，用户可能存在拒收的情况）</span>
</p>
<p id="u24ef042b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）活动用户后续留存：</span>
</p>
<p id="u006d0cfb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·指定日期总人数：参与活动的用户人数</span>
</p>
<p id="uff914e5d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·指定日期的第1-7天留存率：例如有100个用户参与活动后，第1天有50个用户访问了，就代表第1天的留存率为50%</span>
</p>
<p id="u5fb34ba4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<p id="ua69ce178" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<p>
<br/>
</p>`;
