export default `<h1 id="QpJDN" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="u27b0f12c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">支持管理员根据自身企业使用需求，配置自身品牌标示，更加友好展示平台IP效果</span>
</p>
<h1 id="gu2lD" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、品牌配置</span>
</h1>
<p id="uc232eb2e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">支持配置修改前台各个模块以及BI驾驶舱的品牌名称与LOGO</span>
</p>
<p id="u9ce04307" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/c5815d30e84c442b4daeaa55724f18f7.jpg" width="1592.2222644017077" id="ue378436d" class="ne-image"/>
</p>
<p id="ud0774d31" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、销售管理：</span>
</p>
<p id="u106ce433" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）需求描述：可替换中台常规页面左上角的logo和名称信息</span>
</p>
<p id="u995db6f5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）品牌名称：展示默认名称，管理员可操作替换</span>
</p>
<p id="u60e0999f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）品牌标示：展示自动填充的图片，管理员可操作替换，图片限制上传png/jpg格式且小于2m的图片</span>
</p>
<p id="ua1be2145" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、驾驶舱表示：</span>
</p>
<p id="u42fae9fd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）需求描述：可替换bi驾驶舱页面左上角logo和名称信息</span>
</p>
<p id="u1736102e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）品牌名称：展示默认名称，管理员可操作替换</span>
</p>
<p id="ua8a39473" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）品牌标示：展示自动填充的图片，管理员可操作替换，图片限制上传png/jpg格式且小于2m的图片</span>
</p>
<p id="u9a2a55f3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、保存：点击保存后生效，若名称和图片有为空的情况则不允许修改。</span>
</p>
<h1 id="KTS7k" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、积木云助手</span>
</h1>
<p id="u73ee7107" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">提供积木云助手IP形象更换，企业可根据品牌自身调性更换积木云助手AI助手表情IP</span>
</p>
<p id="uc3e89019" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/802bf52ff09b93fa888c95b38cfb0356.jpg" width="1586.6667086989803" id="u7d9007b5" class="ne-image"/>
</p>
<p id="uda0e505f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、品牌IP：</span>
</p>
<p id="u1b7f57eb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）需求描述：该ip主要用于积木云助手的“正常”情况；</span>
</p>
<p id="u5e4d21fd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）更换ip：点击后上传本地文件，限制只可上传png/jpg；</span>
</p>
<p id="u90cd7ee4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、增长IP动作：</span>
</p>
<p id="u80fca589" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）需求描述：该ip主要用于积木云助手的“增长”情况；</span>
</p>
<p id="u6dc8209d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）更换ip：点击后上传本地文件，限制只能上传png/jpg；</span>
</p>
<p id="u01c63557" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、下架IP动作</span>
</p>
<p id="u3419273c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）需求描述：该ip主要用于积木云助手的“下滑”情况；</span>
</p>
<p id="ueb122640" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）更换ip：点击后上传本地文件，限制只能上传png/jpg；</span>
</p>
<p id="u0c3ef06b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">4、保存：点击保存后生效，若ip有为空的情况则不允许修改；</span>
</p>
<p>
<br/>
</p>`;
