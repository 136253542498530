export default `<h1 id="fSB9L" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="udb41b2ff" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">深度洞察用户特征、消费力、营销偏好等360度全场景画像，精准分析会员性别、地域、消费、访问、活跃、渠道、标签、会员等级等多维度画像分析，助力品牌更懂消费者。</span>
</p>
<p id="u00238e6d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<p id="u93240a22" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">数据来源：通过对接业务系统后，获取业务系统下的用户数据进行分析并展示</span>
</p>
<h1 id="WDIh2" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、各模块数据看板介绍</span>
</h1>
<h2 id="WvooI" style="font-size: 24px; line-height: 32px; margin: 21px 0 5px 0">
<span class="ne-text">1、人群总数说明：</span>
</h2>
<p id="u574b6a55" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">展示该业务系统下截止至昨日用户的总量，以及环比和同比增长/下降</span>
</p>
<p id="u0aa9a96f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/8b11312511e9a1940b29272eea5782cf.jpg" width="452.77778977229303" id="u7b477391" class="ne-image"/>
</p>
<p id="u8c6895cb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·人数：展示昨日，当前平台的总人数，</span>
</p>
<p id="ue07e058d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·同比/环比：展示该日期下的平台总人数的同比/环比增长情况</span>
</p>
<p id="u164c8c5e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·计算时间：T+1计算，当日0点计算昨日的数据</span>
</p>
<h2 id="sb87U" style="font-size: 24px; line-height: 32px; margin: 21px 0 5px 0">
<span class="ne-text">2、性别组织架构：</span>
</h2>
<p id="u8a1ef7af" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">展示该业务系统下截止至昨日用户的性别分布情况，通过此图表可快速分析平台对于哪一种性别的用户更有吸引力。</span>
</p>
<p id="u492b0d42" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/23c93a7bcdbe1fb0709efab45ac6ef0b.jpg" width="455.00001205338407" id="ucd11371e" class="ne-image"/>
</p>
<p id="ucd7629c5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·总人数：展示业务系统下的总人数</span>
</p>
<p id="u6a2b49d3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·男性：展示业务系统下性别为男性的人数</span>
</p>
<p id="u905dbcb2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·女性：展示业务系统下性别为女性的人数</span>
</p>
<p id="uf93bf569" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·未知：展示未在系统内设置性别的人数</span>
</p>
<p id="u05f82672" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·计算时间：T+1计算，当日0点计算昨日的数据</span>
</p>
<h2 id="FME3P" style="font-size: 24px; line-height: 32px; margin: 21px 0 5px 0">
<span class="ne-text">3、城市分布：</span>
</h2>
<p id="uad9501c0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看平台整体人群的所在城市分布，辅助广告投放策略。</span>
</p>
<p id="u40c29555" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/c7bf7bcbe14c5dd63e265a5124c4bdd0.jpg" width="450.555567491202" id="uddd54b59" class="ne-image"/>
</p>
<p id="u266f14c9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·客户群省份下人数：所属不同省份下的客户群组人数；</span>
</p>
<p id="ud0c37b6b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·省份下人数占比：某省份下的群组人数/群组全部人数x100%；</span>
</p>
<p id="u59605c1e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·总城市人数：当前业务系统下，所有有城市的客户人数；</span>
</p>
<p id="u292ce996" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·未知人数：当前业务系统下，未设置地域信息的用户人数；</span>
</p>
<h2 id="q3AAC" style="font-size: 24px; line-height: 32px; margin: 21px 0 5px 0">
<span class="ne-text">4、人群消费次数：</span>
</h2>
<p id="u02f7541a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">展示过去90天人群的消费次数情况，通过此图表判断平台的人群消费情况</span>
</p>
<p id="u1a77c2ce" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/521ac7560a76bb5af8247afe215aca2d.jpg" width="451.6666786317475" id="u5b301046" class="ne-image"/>
</p>
<p id="u271c9984" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·消费0次人数：过去90天群组下消费次数为0的成员数量；</span>
</p>
<p id="ufc4a932e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·消费1次人数：过去90天群组下消费次数为1的成员数量；</span>
</p>
<p id="uf683037c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·消费2次人数：过去90天群组下消费次数为2的成员数量；</span>
</p>
<p id="u8afa5a63" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·消费3次人数：过去90天群组下消费次数为3的成员数量；</span>
</p>
<p id="u70fd9c36" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·消费4次以上人数：过去90天群组下消费次数为4次以上的成员数量；</span>
</p>
<h2 id="Q4vba" style="font-size: 24px; line-height: 32px; margin: 21px 0 5px 0">
<span class="ne-text">5、人群访问分布：</span>
</h2>
<p id="uc7f1e417" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看过去90天访问业务系统次数的情况，通过此图表可快速知悉用户的访问情况，来判断平台用户活跃情况。</span>
</p>
<p id="u61c684af" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/68d26783d563e34c9516aac4b4bfca9b.jpg" width="450.0000119209293" id="uc578946f" class="ne-image"/>
</p>
<p id="u2b7238fe" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问0次人数：过去90天访问系统次数为0的成员数量；</span>
</p>
<p id="u95009d76" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问2-5次人数：过去90天访问系统次数为2-5次的成员数量；</span>
</p>
<p id="ue737692f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问5-10次人数：过去90天访问系统次数为5-10次的成员数量；</span>
</p>
<p id="uee54f7dd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问10-20次人数：过去90天访问系统次数为10-20次的成员数量；</span>
</p>
<p id="u80cc4dec" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问20次以上人数：过去90天访问系统次数为20次以上的成员数量；</span>
</p>
<p id="u2d3a8d5e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：用户打开小程序为访问1次</span>
</p>
<h2 id="xBUFn" style="font-size: 24px; line-height: 32px; margin: 21px 0 5px 0">
<span class="ne-text">6、人群月活跃度：</span>
</h2>
<p id="ue6b862c5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看人群在过去一个月的活跃占比，来分析平台用户的活跃情况；</span>
</p>
<p id="ub9e3d168" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/277c145efc70086a34dd5197094f818b.jpg" width="448.333345210111" id="u32c4a49e" class="ne-image"/>
</p>
<p id="ua0d446c4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·过去一个月活跃人数：过去一个月，定义为活跃人群的群组成员（根据我们系统内置的活跃人群规则定义）</span>
</p>
<p id="ufbef120c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·过去一个月未活跃人数：过去一个月，定义为未活跃人群的群组成员（根据我们系统内置的活跃人群规则定义）</span>
</p>
<h2 id="sFcg3" style="font-size: 24px; line-height: 32px; margin: 21px 0 5px 0">
<span class="ne-text">7、会员等级占比：</span>
</h2>
<p id="u8d278282" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看平台整体人群的会员等级分布，来分析平台会员等级策略有效性；</span>
</p>
<p id="ua8d2c2a6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/3a22f128de566f0741db2bae2d666ab3.jpg" width="447.77778963983826" id="uea5be261" class="ne-image"/>
</p>
<p id="u9a6e8c2c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·不同等级下的会员人数：单个等级下的会员人数/群组下所有成员数量x100%</span>
</p>
<h2 id="a5CWb" style="font-size: 24px; line-height: 32px; margin: 21px 0 5px 0">
<span class="ne-text">8、优惠券敏感度：</span>
</h2>
<p id="u149530bf" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">分析平台整体人群在过去90天的优惠券消费排行，来洞悉优惠券的偏好情况；</span>
</p>
<p id="u30d6d6ed" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/5931e5aad0ef80ed872b15a64a618349.jpg" width="456.6666787642023" id="u4ecaefaf" class="ne-image"/>
</p>
<p id="u45b78e83" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·不同优惠券的领取次数：过去90天，单个优惠券的领取次数（交互图内图表默认展示前7个，设计图可能会增多）</span>
</p>
<p id="u0a0cd435" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·不同优惠券的使用次数：过去90天，单个优惠券的使用次数（交互图内图表默认展示前7个，设计图可能会增多）</span>
</p>
<h2 id="aApmk" style="font-size: 24px; line-height: 32px; margin: 21px 0 5px 0">
<span class="ne-text">9、顾客标签画像：</span>
</h2>
<p id="u2fb523e5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">展示应用次数最多的五个标签与平台标签创建情况，来判断该标签类型下的应用情况与用户分布、支付情况</span>
</p>
<p id="u3a3dd657" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/782183a4a180be744ccb6ca099a35d48.jpg" width="446.11112292902" id="u56f39054" class="ne-image"/>
</p>
<p id="u02edb9bf" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·标签应用人数：当前所属该标签的用户人数</span>
</p>
<p id="ue8430405" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·标签新用户人数：当前所属该标签的新用户人数</span>
</p>
<p id="u1e65d778" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·老用户应用数：所属该标签的老用户人数（用户总数-新用户=老用户）</span>
</p>
<p id="u51d00411" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·标签支付率：指的是该标签中有多少用户支付成功的百分比</span>
</p>
<p id="u16b157e4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·标签总数：平台内创建的标签数量合计</span>
</p>
<p id="uef5c8cb1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·运营标签：平台内创建的运营标签数量合计</span>
</p>
<p id="uaf44c168" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·规则标签：平台内创建的规则标签数量合计</span>
</p>
<h2 id="f0Kag" style="font-size: 24px; line-height: 32px; margin: 21px 0 5px 0">
<span class="ne-text">10、会员渠道占比：</span>
</h2>
<p id="u181ec911" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看默认渠道与手动添加的渠道不同的人数以及使用时长、使用次数、次数留存率，来判断用户的渠道价值情况，以及分析外部渠道价值并辅助广告投放。</span>
</p>
<p id="u107b43c3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/2d97caf6e68e85c4f76c3fedf6bb39c9.jpg" width="446.66667849929274" id="ua79a77b7" class="ne-image"/>
</p>
<p id="u1e96007a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·一周使用时长：该渠道的用户本周使用时长/该渠道用户数量</span>
</p>
<p id="ub6f5108a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·使用次数：指的是该渠道的用户访问应用的次数。登录视为1次</span>
</p>
<p id="u13b5248c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·一周留存率：该渠道新用户注册且使用退出后，一周内是否有二次登陆过。如公众号来了1000人，其中有500人一周内有二次登陆，则一周留存率为50%。</span>
</p>
<p>
<br/>
</p>`;
