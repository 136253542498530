export default `<h2 id="kIlPs" style="font-size: 24px; line-height: 32px; margin: 21px 0 5px 0">
<span class="ne-text">一、功能说明</span>
</h2>
<p id="ud841178a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">云策提供页面模板，可根据实际引流场景，生成短链接投放至不同外部渠道。用户打开链接后引流进入小程序/APP/网页，验证渠道有效性与引流率。</span>
</p>
<h1 id="Wffh7" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、创建渠道模板</span>
</h1>
<p id="u59340dd7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">当创建模板时需要通过页面点击跳转到业务系统时，则需要先创建渠道的模板页面，当创建渠道完成后，用户打开先跳转到渠道模板页，再通过模板页的点击按钮跳转进入业务系统。</span>
</p>
<p id="ue3c5efea" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">创建渠道模板操作流程：</span>
</p>
<p id="ucc7a9968" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、进入「会员中台-渠道列表」，点击渠道模板的「新建模板」来进入新增渠道模板页</span>
</p>
<p id="u5a5103b7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/116b0969103c7c5516d279e450886c8d.jpg" width="786.666687506217" id="ub31bdab1" class="ne-image"/>
</p>
<p id="uc2c687e4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入新建页，选择「模板类型」、「业务系统」，上传「banner」、「背景图」后，填写页面「模板名称」、「专题名称」、「按钮名称」、「活动说明」以及进入业务系统后发放的优惠券后，即可创建渠道模板；</span>
</p>
<p id="u906f6ba8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/1629b17079fa76a83eec959653a6dba1.jpg" width="1059.4444725101384" id="udb965236" class="ne-image"/>
</p>
<p id="uab201e16" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="u876340cc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·模板类型：可选择两种领取优惠券的模板或者无需优惠券的模板进行页面配置，“有优惠券”的模板类型支持二次编辑背景图，当选择无需优惠券类型的模板时无需上传背景图，而是需要上传富文本的活动说明内容</span>
</p>
<p id="u832bae8e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/fb4e5b530f70ef8608ebd06426bc1dcf.jpg" width="951.6666918772245" id="uacccb32f" class="ne-image"/>
</p>
<p id="u0073a51a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·业务系统：可选择该模板最终允许跳转到哪一个业务系统里面去，用于后续创建渠道时选择。</span>
</p>
<p id="ud429e94e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·banner：上传模板页面的banner图，支持png/jpg格式且小于2m的图片</span>
</p>
<p id="u83997912" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·模板名称：输入1-10字模板名称，用于页面模板上展示页面名称</span>
</p>
<p id="u2d23670f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·专题名称：输入1-10字专题名称，展示优惠券领取窗口的专题名称</span>
</p>
<p id="uae6f4365" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·按钮名称：可输入1-6字领取按钮文字效果</span>
</p>
<p id="uad5f5fa9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·活动说明：</span>
</p>
<p id="ub3c09c8d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）当选择“有优惠券”类型的模板，那么活动说明为多行文本框，可输入1-500字活动说明文字，用于阐述活动的规则内容；</span>
</p>
<p id="u2fbb4bf4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）当选择“无优惠券”类型的模板，那么活动说明为富文本框，可配置图文来丰富页面效果；</span>
</p>
<p id="u6d6beb7c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·添加优惠券：可选择业务系统内配置的优惠券，当渠道使用该模板，那么用户通过此模板跳转到业务系统后，则可领取此优惠券</span>
</p>
<p id="ubdcafd04" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/b4afee21223b2fad995d6c2195d7da1e.jpg" width="1059.4444725101384" id="u8fd20642" class="ne-image"/>
</p>
<h1 id="BHs3Z" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、创建渠道</span>
</h1>
<p id="uafb06d8c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、进入「会员中台-渠道列表」，点击渠道列表的「新建渠道」来进入新增渠道页</span>
</p>
<p id="uf1a857a0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/61fbe15716d8566deb27bb2cea400d03.jpg" width="1060.555583650684" id="u874872c1" class="ne-image"/>
</p>
<p id="ub19c4cab" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、进入新建页，填写渠道名称、渠道描述、业务系统、标签、推广类型、配置规则后即可创建渠道</span>
</p>
<p id="uab2c179f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/da4f99412d809519e30583fd187ef0bf.jpg" width="1058.8889169398656" id="u21eb468d" class="ne-image"/>
</p>
<p id="ufb1fb57c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·渠道名称：可输入1-20字渠道名称，用于内部管理使用；</span>
</p>
<p id="udc1f72c7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·渠道描述：可输入1-100字渠道描述，用于内部管理使用；</span>
</p>
<p id="uec64559e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·业务系统：选择该渠道关联的业务系统，选择后归属于该业务系统下的渠道；</span>
</p>
<p id="ubbc1b29e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·标签：可多选或者单选标签，选择后，用户通过此渠道进入业务系统后，自动打入标签；</span>
</p>
<p id="u28faa9ee" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·推广类型：可选择小程序/app/网页，选择不同类型则跳转不同类型效果</span>
</p>
<p id="u57e891a2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·配置规则：</span>
</p>
<p id="u02b81f5f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">小程序类型：</span>
</p>
<p id="u0e41e4a4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）链接跳转小程序：用户打开链接后直接唤醒小程序并跳转到小程序页面（页面支持自定义配置）</span>
</p>
<p id="u068ca74c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/aae32dc171950f84ea98a559b41cf509.jpg" width="551.1111257105701" id="ua88a7ad3" class="ne-image"/>
</p>
<p id="u77efbdb7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）小程序二维码：创建后生成小程序二维码，用户扫描二维码进入小程序（页面支持自定义配置）</span>
</p>
<p id="u619350d7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/5c566dba60847b8330bf0531234d0c82.jpg" width="548.3333478592064" id="u49408d0e" class="ne-image"/>
</p>
<p id="ub2023576" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）点击跳转小程序：选择关联模板，创建渠道后跳转到渠道模板，在模板页点击按钮跳转到小程序的指定页面（页面支持自定义配置）</span>
</p>
<p id="u2d1b0099" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/dcc890a3692e473c9afe6614d2d073aa.jpg" width="567.2222372484799" id="u76f6868b" class="ne-image"/>
</p>
<p id="u51253b2d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">APP类型：</span>
</p>
<p id="uc399a168" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）链接跳转APP：用户打开链接后直接唤醒APP并跳转到APP页面（页面支持自定义配置）</span>
</p>
<p id="udcdfa6db" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/eabed0f5a9310011abb6cefecdab15cc.jpg" width="548.3333478592064" id="u634eb7b2" class="ne-image"/>
</p>
<p id="ue00b5c6a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）点击跳转APP：选择关联模板，创建渠道后跳转到渠道模板，在模板页点击按钮跳转到APP的指定页面（页面支持自定义配置）</span>
</p>
<p id="u220ccc45" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/ecac7a46b86f29f5d575158e6a58d45b.jpg" width="545.5555700078427" id="uaf07a8ca" class="ne-image"/>
</p>
<p id="u3ae4e688" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">网页类型：</span>
</p>
<p id="ucc1961d2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）链接跳转网页：打开渠道链接后直接跳转到网页，根据填写链接自动生成带有渠道参数的链接，通过此链接投放可统计用户来源；</span>
</p>
<p id="u7c5ca296" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/0c58b6cc9e70999af3ecc0484bfab1f8.jpg" width="560.0000148349342" id="u3f604f18" class="ne-image"/>
</p>
<p id="u9abca335" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）点击跳转网页：选择关联模板，创建渠道后跳转到渠道模板，在模板页点击按钮跳转到网页；</span>
</p>
<p id="uee667383" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/5c2238c3bc4d350aaf9067fc80f4c845.jpg" width="557.2222369835705" id="ud75b7f06" class="ne-image"/>
</p>
<h1 id="HzZ7u" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">四、查看和管理渠道</span>
</h1>
<p id="u8f54d081" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、进入「会员中台-渠道管理」进入渠道列表，可通过「业务系统」筛选以及渠道名称「关键词」模糊搜索渠道；</span>
</p>
<p id="ue2025fd6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/5af35ac2a9abf9eafc670d4fcf4a670a.jpg" width="1056.111139088502" id="u5b17c442" class="ne-image"/>
</p>
<p id="u575e9a57" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、可在渠道列表查看渠道模板， 可点击「删除」、「配置模板」来二次编辑或者删除渠道模板</span>
</p>
<p id="u0aabcf3a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：请谨慎删除，删除模板会对已创建渠道造成影响</span>
</p>
<p id="u28a77cda" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/db5d6f05e35642c6be67a07224b3b614.jpg" width="1056.6666946587748" id="uef14127b" class="ne-image"/>
</p>
<p id="ub80ecab2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3、可点击「预览」来查看渠道模板的展示效果；</span>
</p>
<p id="ued270b80" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/2e1abae36f1a50ad84ba91ae00f650c7.jpg" width="1050.5555833857743" id="u8f8b1943" class="ne-image"/>
</p>
<p id="u03fd07f9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">4、可在渠道列表点击「详情」按钮，来查看渠道的详细信息与统计规则；</span>
</p>
<p id="u28ca2833" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/5c7222929cbeb6e31526ed825fde606e.jpg" width="1050.5555833857743" id="ud76ac442" class="ne-image"/>
</p>
<p id="u39425ce6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">渠道链接：</span>
</p>
<p id="uf1f9d580" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·渠道类型：展示所选的渠道类型（包含：小程序/APP/网页）</span>
</p>
<p id="ud6fc8a8e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·渠道二维码：展示渠道链接的二维码效果，小程序类型的情况下，扫码打开直接跳转到小程序或者进入到模板页</span>
</p>
<p id="ucae7fc41" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·渠道链接：展示渠道的链接，可复制渠道链接进行使用</span>
</p>
<p id="u7dee6585" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/af66761b7a1442b252b7873d1a35bdb8.jpg" width="193.88889402519052" id="u4f6c2b07" class="ne-image"/>
</p>
<p id="u225e045e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">渠道数据：</span>
</p>
<p id="u374eff6d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·渠道总人数：指的是该渠道的新增客户（指的是从该渠道进入系统注册的用户总人数）</span>
</p>
<p id="u7063609d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·昨日新增:指的是昨日通过该渠道进入系统并注册的用户</span>
</p>
<p id="ucb72750e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·平均浏览时长:指的是昨日通过点击该渠道链接进入系统用户的浏览时长/该渠道的浏览次数</span>
</p>
<p id="u08383d3b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问次数：指的是昨日通过点击该渠道链接进入系统的次数</span>
</p>
<p id="u6556ece9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·客单价：通过该渠道进入系统并最终下单的订单金额/下单用户人数</span>
</p>
<p id="ubea320d0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·渠道价值：通过该渠道进入系统并最终下单的订单金额</span>
</p>
<p id="u702c50b9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/ca15015a304802f26900cd8af9c69fea.jpg" width="692.2222405598492" id="u6bdd5520" class="ne-image"/>
</p>
<p id="ucdf24050" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<h1 id="oqnbF" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">五、查看小程序渠道</span>
</h1>
<p id="u339c59f6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">通过对接积木云策后，系统自动获取小程序渠道的数据，展示小程序渠道的运营关键数值与小程序打开方式的关系占比。</span>
</p>
<p id="ubcdee4a6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、管理员进入「渠道列表」，点击顶部「小程序渠道」切换小程序渠道展示小程序渠道的数据</span>
</p>
<p id="u67fdcad4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/aac8dadee993ff490b43abba7e3a599b.jpg" width="1062.2222503615021" id="u42eb6ab1" class="ne-image"/>
</p>
<p id="u81f961c5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">实时渠道数据：</span>
</p>
<p id="udc0ef5ae" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·pv：截止当前时间，微信生态渠道来源的用户数据汇总。pv指的是页面访问量，用户每次访问业务系统都会被记录下来；</span>
</p>
<p id="u8892d9b8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·uv：截止当前时间，微信生态渠道来源的用户数据汇总，uv指的是独立访问用户数，用户在一段时间内重复访问只计算为1次；</span>
</p>
<p id="u9d1f5cc2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·成交金额：截止当前时间，微信生态渠道来源的用户数据汇总，成交金额指的是微信用户在小程序上支付的金额合计；</span>
</p>
<p id="u901db720" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·成交订单数：截止当前时间，微信生态渠道来源的用户数据汇总，成交订单数指的是用户在小程序上支付的订单数量合计；</span>
</p>
<p id="u498c9327" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·成交用户数：截止当前时间，微信生态渠道来源的用户数据汇总，成交用户数指的是有多少个用户在小程序上世纪支付成功。</span>
</p>
<p id="ucae41dfd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新客户：截止当前时间，微信生态渠道来源的用户数据汇总，新客户指的是截止当前时间有多少个新用户进入系统</span>
</p>
<p id="u5b1debb2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<p id="u1e2e7409" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">场景名称：快捷入口、微信聊天、模版消息、搜索打开、扫描小程序码、公众号文章、公众号菜单、长按识别。</span>
</p>
<p id="u6c934995" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">列表：场景名称、新客户、pv、uv、实收金额、成交订单数、成交用户数</span>
</p>
<p>
<br/>
</p>`;
