export default `<h3 id="ho1dD" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1.简介：</span>
</h3>
<p id="u052e0aab" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">留存分析是分析用户黏性、活跃度的重要方法。主要用来分析某一群组用户（通常为某批新用户）中再次产生指定行为的人数和比例。随着获客成本逐年递增，留存分析变得越来越重要，做好留存分析，能为网站带来持续的流量增长。同时对流失用户针对性的调整推广/产品策略，也可以有效提高产品推广的ROI。</span>
</p>
<p id="u6b36b57d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/96f91f84461de9d05c427f9ab21ebf71.jpg" width="1474.9999780207875" id="ud6876195" class="ne-image"/>
</p>
<p id="u71f00eb9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px"></span>
</p>
<h3 id="drSb1" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.使用说明</span>
</h3>
<h3 id="Km1eS" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.1选择开始行为</span>
</h3>
<p id="u77cd0ed6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">选择一个事件作为初始条件，点击事件名称前的漏斗图标可以为该事件添加至多5个筛选条件（超过5个筛选事件或者筛选互斥项可能会导致无法正常查看数据）</span><img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/2cc1b228c171daf525dd9bc7097f60fc.jpg" width="999.999985098839" id="u27f5955d" class="ne-image"/>
</p>
<h3 id="VxKAa" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.2选择留存行为</span>
</h3>
<p id="ufa926fc2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">选择一个事件作为留存条件，点击事件名称前的漏斗图标可以为该事件添加至多5个筛选条件。</span>
</p>
<p id="uae8510f4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/fbc4c259a79a64b21d9020d0de9675ad.jpg" width="828.7499876506629" id="uf512fe95" class="ne-image"/>
</p>
<p id="u1dd814f9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/f92844cd4d3a216fe11d770e0c76ce70.jpg" width="796.2499881349506" id="uf2ca7422" class="ne-image"/>
</p>
<h3 id="UEVBL" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.3选择用户分群</span>
</h3>
<p id="u04bd92c9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可通过积木云策创建的群组，选择目标人群来分析他们的行为特征，不选择默认为全部用户</span>
</p>
<p id="u539d5684" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/7f9170d8e40f6bfa6cc1ad34bd16ca1f.jpg" width="663.7499901093544" id="WWEkJ" class="ne-image"/>
</p>
<h3 id="qy7nP" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2.4开始分析</span>
</h3>
<p id="u8d6a95e1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1.时间范围：可选择自定义周期的留存分析结果。选择的时间范围代表了开始行为发生的时间范围。即此次计算，选取的实在时间范围内发生了开始行为的访客</span>
</p>
<p id="u3cd9ad7d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/2ce8492feadc9df891505328538e0f21.jpg" width="834.9999875575306" id="aBiMG" class="ne-image"/>
</p>
<p id="u3c1634a5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2.周期粒度：周期的粒度可按天、按周、按月。若选择按周的情况下，周期筛选则按照所选时间范围判断所选筛选时间的所在周，自动按周展示，如果选择时间范围不满1周情况下，则按周期所在周自动展示一周的周期（若跨周且时间范围不满一周的情况下则按两个周期展示）。按月同理，不满1月自动按1月的周期范围展示，若跨月则按所在月展示月份数据</span>
</p>
<p id="u1510b936" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/a0c0f89c3453d0e5e4c738488e218a99.jpg" width="872.4999869987371" id="uf75b3396" class="ne-image"/>
</p>
<p id="ufc94a07f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">3.分析周期：可按次日、7日、14日、30日、60日来选择分析留存周期（默认按7日）</span>
</p>
<p id="ue3e4a7f6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/8ede85dc0e4248d4a9f2889bc9f7b14c.jpg" width="869.99998703599" id="u318691b0" class="ne-image"/>
</p>
<p id="u93442d61" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">4.指标：可选留存率或者流失来查看分析结果（留存即代表用户做了这个开始行为，再次产生了留存行为，流失则代表用户做了这个开始行为，但是未产生留存行为）</span>
</p>
<p id="u6f9756bd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/459ddd5e5fc786f966c18bb54ec3fd0d.jpg" width="776.2499884329739" id="u67db39d4" class="ne-image"/>
</p>
<p id="ube38201b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">5.数据意义：</span><span class="ne-text" style="color: rgba(0, 0, 0, 0.85); font-size: 14px">在留存结果中，鼠标悬浮到表格中位置，即可查看相应表格数据代表的含义。以图中数据为例，表示该网站在所选时间范围内共xxx人满足初始条件，这xxx人中有xxx人在满足初始条件当日又满足了留存条件。计算方式为：满足了留存事件的用户数量/满足了开始事件的数量x100%=xx%</span>
</p>
<p id="uccf6e484" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/d4d31a1388b9cf4e984c67345057fa56.jpg" width="1268.749981094152" id="uc25cbbae" class="ne-image"/>
</p>
<p>
<br/>
</p>`;
