export default `<h1 id="dmB8O" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="ufa8ea999" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">因不同行业下对于不同人群、事件的定义不一致，积木云策推出规则配置功能，支持企业管理员针对实际业务场景来二次修改配置规则，更加个性化，更加契合业务场景</span>
</p>
<h1 id="EEK7Z" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、会员群组</span>
</h1>
<p id="u2bbc05b5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">支持修改消费类型、访问类型、业务类型等三种类型人群的具体数值配置，配置完成后，在「会员中台-会员群组-更多推荐」模块即可按照配置的规则进行人群计算，人群定位更准确。</span>
</p>
<p id="u7bc57d98" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：目前暂不支持修改规则，仅支持修改具体数值范围，需管理员根据所处行业自身业务场景进行配置；</span>
</p>
<p id="udc1d3ac9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/de806e447cd587bf9d4dc0bf16885292.jpg" width="1586.6667086989803" id="u9085c31c" class="ne-image"/>
</p>
<h3 id="D4bi5" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（一）消费类型</span>
</h3>
<p id="u77e1c66d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）高价值消费群：月消费次数超过x次的客户群体，x支持修改；</span>
</p>
<p id="uaa833b46" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）高客单价消费群：客单价超过x的客户群体，x支持修改；</span>
</p>
<p id="u5130b714" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）低消费群体：消费次数只有x次的客户群体，x支持修改；</span>
</p>
<p id="ud6eea008" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）高复购群体：连续x周都有消费的客户群体，x支持修改；</span>
</p>
<p id="u1e896262" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）低频率群体：已超过x天未消费，x支持修改；</span>
</p>
<p id="ua83ab70b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/2a3ac906faca05ba5e54bca9e266a869.jpg" width="1158.888919588961" id="u824d1ed5" class="ne-image"/>
</p>
<h3 id="gOMQf" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（二）访问类型</span>
</h3>
<p id="u5d86ec67" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）高度活跃：一周登录次数高于x次的客户群体，x支持修改；</span>
</p>
<p id="ufd31709d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）即将流失群体：超过x天未登录的群体，x支持修改；</span>
</p>
<p id="ud8fd9930" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）高价值流失群体：超过x天未登录且有过消费的群体，x支持修改；</span>
</p>
<p id="ua3493327" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）低活跃度：平均登录时长低于x分钟群体，x支持修改；</span>
</p>
<p id="u7aa9518d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/52b71e349cc1d0a35f0e240350cf2ad2.jpg" width="1177.7778089782346" id="ue298e3e5" class="ne-image"/>
</p>
<h3 id="MJH1F" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（三）业务类型</span>
</h3>
<p id="ua5daec37" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">该模块需联系客户经理定制开发</span>
</p>
<h1 id="TZgEu" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、透视事件</span>
</h1>
<p id="u38b48964" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">支持修改基础透视、用户行为透视、营销体系透视等三种类型透视模型的具体数值配置，配置完成后，在「会员分析-会员事件-基础透视」模块即可按照配置的规则进行透视模型规则计算，客户分层定位更准确。</span>
</p>
<p id="ub6fbb02a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：目前暂不支持修改规则，仅支持修改具体数值范围，需管理员根据所处行业自身业务场景进行配置；</span>
</p>
<p id="uff210048" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/ef8a2777efc4c47030ca0d44e8faa38b.jpg" width="1586.6667086989803" id="u249e960d" class="ne-image"/>
</p>
<h3 id="pKjHu" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（一）用户属性透视</span>
</h3>
<p id="u9d29eb1c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）用户活跃度透视：近x天内用户访问x1、x2、x3、x4、x5、x6次数的数据对比，其中x-x6均支持修改数值与删除/增加；</span>
</p>
<p id="ufb0a720c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）用户流失透视：近x1、x2、x3、x4天用户未登录情况，其中x1-x4均支持修改数值与删除/增加；</span>
</p>
<p id="u3efb30d3" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）单体价值透视：近x天下单的客户客单价x1、x2、x3、x4、x5的数据对比，其中x1-x5均支持修改数值与删除/增加；</span>
</p>
<p id="ua42109bd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）群体价值透视：前x个客户组的下单价格排序，不去重，其中x支持修改数值；</span>
</p>
<p id="u773ac905" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）性别透视：近x天男女比例消费透视，其中x支持修改数值；</span>
</p>
<p id="u79a4737b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（6）城市透视：展示近x天，消费排名前y的城市，其中x、y均支持修改数值；</span>
</p>
<p id="u9f280539" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/e6b344ff09e8f2d31afc1586866581ad.jpg" width="1373.3333697142434" id="u264e2bec" class="ne-image"/>
</p>
<h3 id="kU67I" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（二）用户行为透视</span>
</h3>
<p id="u402daefc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）访问流失分层：访问x1、x2、x3、x4次且流失的用户群体，其中x1-x4均支持修改数值与删除/增加；</span>
</p>
<p id="u3aefdcf1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）支付流失分层：支付x1、x2、x3、x4次且流失的用户群体，其中x1-x4均支持修改数值与删除/增加；</span>
</p>
<p id="u451fc45d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）消费次数分层：消费x1、x2、x3、x4次的用户群体，其中x1-x4均支持修改数值与删除/增加；</span>
</p>
<p id="u7795ca29" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）订单取消透视：取消过x1、x2、x3、x4次的用户群体，其中x1-x4均支持修改数值与删除/增加；</span>
</p>
<p id="u50ab7f01" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）用户分享透视：无参与/低参与/中参与/高参与分享次数为x次；其中不同参与程度的x次均支持修改数值；</span>
</p>
<p id="u0b5ee2ad" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（6）分享结果透视：无价值/低价值/中价值/高价值分享后带来x名用户；其中不同价值程度的x名均支持修改数值；</span>
</p>
<p id="u902d29fe" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/fff0125e42d681c2e7e2fbe3153d1dfb.jpg" width="1425.555593319882" id="u14312d25" class="ne-image"/>
</p>
<h3 id="WGBkX" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">（三）营销体系透视</span>
</h3>
<p id="u97fa3a78" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）优惠券敏感度：敏感型/不敏感型使用优惠券结算过x次订单，其中x次均支持修改数值；</span>
</p>
<p id="ufdea46a0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）用户复购透视：复购x1、x2、x3、x4、x5次的用户群体，其中x1-x5均支持修改数值与删除/增加；</span>
</p>
<p id="u0f14478c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）优惠类型透视：使用过折扣券、满减券、代金券的用户群体，其中三种类型均不支持修改；</span>
</p>
<p id="ue94f8952" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）优惠券偏好透视：xx-xx优惠券抵扣金额次数，其中xx-xx区间支持添加多个与自定义区间范围；</span>
</p>
<p id="u688ea980" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/1a83577003cc1ac20399f31348925fb2.jpg" width="1355.5555914655154" id="ub2a12752" class="ne-image"/>
</p>
<p id="uebad3e60" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<p>
<br/>
</p>`;
