export default `<h1 id="sozm5" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="u01e7987b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">触达渠道分析可帮助管理员分析不同触达渠道的价值，来协助管理员在后续营销活动创建时所选触达渠道提供有效建议</span>
</p>
<h1 id="g138F" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、触达渠道分析</span>
</h1>
<p id="u0104388b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">1、可查看短信、公众号、push三个渠道的触达数据，来分析不同触达渠道的效果；</span>
</p>
<p id="ua625e6ab" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">2、可点击右上角业务系统筛选不同业务系统的触达渠道分析；</span>
</p>
<p id="u773ef64c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<p id="ud37cda3a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/5d777e75dafdcf0edf2c5ff442553900.jpg" width="1344.4444800600602" id="u4ba38ef0" class="ne-image"/>
</p>
<h3 id="J3v9q" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">短信数据（备注：统计的是历史数据）</span>
</h3>
<p id="ue63585f1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）短信触达人数：通过短信触达的人数总数；</span>
</p>
<p id="ue85be042" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）短信接收人数：实际接收到短信的人数；</span>
</p>
<p id="uef2ef4dc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）短信点击人数：点击短信链接进入应用的人数（只有在短信放入了渠道链接才能统计，其他情况下无法统计）；</span>
</p>
<p id="u614d2a67" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）短信点击率：短信点击人数/短信接收人数x100%；</span>
</p>
<h3 id="njnrh" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">微信公众号（备注：统计的是历史数据）</span>
</h3>
<p id="u58621567" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）触达人数：微信公众号模板消息通知人数；</span>
</p>
<p id="u3d33d7e1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）接收人数：实际收到微信公众号模板消息通知人数（部分用户可能存在未关注公众号的情况，导致无法收到通知）；</span>
</p>
<p id="u424e33be" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）点击人数：通过通知点击链接进入系统的人数（单用户重复点击算作1次，若通知的公众号通知设置为无跳转的则无法统计）；</span>
</p>
<p id="u69881561" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）点击转化率：点击人数/接收人数x100%；</span>
</p>
<h3 id="gFmoP" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">push（备注：统计的是历史数据）</span>
</h3>
<p id="uc3308f7a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）触达人数：app推送预计发送人数；</span>
</p>
<p id="ubdfb2530" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）接收人数：app推送实际收到人数（可能存在用户关闭了推送的情况，或者因为厂商的原因实际只有部分用户收到的情况）；</span>
</p>
<p id="u150949b9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）点击人数：通过push点击进入系统的人数（单用户重复点击算作1次）；</span>
</p>
<p id="u6b1b3d4d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）点击转化率：点击人数/接收人数x100%；</span>
</p>
<h1 id="qUZzm" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、近7日点击人数走势图</span>
</h1>
<p id="ucb080a5f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看最近7天不同触达渠道的点击人数统计，来分析最近7天的渠道有效性；</span>
</p>
<p id="u976d834a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/704ec8a71ad44324b078f845bfe2be74.jpg" width="1292.2222564544215" id="u60613489" class="ne-image"/>
</p>
<p id="u30cba33d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）坐标轴：x轴为7日日期，y轴为点击次数；</span>
</p>
<p id="ua06f133c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）浮窗：鼠标悬浮后，展示该日期三个通知渠道的点击次数；</span>
</p>
<p>
<br/>
</p>`;
