export default `<h1 id="sozm5" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="u01e7987b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">BI驾驶舱是积木云策推出的敏捷型BI以及数据可视化看板，目标是通过对接客户端业务系统来更直观的查看当日业务系统的运营情况，能够帮助管理人员快速分析数据，数据实时可见，可即时掌握前台运营情况</span>
</p>
<p id="u1f71094b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/302ab7b42e60ff9fed63710bd303b3b9.jpg" width="1580.0000418557072" id="u979aeb17" class="ne-image"/>
</p>
<h1 id="g138F" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、各报表说明</span>
</h1>
<h3 id="SKLHl" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1、关键指标</span>
</h3>
<p id="u8f2c248f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">通过关键指标可快速得知当日用户的访问情况与新增用户情况，数据实时刷新。帮助管理员实时监测用户涌入情况。</span>
</p>
<p id="uf32e60b7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/08c7684b4096f03e9f145e80c3a68037.jpg" width="1584.4444864178893" id="u9b2bc52f" class="ne-image"/>
</p>
<p id="ud57d2d64" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="ub32bab82" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）今日累计访问：今日累计访问系统的次数（单个用户访问多次都计入）</span>
</p>
<p id="uf6e7c184" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）今日访问次数：今日累计访问系统的人数（按人计算，单个用户访问多次计为1次）</span>
</p>
<p id="u32a4e386" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）实时访客：当前正在使用系统的用户数量，用户退出使用业务系统后自动扣除</span>
</p>
<p id="u6d461e11" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）实名用户：历史有注册手机号码的用户，若当前有用户进入系统并注册手机号后，该页面实时刷新</span>
</p>
<p id="u057a6fcd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（5）今日新增用户：今日对应业务系统下注册的新用户数量</span>
</p>
<h3 id="jxmD8" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2、渠道来源</span>
</h3>
<p id="u86c2b3b1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">分析在积木云策创建的渠道链接访问的数量以及带来的新客数量合计，通过此分析可快速得知不同来源渠道的价值。</span>
</p>
<p id="u90f912be" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/b1dcb6755040d08bf9c82c0e007f02d4.jpg" width="462.2222344669298" id="uf5fd763f" class="ne-image"/>
</p>
<p id="u83e9011d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="u745ba6b7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）渠道类型：包含微信渠道、app渠道、自定义渠道；</span>
</p>
<p id="ud89cf30f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）访问数量：汇总今日通过该渠道进入业务系统后在业务系统的访问次数合计（多次访问都计入内）；</span>
</p>
<p id="u6858b1aa" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）新客数量：汇总今日通过该渠道注册的新用户数量；</span>
</p>
<p id="uf0e464b8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）排序：访问数量大小进行排序；</span>
</p>
<h3 id="f8hUn" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">3、关键页面访问次数</span>
</h3>
<p id="u9d5d2c80" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">汇总访问次数前五的页面名称（以对接业务系统的前台页面名称为主，若有多个不同端口则都汇总入内）</span>
</p>
<p id="ue146f7ab" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/22b36a4ec602891b57e201f83fedb837.jpg" width="458.8889010452933" id="u46c5de1f" class="ne-image"/>
</p>
<p id="u3b3d392a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="uaf1f2ebc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）数据：展示前5个访问数量的页面（用户多次访问去重）</span>
</p>
<h3 id="Jfkgi" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">4、平台流量分布</span>
</h3>
<p id="u654bd54d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看当日平台在不同端口的流量访问情况，来分析不同端口的价值；</span>
</p>
<p id="uebecfa6f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/c0ae10183a60cc9dd251b21e0d3df930.jpg" width="641.111128094756" id="u12b7b6b9" class="ne-image"/>
</p>
<p id="u3d3fb0e9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="u7756e224" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）渠道类型：包含小程序、网页、安卓、ios。的流量分布占比。</span>
</p>
<p id="ub07c4f7d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">此处指的是，四个渠道的访问次数占比（按照访问计算，而非按照人）</span>
</p>
<p id="ubd2494c2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）浮窗：鼠标移入后，展示对应渠道的名称、百分比、数值</span>
</p>
<h3 id="w1vI3" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">5、实时行为</span>
</h3>
<p id="u454d7153" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看今日不同渠道的关键行为数据，快速直观查看平台的运营情况</span>
</p>
<p id="uee857558" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/e0be5b6c2a3b22bf71298a55690ddfc7.jpg" width="451.11112306147476" id="u9210c81b" class="ne-image"/>
</p>
<p id="u491ae3fe" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="u7fad3b53" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）用户名：用户在系统内的名称</span>
</p>
<p id="u96cf8daa" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）关键行为：包含登录系统、支付订单、取消订单、转发分享、注册成功。若客户有行为的其他要求，需另做定制</span>
</p>
<p id="u8bf0ed44" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）触发时间：用户操作该行为的时间，详细到时分秒</span>
</p>
<p id="ud4f300cd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）来源渠道：用户所属的渠道（app/小程序/自定义渠道）</span>
</p>
<p id="ud3abdf86" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<h3 id="d17jo" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">6、销售实时看板</span>
</h3>
<p id="u3db53822" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看今日用户在平台的支付订单情况，来分析今日平台的交易情况。</span>
</p>
<p id="ud8819bfc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/9b7454da5ee6714bb67f915c31e093cb.jpg" width="452.2222342020203" id="ub5cafa33" class="ne-image"/>
</p>
<p id="ufba42a32" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="u9d725ac8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）用户名：用户在系统内的名称</span>
</p>
<p id="u7b24b17f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）支付商品：用户购买的商品名称，超出省略处理</span>
</p>
<p id="udea443b0" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）支付时间：用户操作支付的时间</span>
</p>
<p id="ud68745ed" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）订单金额：对应订单的订单总金额</span>
</p>
<p>
<br/>
</p>`;
