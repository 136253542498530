export default `<h1 id="dEqZg" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">一、功能说明</span>
</h1>
<p id="ua9ac50bd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">提供全链路的用户增长、用户活跃、用户留存、用户访问精准分析，洞悉用户完整客户旅程。</span>
</p>
<p id="uece1de8f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text"></span>
</p>
<p id="ua463382a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">数据来源：通过对接业务系统后，获取业务系统下的用户数据进行分析并展示</span>
</p>
<h1 id="b9Ydd" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">二、新增分析</span>
</h1>
<h3 id="tkXB0" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1、每日新增客户数</span>
</h3>
<p id="ub27fda84" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">企业可在此模块以“日”为统计粒度查看近一个月内每日的用户增长情况，来判断企业新顾客增长情况。</span>
</p>
<p id="udaeea9b2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/9ba1c3f68ebd6bc393dc7a0aeea20123.jpg" width="450.0000119209293" id="u536154ad" class="ne-image"/>
</p>
<p id="udf59fb4a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">新增人数：单日/近7日/近1个月的新增人数（新增人数判断基于我们系统内置的会员群组规则定义）</span>
</p>
<p id="u3ef9c29e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">新增人数平均值：如近7日新增人数平均值=新增人数/7天x100%、近30日新增人数：新增人数/30天x100%</span>
</p>
<h3 id="LWECL" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2、每周新增客户数</span>
</h3>
<p id="u94ad7655" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">企业可在此模块以“日”为统计粒度查看近三个月内每周的用户增长情况，来判断企业新顾客增长情况。</span>
</p>
<p id="u8e9cf513" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/a3627777d5a193f373137a284f3b7802.jpg" width="447.2222340695655" id="u2e08e44b" class="ne-image"/>
</p>
<p id="u01edc334" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">新增人数：单日/近7日/近1个月的新增人数（新增人数判断基于我们系统内置的会员群组规则定义）</span>
</p>
<p id="u5d1f12cb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">新增人数平均值：如近7日新增人数平均值=新增人数/7天x100%、近30日新增人数：新增人数/30天x100%</span>
</p>
<h3 id="awtFI" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">3、每月新增客户数</span>
</h3>
<p id="u95fb54aa" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">企业可在此模块以“月”为统计粒度查看近一年内每月的用户增长情况，来判断企业长期的新顾客增长情况。</span>
</p>
<p id="u05e2dfeb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/748a35db6bec35370cc3019626c1a97f.jpg" width="445.5555673587472" id="u9a22ee35" class="ne-image"/>
</p>
<p id="u7c9cce0d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">新增人数：单日/近7日/近1个月的新增人数（新增人数判断基于我们系统内置的会员群组规则定义）</span>
</p>
<p id="u77ff2ecb" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">新增人数平均值：如近7日新增人数平均值=新增人数/7天x100%、近30日新增人数：新增人数/30天x100%</span>
</p>
<h3 id="ycI4V" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">4、新用户下单趋势</span>
</h3>
<p id="u3a7869bd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">按近30天查看新增用户的下单情况，来判断新用户所带来的收益价值。</span>
</p>
<p id="ue8e77ef2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/c63f54fb836e7517bfd35c79370034e4.jpg" width="446.11112292902" id="ucc045018" class="ne-image"/>
</p>
<p id="u7a580d57" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">新增用户-没有下单行为：近1个月新增用户内，没有下单的用户数量</span>
</p>
<p id="u27211038" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">新增用户-有下单行为：近1个月新增用户内，有下单的用户数量</span>
</p>
<p id="u3a4d8041" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">新增用户数：近1个月新增的用户总数（新增用户根据云策系统配置的规则定义）</span>
</p>
<h3 id="NrTIK" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">5、新用户来源占比分析</span>
</h3>
<p id="u25668296" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可根据用户的一级渠道来源/自定义渠道来源/微信渠道来源来多维度分析新增用户的渠道分布，来判断新增用户中最具价值的渠道</span>
</p>
<p id="ua3ae06a8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/de245343a3f46c2202c833f4c5316152.jpg" width="450.555567491202" id="u6a1e003e" class="ne-image"/>
</p>
<p id="u543f0a16" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新用户的一级渠道占比（APP/小程序）：平台历史通过app/小程序自然注册的新用户人数占比</span>
</p>
<p id="u68776b88" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新用户的自定义渠道占比：平台历史通过中台自己创建的渠道进入注册的新用户人数占比</span>
</p>
<p id="ucf90a9ee" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新用户的微信渠道占比（微信聊天/模板消息/搜索打开/扫描二维码/公众号文章/公众号菜单/长按识别）：平台历史通过不同入口的微信渠道进入系统的新用户人数</span>
</p>
<h3 id="pU1db" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">6、新用户增长趋势</span>
</h3>
<p id="u61756b6d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">按最近30天每日不同来源渠道的新增用户情况，通过此图表来分析不同渠道在不同日期的增长情况</span>
</p>
<p id="u64c56bd1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/023916326742fbc6fc057b5028718655.jpg" width="443.33334507765625" id="ue6e81770" class="ne-image"/>
</p>
<p id="u92b65107" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">一级渠道新用户增长趋势（APP/小程序）：当月通过app/小程序自然注册的新用户人数</span>
</p>
<p id="u08c76714" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">自定义渠道新用户增长趋势：当月通过中台自己创建的渠道进入注册的新用户人数</span>
</p>
<p id="ud11e26e9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">微信渠道新用户增长趋势（微信聊天/模板消息/搜索打开/扫描二维码/公众号文章/公众号菜单/长按识别）：当月通过不同入口的微信渠道进入系统的新用户人数</span>
</p>
<h3 id="EgSTn" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">7、不同渠道增长明细表</span>
</h3>
<p id="u6d6656ab" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">按不同渠道查看当日渠道的增长、付费、访问、客单价等详细数据，来判断渠道价值</span>
</p>
<p id="ucd9d51ae" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/41544cbc8dcd0bfb4384050b1507cbcd.jpg" width="879.4444677417667" id="u51fb46d2" class="ne-image"/>
</p>
<p id="u28b6d237" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新增用户数：该渠道下指定日期的新增用户数量（指的是通过该渠道进入系统并注册）</span>
</p>
<p id="u220af18e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·累计新增用户数：该渠道下的历史新增用户人数</span>
</p>
<p id="ubf164a37" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·付费用户数：该渠道下指定日期的付费用户人数（单个用户多次付费计为1个）</span>
</p>
<p id="u358b6315" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·累计访问用户数：该渠道下指定日期下历史的总访问人数（通过该渠道进入系统的人数，若用户第二天从其他渠道进入则计算为新的渠道）</span>
</p>
<p id="u3ff5e09c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·GMV：通过该渠道进入的用户，指定日期下的订单成交额（包含待支付、不包含已取消）</span>
</p>
<p id="u5605d1ba" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·渠道客单价：通过该渠道进入的用户指定日期下的人均客单价（成交金额/成交用户）</span>
</p>
<h1 id="vd2EE" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">三、活跃分析</span>
</h1>
<h3 id="OTJg7" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1、活跃用户趋势</span>
</h3>
<p id="u5e8d0a45" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">企业可在此模块查看近30日用户的活跃情况，来分析业务系统的用户活跃情况来判断用户的活跃度</span>
</p>
<p id="u7ea43cfc" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/ce3f41ac5b1389e16659bf3070e34449.jpg" width="1156.66669730787" id="uc2154c7a" class="ne-image"/>
</p>
<p id="ua320e103" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·活跃客户数：近30日定义为活跃的客户人数（基于我们的内置群组的规则定义）</span>
</p>
<h3 id="DJfDk" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2、新用户占比趋势</span>
</h3>
<p id="u7a70299d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看最近30日活跃的用户中新用户的占比，企业可根据新用户活跃的比重来调整活动策略。</span>
</p>
<p id="u43bbc21c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/b9411d0f4dbb1d4701c94f09a01ed84a.jpg" width="582.2222376458443" id="u60c70a5e" class="ne-image"/>
</p>
<p id="u289f9d75" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·活跃用户内的新用户占比：近30日定义为活跃的客户人数，其中包含新用户的人数占近30日活跃的人数的百分比</span>
</p>
<h3 id="uIqD3" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">3、新用户活跃结构数量</span>
</h3>
<p id="ubca801f7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">查看最近30日新增用户的下单/未下单比重，以及观察新老用户人均启动次数来查看某时间段应用的新老用户活跃分层。</span>
</p>
<p id="ue1f0d46b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/0bb6a4657291921f452c51d20c9b271e.jpg" width="594.4444601918449" id="ua6edf1b7" class="ne-image"/>
</p>
<p id="u269515e9" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新客户访问总数：近30日，单个日期下的新用户访问系统人数（新用户为云策系统定义的规则）</span>
</p>
<p id="ue67afff8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·老客户访问总数：近30日，单个日期下的老用户访问系统人数（总用户-新用户=老用户）</span>
</p>
<p id="uad9ef269" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问客户总数：新用户+老用户合计（单日该系统下的总访问人数）</span>
</p>
<h3 id="NHhHA" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">4、周活跃用户</span>
</h3>
<p id="ud0bb085a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可以“周”为粒度来观察最近60日每一周活跃用户数量，来观察平台的用户活跃情况</span>
</p>
<p id="u30be5a8b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/4a8b229d2bb21503cf1c29f6f322e778.jpg" width="640.0000169542105" id="ud6002b0a" class="ne-image"/>
</p>
<p id="uaecd5e99" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·活跃客户数：近60日（8周）的每周活跃人数</span>
</p>
<h3 id="QTRv6" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">5、月活跃用户</span>
</h3>
<p id="u87f09c9c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可以“月”为粒度来观察最近一年每个月活跃用户数量，来观察平台的历史用户活跃情况</span>
</p>
<p id="u88fbf30a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/c2ab426382ea8de848c8a282eb6d561d.jpg" width="632.222238970392" id="u0d0dee24" class="ne-image"/>
</p>
<p id="ub03ffb55" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·活跃客户数：近1年（12个月）的每月活跃人数</span>
</p>
<h1 id="RW8Wt" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">四、留存分析</span>
</h1>
<h3 id="xGq4B" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1、新增客户7日留存</span>
</h3>
<p id="u0ecafbaf" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看过去15天的新增客户7天留存率，通过留存率可分析新用户的整体用户粘性、活跃度</span>
</p>
<p id="u111cc4a2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/47abbffb6e4d16b707a64980f8ec9d5d.jpg" width="640.0000169542105" id="u113824b9" class="ne-image"/>
</p>
<p id="ucc1164d8" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新增客户数的第1/2/3/4/5/6/7天留存率：指x天注册的新客户，第一天登录占比多少，第二天登录占比多少、第三天登录占比多少。若10个客户在星期一注册，其中五个星期二登录了，6个星期三登录了。那么第一天留存率为50%、第三天为60%</span>
</p>
<h3 id="xIoW2" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2、新增客户7日流失</span>
</h3>
<p id="u74d5beac" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看过去新增用户的7日流失情况，通过流失情况可得知用户详细的流失数据，通过此数据来分析新用户对产品的喜好程度，流失率越高则代表产品需要营销活动、优化页面效果来提高用户的留存率。</span>
</p>
<p id="u054aa3c7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/961b81b396bdff82ea64a5cab5e9a7b2.jpg" width="633.3333501109375" id="u71c36d69" class="ne-image"/>
</p>
<p id="u4b4e77cd" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">新增客户数的第1/2/3/4/5/6/7天流失率：指的是连续x天未登录的用户。如7.10号新增10个客户，第二天只有2个用户登录，那么流失率为80%。第三天3个人登录，那么流失率为70%</span>
</p>
<h3 id="fIak8" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">3、新增客户8周留存</span>
</h3>
<p id="u702712ae" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看最近两个月新增用户的8周留存情况，通过此留存情况可查看平台在最近两个月的新增用户留存情况，适用于外部渠道投放后的用户数据检测。</span>
</p>
<p id="u99f2fe22" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/994ba529f43940e5443c358a69ad8060.jpg" width="640.0000169542105" id="ubabafa88" class="ne-image"/>
</p>
<p id="ufb46970f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 13px;">规则说明：最近两个月注册的新客户，第一周登录占比多少，第二周登录占比多少、第三周登录...第8周的占比有多少。若10个客户在第一周注册，其中五个第二周登录了，6个第三周登录了。那么第一周留存率为50%、第三周为60%</span>
</p>
<h3 id="wR6TC" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">4、活跃客户7日留存率</span>
</h3>
<p id="ueb4aa615" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 13px">可查看过去14天活跃客户的7日留存率，用于分析活跃顾客对于平台的留存情况。</span>
</p>
<p id="u94eb48e5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 13px">备注：活跃顾客定义为积木云策会员群组内配置的活跃顾客规则，符合活跃顾客的规则即定义为活跃顾客。</span>
</p>
<p id="u95e54f82" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/aefc4aa975de37cfa9671f74970f5280.jpg" width="642.2222392353016" id="u369b526f" class="ne-image"/>
</p>
<p id="u78d0d64a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">说明：例如7.1号，活跃客户有1000人，其中200人7.2号登录 。300人7.3号登录。那么第一天的客户留存率20%，第二天客户留存率为30%</span>
</p>
<h3 id="md5zp" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">5、活跃客户8周留存率</span>
</h3>
<p id="u553dff7f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 13px">可查看过去14天活跃客户的7日留存率，用于分析活跃顾客对于平台的留存情况。</span>
</p>
<p id="uc8da6bbf" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 13px">备注：活跃顾客定义为积木云策会员群组内配置的活跃顾客规则，符合活跃顾客的规则即定义为活跃顾客。</span>
</p>
<p id="u0ee73ebe" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/3f472c91a9fecc9a98a18e18255b9b6a.jpg" width="535.7778015136719" id="u79771f3f" class="ne-image"/>
</p>
<p id="u047cf0c5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">说明：例如7.1号，活跃客户有1000人，其中200人7.2号登录 。300人7.3号登录。那么第一天的客户留存率20%，第二天客户留存率为30%</span>
</p>
<h3 id="XnPm0" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">6、活跃客户半年留存率</span>
</h3>
<p id="uc626f4a7" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 13px">可查看过去6个月活跃客户的半年留存率，用于分析活跃顾客对于平台的留存情况。</span>
</p>
<p id="uc52640b6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text" style="font-size: 13px">备注：活跃顾客定义为积木云策会员群组内配置的活跃顾客规则，符合活跃顾客的规则即定义为活跃顾客。</span>
</p>
<p id="ub5665849" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/0aa34965c9c9681f9b705694ebe81982.jpg" width="587.6666870117188" id="ued87a797" class="ne-image"/>
</p>
<h3 id="OIpZU" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">7、访问/成交留存明细表</span>
</h3>
<p id="ubaa68b3a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看用户访问或者成交后的留存情况，来分析用户的多维度留存情况</span>
</p>
<p id="u2c53d011" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/8ebd395057d8495c60b390aea850313c.jpg" width="917.7778020905866" id="u8b376ce5" class="ne-image"/>
</p>
<p id="uf848dd28" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/7feff444dbef9670b12c34a7d67bff4e.jpg" width="910.5555796770408" id="ueb5ce17b" class="ne-image"/>
</p>
<p id="ube7e5bd4" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新增客户的最近一年留存率：访问时间（按月）的新访问总人数的第一个月至第六个月的留存率</span>
</p>
<p id="ueeccc5de" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·成交客户的最近一年留存率：成交时间（按月）的新成交客户人数的第一个月至第六个月的留存率</span>
</p>
<h1 id="VNP85" style="font-size: 28px; line-height: 36px; margin: 26px 0 10px 0">
<span class="ne-text">五、访问分析</span>
</h1>
<h3 id="sNVtc" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">1、流量总览</span>
</h3>
<p id="ub403c89d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">查看平台昨日的整体流量访问情况与成交转化情况，支持点击顶部切换小程序或APP两种类型</span>
</p>
<p id="u7d094681" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/89b995f2536a962c269dfd91c3242e3a.jpg" width="896.666690420222" id="ud5c11b4f" class="ne-image"/>
</p>
<p id="u414d7003" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>流量访问：</strong>
</p>
<p id="u64ac24c2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访客数：访问系统的客户数量</span>
</p>
<p id="u26990c38" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新访客数：访问系统的新用户数量</span>
</p>
<p id="uc11a117a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·浏览量：启动系统应用的次数总和</span>
</p>
<p id="u25abb450" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·平均停留时长（秒）：系统所有访客统计时长的总和/访客数。</span>
</p>
<p id="uc0995a80" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·人均浏览量：系统所有访客的浏览次数/访客数</span>
</p>
<p id="u33c976c5" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·跳失率：只访问一个页面就退出的访客数/总访客数</span>
</p>
<p id="u3fcf1f13" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>成交转化：</strong>
</p>
<p id="u5024e322" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·支付人数：支付费用的访客总数。</span>
</p>
<p id="ue41adac6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·访问-支付转化率：访客总数/支付人数</span>
</p>
<h3 id="ZWdMe" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">2、人均启动次数</span>
</h3>
<p id="ub4fdd03e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看过去30天用户的人均启动应用次数，来观察用户启动应用的情况</span>
</p>
<p id="u87d2507d" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/6068e3cd86169743628c177cac4cca67.jpg" width="447.2222340695655" id="u58b332d7" class="ne-image"/>
</p>
<p id="uac5d62d6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·人均启动次数：过去30天启动的总数/访问的人数x100%</span>
</p>
<p id="uba2ef51e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：过去30天重复登录的访问人数只计算为1个人</span>
</p>
<h3 id="Mvi6U" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">3、人均使用时长</span>
</h3>
<p id="u7a11464e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看过去30天人均使用应用时长，来判断用户对于应用的黏性高低</span>
</p>
<p id="u685729e2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/5891f7ddc69ffb72f20fa2bcb483d9e7.jpg" width="452.2222342020203" id="u2cdf7c30" class="ne-image"/>
</p>
<p id="u4290fb63" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·人均使用时长：过去30天，使用的时长/访问的人数x100%</span>
</p>
<p id="uc0d12051" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：过去30天重复登录的访问人数只计算为1个人</span>
</p>
<h3 id="XyNZW" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">4、新老用户启动次数</span>
</h3>
<p id="ucbe6716a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">查看过去30天新用户与老用户的人均启动次数对比。来查看平台新用户的活跃情况以及老用户的活跃情况</span>
</p>
<p id="u14c14303" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/bf64f723dbdf750c2d99f0fdfeeb39e8.jpg" width="448.333345210111" id="uff068cd2" class="ne-image"/>
</p>
<p id="u1ac688b6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新客户人均启动次数：过去30天属于新客户的启动次数/近30天的新客户人数</span>
</p>
<p id="ud7e14df1" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·老客户人均启动次数：过去30天属于老客户的启动次数/近30天的新客户人数</span>
</p>
<p id="udb1d44bf" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·整体人均启动次数：启动次数总计</span>
</p>
<p id="u4568f35f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">备注：基于「积木云策-会员群组」模块定义新用户规则，老用户则为全部用户减去新用户。</span>
</p>
<p id="u55a7ee78" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<br/>
</p>
<h3 id="gdEKQ" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">5、新老用户使用时长</span>
</h3>
<p id="u013ce35f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">查看过去30天新用户与老用户使用应用的时长，搭配其他分析来判断新用户群体和老用户群体的使用差异。</span>
</p>
<p id="ucabd5487" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/c89c993f6577f86c680c14d547d19140.jpg" width="448.333345210111" id="uc5055cf0" class="ne-image"/>
</p>
<p id="u2ea4819e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·新客户人均使用时长：过去30天属于新客户的使用时长/近30天的新客户人数</span>
</p>
<p id="u7125e1c6" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·老客户人均使用时长：过去30天属于老客户的使用时长/近30天的新客户人数</span>
</p>
<p id="ua8af849b" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·整体人均使用时长：过去30天所有用户的使用时长/近30天的新客户人数</span>
</p>
<h3 id="QcX0v" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">6、人均浏览深度</span>
</h3>
<p id="ubb7c115c" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">可查看用户的浏览页面深度，来判断用户对于小程序使用程度，来判断应用的用户活跃情况</span>
</p>
<p id="u7a1c230e" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/1b4488d60f1118aa212de4b2e35c3f0d.jpg" width="447.77778963983826" id="u5056cf5d" class="ne-image"/>
</p>
<p id="u89e116ec" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·人均浏览深度指的是：所有访客页面浏览总数/访客数量。</span>
</p>
<h3 id="luB9k" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">7、平均浏览深度</span>
</h3>
<p id="ua2e78a62" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">与人均浏览深度的角度不同，该模块是按登录次数进行计算，而非按人进行计算。</span>
</p>
<p id="u2c49d89f" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/da38a27c107f4cbdb745e20eca9aa477.jpg" width="448.333345210111" id="uf892df80" class="ne-image"/>
</p>
<p id="ubbc13d21" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">·平均浏览深度：所有登录的浏览页面总和/登录次数（人均是按照人为单位算的，这个是按照每次登录就计算入内）</span>
</p>
<h3 id="NYIpp" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">8.访问行为概览</span>
</h3>
<p id="u31f8ef5a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">通过访问行为概览，可得知某个日期下用户的涌入情况，适用于平台进行用户引流、激活等相关活动时作为重要指标进行监测。</span>
</p>
<p id="ub5d18a5a" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/480f3aa2d13d425ce6f3b84efa563c65.jpg" width="448.333345210111" id="ufc5343f7" class="ne-image"/>
</p>
<p id="u87335865" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<strong>字段说明：</strong>
</p>
<p id="u2d63ce39" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（1）日人均访问次数：统计时间内，日均每个用户访问应用的次数，即总访问次数/访问页面的总用户数；</span>
</p>
<p id="ub2b802c2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（2）日人均访问时长（分钟）：</span><span class="ne-text">统计时间内，日均每个用户停留在前端页面的总时长，即总停留时长/访问人数；</span>
</p>
<p id="u2b038631" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（3）</span><span class="ne-text">日人均访问页面数：</span><span class="ne-text">统计时间内，日均每次打开前端访问的去重页面数；</span>
</p>
<p id="ua14bafea" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">（4）月人均访问频次：统计时间内，月均每个用户访问小程序的次数；</span>
</p>
<h3 id="axoSQ" style="font-size: 20; line-height: 28px; margin: 16px 0 5px 0">
<span class="ne-text">9.页面访问排行榜</span>
</h3>
<p id="ucd3f0b56" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">通过此图表可知悉用户对于不同页面的偏好程度，来及时调整页面展示优先级。</span>
</p>
<p id="uec5180e2" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<img src="https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/scrm-project/ued/8d6d0603d400807425307adb42c773c5.jpg" width="451.11112306147476" id="ub18f4424" class="ne-image"/>
</p>
<p id="uddb9a831" class="ne-p" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; min-height: 24px;">
<span class="ne-text">各个页面下访问人数排行：取前五个近30天访问人数最多的页面（多次访问算1次）</span>
</p>
<p>
<br/>
</p>`;
